html {
    font-family: "Poppins", "cursive";
    padding: 0px;
}

body {
    margin: 0px;
}

input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.loading-content {
    width: 150px;
    text-align: center;
    position:absolute;
    top: 40%;
    left: 0;
    right: 0;
    margin:auto;
}

.loading-icon {
    width: 100px;
}




.container {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.welcome-footer-follow-soon-icon {
    opacity: .7;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {

    .mobile-hide {
        display: none;
    }

    .welcome-footer-become-member-container {
        display: none;
    }

    .header {
        width: 100%;
        padding: 10px 0;
        display: flex;
        flex-direction: row;
    }

    .header-title {
        margin: auto 10px;
        width: 50%;
        font-size: 13px;
    }

    .header-logo {
        width: 120px;
        margin-top: 5px;
    }
    
    .home-header-title:hover {cursor: default;}

    .welcome-header-items {
        display: flex;
        padding: 0px;
        margin: 5px 15px;
        list-style-type: none;
        width: 50%;
        justify-content: flex-end;
    }

    .welcome-header-item {
        margin-right: 10px;
        font-size: 12px;
        color: black;
    }

    .welcome-header-item:hover {
        border-bottom: solid #FF2F46 2px;
    }

    .main-image {
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/static/media/motorcyk-motorcycle-ducati-profile-main.67e79778.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 320px;
        padding: 20px;
        margin: 0px;
        display: block;
    }

    .image-quote {
        color: white;
        font-family: "Permanent Marker", "cursive";
        margin-top: 290px;
        margin-left: 5px;
        width: 100px;
        font-size: 20px;
    }

    .upcoming-event-section {
        width: 100%;
        background-color: #232323;
        text-align: center;
        display: block;
        padding: 20px 0;
    }

    .upcoming-event-section-title {
        color: #FF2F46;
        font-family: "Permanent Marker", "cursive";
        font-size: 35px;
        width: 100%;
        text-align: center;
        background-color: #232323;
        margin: 0px;
        padding-top: 45px;
    }

    .event-box {
        display: inline-block;
        background-color: #191919;
        width: 35%;
        padding: 10px;
        border-radius: 10px;
        margin: 20px 10px;
    }

    .event-box p {
        margin: 10px auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .event-date {
        opacity: .70;
        color: white;
        font-size: 10px;
    }

    .event-title {
        font-size: 15px;
        color: white;
    }

    .event-city {
        font-family: "Permanent Marker", "cursive";
        color:#FF2F46;
        font-size: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .event-host {
        font-size: 8px;
        color: white;
    }

    .upcoming-event-section-button {
        width: 90%;
        height: 50px;
        border-radius: 10px;
        margin: 20px auto;
        border: none;
        background-color: #FF2F46;
        color: white;
        font-size: 15px;
    }

    .upcoming-event-section-button:hover {
        cursor: pointer;
    }

    .how-it-works-section {
        padding: 20px;
        text-align: center;
    }

    .how-it-works-section-title {
        color: #191919;
        font-size: 35px;
        font-weight: bolder;
        margin: 50px auto;
        text-align: center;
    }

    .discover, .create {
        display: block;
        margin-bottom: 100px;
        width: 100%;
    }

    .discover-icon, .create-icon {
        height: 20px;
    }

    .how-it-works-section .discover .discover-title, 
    .how-it-works-section .create .create-title {
        font-family: "Permanent Marker", "cursive";
        font-size: 25px;
    }

    .discover-content, .create-content {
        width: 95%;
        margin: auto;
        font-size: 15px;
    }

    .discover-link, .create-link {
        color: #FF2F46;
        font-size: 15px;
    }

    .marker, .header-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
    }

    .arrow-icon {
        margin-bottom: -2px;
        width: 12px;
    }

    .welcome-footer-container {
        background-color: #232323;
        color: white;
        width: 100%;
        padding: 20px 0px;
    }

    .welcome-footer-list-container {
        width: 100%;
        margin-top: 50px;
        text-align: center;
    }

    .welcome-footer-list-one,
    .welcome-footer-list-two,
    .welcome-footer-list-three {
        display: block;
        width: 100%;
        padding: 0px;
        margin: 50px 0px;
    }

    .welcome-footer-list-title:hover {
        cursor: default;
    }

    .footer-break {
        display: none;
    }

    .welcome-footer-list-item {
        list-style-type: none;
        padding: 0px;
        margin-top: 10px;
        font-weight: lighter;
        font-size: 15px;
    }

    .welcome-footer-list-item:hover {
        color: #FF2F46;
    }

    .welcome-footer-list-item-question:hover {
        cursor: default;
        color: white;
    }

    .welcome-footer-follow-soon-container {
        text-align: center;
    }

    .welcome-footer-follow-soon-icons-container {
        display: block;
    }

    .welcome-footer-follow-soon-icons-title {
        margin: 0px auto;
    }

    .welcome-footer-follow-soon-icons-title:hover {
        cursor: default;
    }

    .welcome-footer-follow-soon-icons-title-comming {
        margin-top: 45px;
    }

    .welcome-footer-follow-soon-icons {
        width: 100%;
        margin-top: 35px;
    }

    .welcome-footer-follow-soon-icon {
        margin: 0px 10px;
    }

    .welcome-footer-follow-social-icon {
        height: 35px;
    }

    .welcome-footer-follow-soon-store-container {
        display: inline-block;
        vertical-align: top;
        margin: auto 50px;
    }

    .welcome-footer-follow-soon-icons-google {
        width: 135px;
    }

    .welcome-footer-follow-soon-icons-apple {
        width: 135px;
    }

    .welcome-footer-bottom-title {
        font-size: 10px;
        margin-top: 50px;
        width: 100%;
        text-align: center;
    }

    .welcome-footer-bottom-title-title {
        margin: 0px 10px;
    }

    .welcome-footer-bottom-title-title:hover {
        cursor: default;
    }

    .welcome-footer-bottom-title-link {
        color: white;
        margin: 0px 10px;
    }

    .welcome-footer-bottom-title-link:hover {
        color:#FF2F46;
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .hide-tablet {
        display: none;
    }

    .header {
        width: 100%;
        color: white;
        /* background-color: #232323; */
        padding: 10px 0;
        display: flex;
        flex-direction: row;
        height: 50px;
    }

    .header-title {
        margin: auto 10px;
        width: 50%;
        font-size: 18px;
    }

    .header-logo {
        width: 130px;
        margin-top: 5px;
    }

    .welcome-header-items{
        display: flex;
        padding: 0;
        margin: 5px 10px;
        list-style-type: none;
        width: 50%;
        justify-content: flex-end;
    }

    .welcome-header-item {
        margin: auto 15px;
        font-size: 15px;
        color: black;
    }

    .welcome-header-item:hover {
        border-bottom: solid #FF2F46 2px;
    }

    ul li {
        margin: auto 5px;
    }

    .main-image {
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/static/media/tabletImage3.2e892927.png);
        background-repeat: no-repeat;
        background-size: cover;
        height: 600px;
        padding: 20px;
        margin: 0px;
        display: block;
        text-align: center;
    }

    .image-quote {
        color: white;
        /* font-family: "Indie Flower", "cursive"; */
        font-family: "Permanent Marker", "cursive";
        width: 100%;
        margin-top: 300px;
        font-size: 30px;
        font-weight: bold;
    }

    .image-button {
        width: 30%;
        height: 50px;
        border-radius: 10px;
        margin: 0 auto;
        border: none;
        background-color: #FF2F46;
        color: white;
        font-size: 15px;
    }

    .image-button:hover {
        cursor: pointer;
    }

    .upcoming-event-section {
        width: 100%;
        background-color: #232323;
        text-align: center;
        display: block;
        padding: 20px 0;
    }

    .upcoming-event-section-title {
        background-color: #232323;
        color: #FF2F46;
        font-family: "Permanent Marker", "cursive";
        font-size: 35px;
        margin: 0px;
        padding: 20px 0px;
        width: 100%;
        text-align: center;
    }

    .event-box {
        display: inline-block;
        background-color: #191919;
        width: 40%;
        padding: 10px;
        border-radius: 10px;
        margin: 20px 20px;
        margin-bottom: 40px;
    }

    .event-box p {
        margin: 10px auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .event-date {
        opacity: .70;
        color: white;
    }

    .event-title {
        font-size: 20px;
        color: white;
    }

    .event-city {
        /* font-family: "Indie Flower", "cursive"; */
        font-family: "Permanent Marker", "cursive";
        color:#FF2F46;
        font-size: 25px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .event-host {
        font-size: 10px;
        color: white;
    }


    .how-it-works-section {
        padding: 20px;
        text-align: center;

    }

    .how-it-works-section-title {
        color: #191919;
        /* font-family: "Permanent Marker", "cursive"; */
        font-weight: bolder;
        font-size: 45px;
        margin: 50px;
        text-align: center;
    }

    .discover, .create {
        display: block;
        margin-bottom: 100px;
        width: 100%;
    }

    .discover-icon, .create-icon {
        width: 25px;
    }

    .discover-title, .create .create-title {
        font-family: "Permanent Marker", "cursive";
        font-size: 30px;
    }

    .discover-content, .create-content {
        width: 90%;
        margin: auto;
        font-size: 20px;
    }

    .discover-link, .create-link {
        color: #FF2F46;
        font-size: 25px;
    }

    .marker, .header-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
    }

    .arrow-icon {
        margin-bottom: -4px;
        width: 20px;
    }

    .welcome-footer-container {
        background-color: #232323;
        color: white;
        width: 100%;
        padding: 80px 0px;
    }

    .welcome-footer-become-member-container {
        width: 100%;
        text-align: center;
    }

    .welcome-footer-statement:hover {
        cursor: default;
    }

    .welcome-footer-button {
        width: 120px;
        height: 40px;
        border-radius: 10px;
        margin: 0px auto;
        margin-left: 5px;
        border: #FF2F46 1px solid;
        background-color: transparent;
        color: #FF2F46;
    }

    .welcome-footer-button:hover {
        cursor: pointer;
        color: white;
        background-color: #FF2F46;
    }

    .welcome-footer-list-container {
        width: 100%;
        margin-top: 50px;
        text-align: center;
    }

    .welcome-footer-list-one,
    .welcome-footer-list-two {
        display: inline-block;
        vertical-align: top;
        width: 40%;
        padding: 0px;
        margin: 50px 0px;
    }

    .welcome-footer-list-three {
        display: block;
        width: 400px;
        text-align: "center";
        margin: 0px auto;
        padding: 0px;
    }

    .welcome-footer-list-title {
        margin: 0px;
    }

    .welcome-footer-list-title:hover {
        cursor: default;
    }

    .footer-break {
        display: none;
    }

    .welcome-footer-list-item {
        list-style-type: none;
        padding: 0px;
        margin-top: 10px;
        font-weight: lighter;
        font-size: 15px;
    }

    .welcome-footer-list-item:hover {
        color: #FF2F46;
    }

    .welcome-footer-list-item-question {
        margin-top: 35px;
    }

    .welcome-footer-list-item-question:hover {
        cursor: default;
        color: white;
    }

    .welcome-footer-follow-soon-container {
        text-align: center;
    }

    .welcome-footer-follow-soon-icons-container {
        display: inline-block;
        vertical-align: top;
        margin: auto 100px;
    }

    .welcome-footer-follow-soon-icons-title {
        width: 400px;
        margin: 0px auto;
        margin-top: 100px;
    }

    .welcome-footer-follow-soon-icons-title:hover {
        cursor: default;
    }

    .welcome-footer-follow-soon-icons {
        margin-top: 45px;
    }

    .welcome-footer-follow-soon-icon {
        margin: 0px 10px;
    }

    .welcome-footer-follow-social-icon {
        height: 40px;
    }

    .welcome-footer-follow-soon-store-container {
        display: inline-block;
        vertical-align: top;
        margin: auto 50px;
    }

    .welcome-footer-follow-soon-icons-google {
        width: 135px;
    }

    .welcome-footer-follow-soon-icons-apple {
        width: 135px;
    }

    .welcome-footer-bottom-title {
        font-size: 10px;
        margin-top: 50px;
        width: 100%;
        text-align: center;
    }

    .welcome-footer-bottom-title-title {
        margin: 0px 20px;
    }

    .welcome-footer-bottom-title-title:hover {
        cursor: default;
    }

    .welcome-footer-bottom-title-link {
        color: white;
        margin: 0px 20px;
    }

    .welcome-footer-bottom-title-link:hover {
        color:#FF2F46;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    
    .hide-tablet {
        display: none;
    }

    .header {
        width: 100%;
        color: white;
        /* background-color: #232323; */
        padding: 10px 0;
        display: flex;
        flex-direction: row;
        height: 50px;
    }

    .header-title {
        margin: auto 10px;
        width: 50%;
        font-size: 22px;
    }

    .header-logo {
        width: 150px;
        margin-top: 5px;
    }

    .welcome-header-items{
        display: flex;
        padding: 0;
        margin: 5px 10px;
        list-style-type: none;
        width: 50%;
        justify-content: flex-end;
    }

    .welcome-header-item {
        margin: auto 15px;
        font-size: 15px;
    }

    .main-image {
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/static/media/indexImage.f798061a.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 500px;
        text-align: center;
        margin: auto;
    }

    .image-quote {
        color: white;
        font-family: "Permanent Marker", "cursive";
        width: 100%;
        margin-top: 200px;
        font-size: 30px;
        font-weight: bold;
    }

    .image-button {
        width: 300px;
        height: 50px;
        border-radius: 10px;
        margin: 0 auto;
        border: none;
        background-color: #FF2F46;
        color: white;
        font-size: 20px;
    }

    .upcoming-event-section-contianer {
        width: 100%;
        background-color: #232323;
        overflow:auto;
    }

    .upcoming-event-section {
        width: 1200px;
        background-color: #232323;
        text-align: center;
        display: block;
        padding: 20px 0;
        margin: auto;
        overflow:auto;
    }

    .upcoming-event-section-title {
        color: #FF2F46;
        font-family: "Permanent Marker", "cursive";
        margin: 0px;
        padding: 20px 0px;
        width: 100%;
        text-align: center;
    }

    .event-box {
        display: inline-block;
        background-color: #191919;
        width: 300px;
        padding: 10px;
        border-radius: 10px;
        margin: 20px 20px;
        margin-bottom: 40px;
    }

    .event-box p {
        margin: 10px auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .event-date {
        opacity: .70;
        color: white;
    }

    .event-title {
        font-size: 20px;
        color: white;
    }

    .event-city {
        font-family: "Permanent Marker", "cursive";
        color:#FF2F46;
        font-size: 25px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .event-host {
        font-size: 10px;
        color: white;
    }


    .how-it-works-section {
        padding: 20px;
        text-align: center;

    }

    .how-it-works-section-title {
        color: #191919;
        /* font-family: "Permanent Marker", "cursive"; */
        font-weight: bolder;
        font-size: 45px;
        margin: 50px;
        text-align: center;
    }

    .discover, .create {
        display: inline-block;
        vertical-align: top;
        margin-bottom: 100px;
        width: 48%;
    }

    .discover-icon, .create-icon {
        width: 25px;
    }

    .discover-title, .create-title {
        font-family: "Permanent Marker", "cursive";
        font-size: 30px;
    }

    .discover-content, .create-content {
        width: 70%;
        margin: auto;
        font-size: 20px;
    }

    .discover-link, .create-link {
        color: #FF2F46;
        font-size: 25px;
    }

    .marker, .header-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
    }

    .arrow-icon {
        margin-bottom: -4px;
        width: 20px;
    }

    .welcome-footer-container {
        background-color: #232323;
        color: white;
        width: 100%;
        padding: 80px 0px;
    }

    .welcome-footer-become-member-container {
        width: 100%;
        text-align: center;
    }

    .welcome-footer-statement:hover {
        cursor: default;
    }

    .welcome-footer-button {
        width: 120px;
        height: 40px;
        border-radius: 10px;
        margin: 0px auto;
        margin-left: 5px;
        border: #FF2F46 1px solid;
        background-color: transparent;
        color: #FF2F46;
    }

    .welcome-footer-button:hover {
        cursor: pointer;
        color: white;
        background-color: #FF2F46;
    }

    .welcome-footer-list-container {
        width: 100%;
        margin-top: 50px;
        text-align: center;
    }

    .welcome-footer-list-one,
    .welcome-footer-list-two,
    .welcome-footer-list-three {
        display: inline-block;
        vertical-align: top;
        width: 150px;
        margin: 50px 30px;
    }

    .welcome-footer-list-title {
        margin: 0px;
    }

    .welcome-footer-list-title:hover {
        cursor: default;
    }

    .welcome-footer-list-item {
        list-style-type: none;
        padding: 0px;
        margin-top: 10px;
        font-weight: lighter;
        font-size: 15px;
    }

    .welcome-footer-list-item:hover {
        color: #FF2F46;
    }

    .welcome-footer-list-item-question:hover {
        cursor: default;
        color: white;
    }

    .welcome-footer-follow-soon-container {
        text-align: center;
        width: 100%;
    }

    .welcome-footer-follow-soon-icons-container {
        display: inline-block;
        vertical-align: top;
        margin: auto 100px;
    }

    .welcome-footer-follow-soon-icons-title {
        margin: 0px 50px;
        margin-top: 50px;
    }

    .welcome-footer-follow-soon-icons {
        margin-top: 45px;
    }

    .welcome-footer-follow-soon-icon {
        margin: 0px 10px;
    }

    .welcome-footer-follow-social-icon {
        height: 40px;
    }

    .welcome-footer-follow-soon-store-container {
        display: inline-block;
        vertical-align: top;
        margin: auto 50px;
    }

    .welcome-footer-follow-soon-icons-google {
        width: 135px;
    }

    .welcome-footer-follow-soon-icons-apple {
        width: 135px;
    }

    .welcome-footer-bottom-title {
        font-size: 10px;
        margin-top: 50px;
        width: 100%;
        text-align: center;
    }

    .welcome-footer-bottom-title-title {
        margin: 0px 20px;
    }

    .welcome-footer-bottom-title-title:hover {
        cursor: default;
    }

    .welcome-footer-bottom-title-link {
        color: white;
        margin: 0px 20px;
    }

    .welcome-footer-bottom-title-link:hover {
        color:#FF2F46;
    }

    

}

.container {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.welcomecs-footer-follow-soon-icon {
    opacity: .7;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {

    .mobile-hide {
        display: none;
    }

    .welcomecs-footer-become-member-container {
        display: none;
    }

    .header {
        width: 100%;
        padding: 10px 0;
        display: flex;
        flex-direction: row;
    }

    .header-title {
        margin: auto 10px;
        width: 50%;
        font-size: 13px;
    }

    .header-logo {
        width: 120px;
        margin-top: 5px;
    }
    
    .home-header-title:hover {cursor: default;}

    .header-items {
        display: flex;
        padding: 0;
        margin: 5px 10px;
        list-style-type: none;
        width: 50%;
        justify-content: flex-end;
    }

    .header-item {
        margin: auto 0px;
        font-size: 12px;
        color: black;
    }

    .header-item:hover {
        border-bottom: solid #FF2F46 2px;
    }

    .welcomecs-intro-content-container {
        color: white;
        width: 100%;
        text-align: center;
        cursor: default;
        margin-top: 15px;
    }

    .welcomecs-intro-content-container h1 {
        margin-bottom: 0px;
    }

    .welcomecs-intro-content-container h2 {
        margin-top: 0px;
    }

    .welcomecs-countdown-style {
        display: inline-block;
        vertical-align: top;
        margin: 0px 10px;
    }

    .welcomecs-email-launch-notification-form-container {
        color: white;
        width: 100%;
        text-align: center;
        margin-top: 50px;
    }   

    .welcomecs-email-launch-notification-form-container input {
        display: block;
        margin: 0px auto;
        width: 300px;
        height: 30px;
        background-color: transparent;
        border: 1px gray solid;
        border-radius: 10px;
        color: white;
        padding: 0px 10px;
        text-align: center;
    }

    .welcomecs-email-launch-notification-form-container button {
        display: block;
        margin: 10px auto;
        width: 320px;
        height: 30px;
        background-color: transparent;
        border: none;
        color: white;
        border: 1px solid #FF2F46;
        border-radius: 10px;
    }

    .welcomecs-email-launch-notification-form-container button:hover {
        background-color: #FF2F46;
        cursor: pointer;
    }

    .main-image-container {
        background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url(/static/media/motorcyk-motorcycle-honda-ride-hills-roads-mobile.ee25f871.jpg);
        background-repeat: no-repeat;
        background-size:cover;
        background-position: center;
        min-height: 100vh;
        padding: 20px;
        margin: 0px;
        display: block;
    }

    .upcoming-event-section-button {
        width: 75%;
        height: 45px;
        border-radius: 10px;
        margin: 20px auto;
        border: none;
        background-color: #FF2F46;
        color: white;
        font-size: 15px;
    }

    .upcoming-event-section-button:hover {
        cursor: pointer;
    }

    .welcomecs-footer-container {
        color: black;
        width: 100%;
        padding: 20px 0px;
    }

    .welcomecs-footer-list-container {
        width: 100%;
        margin-top: 50px;
        text-align: center;
    }

    .welcomecs-footer-list-item {
        list-style-type: none;
        padding: 0px;
        margin-top: 10px;
        font-weight: lighter;
        font-size: 15px;
    }

    .welcomecs-footer-list-item:hover {
        color: #FF2F46;
    }

    .welcomecs-footer-list-item-question:hover {
        cursor: default;
        color: white;
    }

    .welcomecs-footer-follow-soon-container {
        text-align: center;
    }

    .welcomecs-footer-follow-soon-icons-container {
        display: block;
    }

    .welcomecs-footer-follow-soon-icons-title {
        margin: 0px auto;
    }

    .welcomecs-footer-follow-soon-icons-title:hover {
        cursor: default;
    }

    .welcomecs-footer-follow-soon-icons-title {
        margin-top: 75px;
        color: white;
        font-size: larger;
    }

    .welcomecs-footer-follow-soon-icons {
        width: 100%;
        margin-top: 10px;
    }

    .welcomecs-footer-follow-soon-icon {
        margin: 0px 10px;
    }

    .welcomecs-footer-follow-social-icon {
        height: 35px;
    }

    .welcomecs-footer-follow-soon-store-container {
        display: inline-block;
        vertical-align: top;
        margin: auto 50px;
    }

    .welcomecs-footer-follow-soon-icons-google {
        width: 135px;
    }

    .welcomecs-footer-follow-soon-icons-apple {
        width: 135px;
    }

    .welcomecs-footer-bottom-title {
        font-size: 10px;
        margin-top: 50px;
        width: 100%;
        text-align: center;
    }

    .welcomecs-footer-bottom-title-title {
        margin: 0px 10px;
    }

    .welcomecs-footer-bottom-title-title:hover {
        cursor: default;
    }

    .welcomecs-footer-bottom-title-link {
        color: black;
        margin: 0px 10px;
    }

    .welcomecs-footer-bottom-title-link:hover {
        color:#FF2F46;
    }

    .welcomecs-footer-blogs-title {
        margin: 0px;
        margin-top: 75px;
        width: 100%;
        text-align: center;
        color: white;
        font-size: larger;
    }

    .welcomecs-footer-blogs-container {
        width: 100%;
        height: 350px;
        display: block;
        margin: 0px;
        margin-top: 25px;
        white-space: nowrap;
        overflow-x: scroll; 
        overflow-y: hidden; 
    }

    ::-webkit-scrollbar {
        width: 0px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #525252;
        opacity: .5;
    }

    .welcomecs-blog-box {
        display: inline-block;
        vertical-align: top;
        width: 305px;
        height: 300px;
        border: solid 2px #FF2F46;
        border-radius: 5px;
        background-color: #232323;
        margin: 10px;
        overflow: hidden;
    }

    .welcomecs-blog-image {
        max-height: 70%;
        min-width: 100%;
    }

    .welcomecs-blog-title {
        width: 90%;
        color: white;
        overflow-wrap: break-word;
        margin: 0px 5px;
        height: 50px;
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .hide-tablet {
        display: none;
    }

    .header {
        width: 100%;
        padding: 10px 0;
        display: flex;
        flex-direction: row;
    }

    .header-title {
        margin: auto 10px;
        width: 50%;
        font-size: 13px;
    }

    .header-logo {
        width: 120px;
        margin-top: 5px;
    }
    
    .home-header-title:hover {cursor: default;}

    .header-items {
        display: flex;
        padding: 0;
        margin: 5px 10px;
        list-style-type: none;
        width: 50%;
        justify-content: flex-end;
    }

    .header-item {
        margin: auto 0px;
        font-size: 12px;
        color: black;
    }

    .header-item:hover {
        border-bottom: solid #FF2F46 2px;
    }

    .welcomecs-intro-content-container {
        color: white;
        width: 500px;
        text-align: center;
        cursor: default;
        margin: 0px auto;
        margin-top: 15px;
    }

    .welcomecs-intro-content-container h1 {
        margin-bottom: 0px;
    }

    .welcomecs-intro-content-container h2 {
        margin-top: 0px;
    }

    .welcomecs-countdown-style {
        display: inline-block;
        vertical-align: top;
        margin: 0px 10px;
    }

    .welcomecs-email-launch-notification-form-container {
        color: white;
        width: 100%;
        text-align: center;
        margin-top: 50px;
    }   

    .welcomecs-email-launch-notification-form-container input {
        display: block;
        margin: 0px auto;
        width: 300px;
        height: 30px;
        background-color: transparent;
        border: 1px gray solid;
        border-radius: 10px;
        color: white;
        padding: 0px 10px;
        text-align: center;
    }

    .welcomecs-email-launch-notification-form-container button {
        display: block;
        margin: 10px auto;
        width: 320px;
        height: 30px;
        background-color: transparent;
        border: none;
        color: white;
        border: 1px solid #FF2F46;
        border-radius: 10px;
    }

    .welcomecs-email-launch-notification-form-container button:hover {
        background-color: #FF2F46;
        cursor: pointer;
    }

    .main-image-container {
        background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url(/static/media/motorcyk-motorcycle-road-rider-tablet.2fd97a71.jpg);
        background-repeat: no-repeat;
        background-size:cover;
        background-position: center;
        min-height: 100vh;
        padding: 20px;
        margin: 0px;
        display: block;
    }

    .upcoming-event-section-button {
        width: 500px;
        height: 50px;
        border-radius: 10px;
        margin: 20px auto;
        margin-top: 55px;
        border: none;
        background-color: #FF2F46;
        color: white;
        font-size: 15px;
    }

    .upcoming-event-section-button:hover {
        cursor: pointer;
    }

    .welcomecs-footer-container {
        color: black;
        width: 100%;
        padding: 20px 0px;
    }

    .welcomecs-footer-list-container {
        width: 100%;
        margin-top: 50px;
        text-align: center;
    }

    .welcomecs-footer-list-item {
        list-style-type: none;
        padding: 0px;
        margin-top: 10px;
        font-weight: lighter;
        font-size: 15px;
    }

    .welcomecs-footer-list-item:hover {
        color: #FF2F46;
    }

    .welcomecs-footer-list-item-question:hover {
        cursor: default;
        color: white;
    }

    .welcomecs-footer-follow-soon-container {
        text-align: center;
    }

    .welcomecs-footer-follow-soon-icons-container {
        display: block;
    }

    .welcomecs-footer-follow-soon-icons-title {
        margin: 0px auto;
    }

    .welcomecs-footer-follow-soon-icons-title:hover {
        cursor: default;
    }

    .welcomecs-footer-follow-soon-icons-title {
        margin-top: 45px;
        color: white;
        font-size: larger;
    }

    .welcomecs-footer-follow-soon-icons {
        width: 100%;
        margin-top: 10px;
    }

    .welcomecs-footer-follow-soon-icon {
        margin: 0px 10px;
    }

    .welcomecs-footer-follow-social-icon {
        height: 35px;
    }

    .welcomecs-footer-follow-soon-store-container {
        display: inline-block;
        vertical-align: top;
        margin: auto 50px;
    }

    .welcomecs-footer-follow-soon-icons-google {
        width: 135px;
    }

    .welcomecs-footer-follow-soon-icons-apple {
        width: 135px;
    }

    .welcomecs-footer-bottom-title {
        font-size: 10px;
        margin-top: 50px;
        width: 100%;
        text-align: center;
    }

    .welcomecs-footer-bottom-title-title {
        margin: 0px 10px;
    }

    .welcomecs-footer-bottom-title-title:hover {
        cursor: default;
    }

    .welcomecs-footer-bottom-title-link {
        color: black;
        margin: 0px 10px;
    }

    .welcomecs-footer-bottom-title-link:hover {
        color:#FF2F46;
    }

    .welcomecs-footer-blogs-title {
        margin: 0px;
        margin-top: 75px;
        width: 100%;
        text-align: center;
        color: white;
        font-size: larger;
    }

    .welcomecs-footer-blogs-container {
        width: 100%;
        height: 350px;
        display: block;
        margin: 0px;
        margin-top: 25px;
        white-space: nowrap;
        overflow-x: scroll; 
        overflow-y: hidden; 
    }

    ::-webkit-scrollbar {
        width: 0px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #525252;
        opacity: .5;
    }

    .welcomecs-blog-box {
        display: inline-block;
        vertical-align: top;
        width: 305px;
        height: 300px;
        border: solid 2px #FF2F46;
        border-radius: 5px;
        background-color: #232323;
        margin: 10px;
        overflow: hidden;
    }

    .welcomecs-blog-image {
        max-height: 70%;
        min-width: 100%;
    }

    .welcomecs-blog-title {
        width: 90%;
        color: white;
        overflow-wrap: break-word;
        margin: 0px 5px;
        height: 50px;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    
    .header {
        width: 100%;
        padding: 10px 0;
        display: flex;
        flex-direction: row;
    }

    .header-title {
        margin: auto 10px;
        width: 50%;
        font-size: 13px;
    }

    .header-logo {
        width: 120px;
        margin-top: 5px;
    }
    
    .home-header-title:hover {cursor: default;}

    .header-items {
        display: flex;
        padding: 0;
        margin: 5px 10px;
        list-style-type: none;
        width: 50%;
        justify-content: flex-end;
    }

    .header-item {
        margin: auto 0px;
        font-size: 12px;
        color: black;
    }

    .header-item:hover {
        border-bottom: solid #FF2F46 2px;
    }

    .welcomecs-intro-content-container {
        color: white;
        width: 500px;
        text-align: center;
        cursor: default;
        margin: 0px auto;
        margin-top: 15px;
    }

    .welcomecs-intro-content-container h1 {
        margin-bottom: 0px;
    }

    .welcomecs-intro-content-container h2 {
        margin-top: 0px;
    }

    .welcomecs-countdown-style {
        display: inline-block;
        vertical-align: top;
        margin: 0px 10px;
    }

    .welcomecs-email-launch-notification-form-container {
        color: white;
        width: 100%;
        text-align: center;
        margin-top: 50px;
    }   

    .welcomecs-email-launch-notification-form-container input {
        display: block;
        margin: 0px auto;
        width: 300px;
        height: 30px;
        background-color: transparent;
        border: 1px gray solid;
        border-radius: 10px;
        color: white;
        padding: 0px 10px;
        text-align: center;
    }

    .welcomecs-email-launch-notification-form-container button {
        display: block;
        margin: 10px auto;
        width: 320px;
        height: 30px;
        background-color: transparent;
        border: none;
        color: white;
        border: 1px solid #FF2F46;
        border-radius: 10px;
    }

    .welcomecs-email-launch-notification-form-container button:hover {
        background-color: #FF2F46;
        cursor: pointer;
    }

    .main-image-container {
        background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url(/static/media/motorcyk-motorcycle-ducati-panigale-desktop.539fa820.jpg);
        background-repeat: no-repeat;
        background-size:cover;
        background-position: center;
        min-height: 100vh;
        padding: 20px;
        margin: 0px;
        display: block;
    }

    .upcoming-event-section-button {
        width: 500px;
        height: 50px;
        border-radius: 10px;
        margin: 20px auto;
        border: none;
        background-color: #FF2F46;
        color: white;
        font-size: 15px;
    }

    .upcoming-event-section-button:hover {
        cursor: pointer;
    }

    .welcomecs-footer-container {
        color: black;
        width: 100%;
        padding: 20px 0px;
    }

    .welcomecs-footer-list-container {
        width: 100%;
        margin-top: 50px;
        text-align: center;
    }

    .welcomecs-footer-list-item {
        list-style-type: none;
        padding: 0px;
        margin-top: 10px;
        font-weight: lighter;
        font-size: 15px;
    }

    .welcomecs-footer-list-item:hover {
        color: #FF2F46;
    }

    .welcomecs-footer-list-item-question:hover {
        cursor: default;
        color: white;
    }

    .welcomecs-footer-follow-soon-container {
        text-align: center;
    }

    .welcomecs-footer-follow-soon-icons-container {
        display: block;
    }

    .welcomecs-footer-follow-soon-icons-title {
        margin: 0px auto;
    }

    .welcomecs-footer-follow-soon-icons-title:hover {
        cursor: default;
    }

    .welcomecs-footer-follow-soon-icons-title {
        margin-top: 45px;
        color: white;
        font-size: larger;

    }

    .welcomecs-footer-follow-soon-icons {
        width: 100%;
        margin-top: 10px;
    }

    .welcomecs-footer-follow-soon-icon {
        margin: 0px 10px;
    }

    .welcomecs-footer-follow-social-icon {
        height: 35px;
    }

    .welcomecs-footer-follow-soon-store-container {
        display: inline-block;
        vertical-align: top;
        margin: auto 50px;
    }

    .welcomecs-footer-follow-soon-icons-google {
        width: 135px;
    }

    .welcomecs-footer-follow-soon-icons-apple {
        width: 135px;
    }

    .welcomecs-footer-bottom-title {
        font-size: 10px;
        margin-top: 50px;
        width: 100%;
        text-align: center;
    }

    .welcomecs-footer-bottom-title-title {
        margin: 0px 10px;
    }

    .welcomecs-footer-bottom-title-title:hover {
        cursor: default;
    }

    .welcomecs-footer-bottom-title-link {
        color: black;
        margin: 0px 10px;
    }

    .welcomecs-footer-bottom-title-link:hover {
        color:#FF2F46;
    }

    .welcomecs-footer-blogs-title {
        margin: 0px;
        margin-top: 75px;
        width: 100%;
        text-align: center;
        color: white;
        font-size: larger;
    }

    .welcomecs-footer-blogs-container {
        width: 100%;
        height: 350px;
        display: block;
        margin: 0px;
        margin-top: 25px;
        white-space: nowrap;
        overflow-x: scroll; 
        overflow-y: hidden; 
        text-align: center;
    }

    ::-webkit-scrollbar {
        width: 0px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #525252;
        opacity: .5;
    }

    .welcomecs-blog-box {
        display: inline-block;
        vertical-align: top;
        width: 305px;
        height: 300px;
        border: solid 2px #FF2F46;
        border-radius: 5px;
        background-color: #232323;
        margin: 10px;
        overflow: hidden;
    }

    .welcomecs-blog-image {
        max-height: 70%;
        min-width: 100%;
    }

    .welcomecs-blog-title {
        width: 90%;
        color: white;
        overflow-wrap: break-word;
        margin: 0px 5px;
        height: 50px;
        text-align: start;
    }

}

.signup-container {
    width: 100%;
    padding: 30px 0px;
    margin: 0px;
    text-align: center;
    background-color: #232323;
    overflow: auto;
    height: 100vh;
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.no-dec {
    text-decoration: none;
}

.signup-logo-icon {
    width: 100px;
}

.signup-with-google-container {
    width: 320px;
    margin: 0px auto;
    text-align: center;
}

.signup-with-email {
    background-color: #232323;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 45px;
    width: 250px;
    margin: 20px auto;
    text-align: center;
}

.signup-with-google-container {
    width: 250px;
    margin: 0px auto;
    text-align: center;
}

.signup-spacer {
    font-family: "Poppins", "cursive";
    color: white;
    margin-top: 20px;
}

.signup-with-email:hover {cursor:pointer;}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {

    .signup-box {
        background-color: #191919;
        color: white;
        width: 80%;
        margin: 25px auto;
        margin-bottom: 50px;
        padding: 25px;
        border-radius: 10px;
        text-align: center;
        overflow: auto;
    }

    .signup-box .signup-box-title {
        font-family: "Permanent Marker", "cursive";
        font-size: 30px;
        margin: 20px auto;
    }

    .signup-box-apple {
        background-color: white;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        height: 35px;
        width: 350px;
        margin: 10px auto;
    }

    .signup-box-apple:hover {cursor:pointer;}

    .signup-box-email:hover {cursor:pointer;}

    .signup-spacer {
        margin: 50px auto;
        font-family: "Poppins", "cursive";
    }

    .form-input {
        display: block;
        margin: 15px auto;
        width: 85%;
        height: 30px;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        background-color: #232323;
        color: white;
        text-align: center;
        font-size: 15px;
    }

    .form-button {
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 85%;
        font-family: "Poppins", "cursive";
    }

    .form-button {cursor: pointer}

    .signup-box-forgot-password {
        font-family: "Poppins", "cursive";
        margin: 25px;
        color: #FF2F46
    }

    .signup-box-signup {
        font-family: "Poppins", "cursive";
        width: 90%;
        margin: 25px auto;
    }

    .signup-box-signup strong {
        color: #FF2F46;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {

    /* .signup-title {
        margin: 0px auto;
        font-family: "Permanent Marker", "cursive";
        width: 100%;
        font-size: 35px;

    } */

    .signup-box {
        background-color: #191919;
        color: white;
        width: 570px;
        margin: 25px auto;
        margin-bottom: 50px;
        padding: 25px;
        border-radius: 10px;
    }

    .signup-box .signup-box-title {
        font-family: "Permanent Marker", "cursive";
        font-size: 35px;
        margin: 20px auto;
    }



    .signup-box-apple:hover {cursor:pointer;}

    .signup-box-email:hover {cursor:pointer;}

    .signup-spacer {
        margin: 50px auto;
        font-family: "Poppins", "cursive";
    }

    .form-input {
        display: block;
        margin: 15px auto;
        width: 85%;
        height: 30px;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        background-color: #232323;
        color: white;
        text-align: center;
        font-size: 15px;
    }

    .form-button {
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 85%;
        font-family: "Poppins", "cursive";
    }

    .form-button {cursor: pointer}

    .signup-box-forgot-password {
        font-family: "Poppins", "cursive";
        margin: 25px;
        color: #FF2F46
    }

    .signup-box-signup {
        font-family: "Poppins", "cursive";
        width: 320px;
        margin: 25px auto;
    }

    .signup-box-signup strong {
        color: #FF2F46;
    }

    /* .signup-footer {
        font-family: "Poppins", "cursive";
        background-color: #232323;
        margin-top: 0px;
    } */

}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

/* @media only screen and (min-width: 992px) {} */
.signup-info-container {
    width: 100%;
    height: 100vh;
    padding: 30px 0px;
    margin: 0px;
    text-align: center;
    background-color: #232323;
    overflow: auto;


}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.no-dec {
    text-decoration: none;
}

.signup-logo {
    margin: 0px auto;
    width: 100px;
}

.signup-form-error-message {
    color: #FF2F46;
    font-family: "Poppins", "cursive";
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {

    .signup-info-box {
        background-color: #191919;
        color: white;
        width: 80%;
        margin: 25px auto;
        margin-bottom: 50px;
        padding: 25px;
        border-radius: 10px;
    }

    .signup-info-title {
        font-family: "Permanent Marker", "cursive";
        font-size: 35px;
        margin: 0px;
    }

    .signup-info-box-quote {
        font-family: "Poppins", "cursive";
    }

    .signup-info-box button {
        display: block;
        width: 85%;
        margin: 15px auto;   
    }

    .signup-info-box-apple {
        background-color: white;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        height: 35px;
    }

    .signup-info-box-google {
        background-color: #232323;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
    }

    .signup-info-box-facebook {
        background-color: #2B478D;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
    }

    .signup-info-box-twitter {
        background-color: #0096EE;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
    }

    .signup-info-box-email {
        background-color: #232323;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
    }

    .signup-info-box-apple:hover {cursor:pointer;}

    .signup-info-box-google:hover {cursor:pointer;}

    .signup-info-box-facebook:hover {cursor:pointer;}

    .signup-info-box-twitter:hover {cursor:pointer;}

    .signup-info-box-email:hover {cursor:pointer;}

    .signup-info-spacer {
        margin: 50px auto;
        font-family: "Poppins", "cursive";
    }

    .form-input {
        display: block;
        margin: 15px auto;
        width: 85%;
        height: 30px;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        background-color: #232323;
        color: white;
        text-align: center;
        font-size: 15px;
    }

    .form-button {
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 85%;
        font-family: "Poppins", cursive;
    }

    .form-button {cursor: pointer}

    .signup-info-box-forgot-password {
        font-family: "Poppins", cursive;
        margin: 25px;
        color: #FF2F46
    }

    .signup-info-box-signup-info {
        font-family: "Poppins", cursive;
    }

    .signup-info-box-signup-info strong {
        color: #FF2F46;
    }

    /* .signup-info-footer {
        font-family: "Poppins", cursive;
        font-size: 10px;
        margin: 0 auto;
        padding-bottom: 50px;
        background-color: #232323;
    } */
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .signup-info-title {
        margin: 0px auto;
        font-family: "Permanent Marker", cursive;
        width: 100%;
        font-size: 35px;
    }

    .signup-info-box {
        background-color: #191919;
        color: white;
        width: 575px;
        margin: 25px auto;
        margin-bottom: 50px;
        padding: 25px;
        border-radius: 10px;
    }

    .signup-info-box-title {
        font-family: "Indie Flower", cursive;
        font-size: 35px;
        margin: 20px auto;
    }

    .signup-info-box-quote {
        font-family: "Poppins", cursive;
    }

    .signup-info-box button {
        display: block;
        width: 85%;
        margin: 15px auto;   
    }

    .signup-info-box-apple {
        background-color: white;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        height: 35px;
    }

    .signup-info-box-google {
        background-color: #232323;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
    }

    .signup-info-box-facebook {
        background-color: #2B478D;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
    }

    .signup-info-box-twitter {
        background-color: #0096EE;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
    }

    .signup-info-box-email {
        background-color: #232323;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
    }

    .signup-info-box-apple:hover {cursor:pointer;}

    .signup-info-box-google:hover {cursor:pointer;}

    .signup-info-box-facebook:hover {cursor:pointer;}

    .signup-info-box-twitter:hover {cursor:pointer;}

    .signup-info-box-email:hover {cursor:pointer;}

    .signup-info-spacer {
        margin: 50px auto;
        font-family: "Poppins", cursive;
    }

    .form-input {
        display: block;
        margin: 15px auto;
        width: 85%;
        height: 30px;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        background-color: #232323;
        color: white;
        text-align: center;
        font-size: 15px;
    }

    .form-button {
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 85%;
        font-family: "Poppins", cursive;
    }

    .form-button {cursor: pointer}

    .signup-info-box-forgot-password {
        font-family: "Poppins", cursive;
        margin: 25px;
        color: #FF2F46
    }

    .signup-info-box-signup-info {
        font-family: "Poppins", cursive;
    }

    .signup-info-box-signup-info strong {
        color: #FF2F46;
    }

    /* .signup-info-footer {
        font-family: "Poppins", cursive;
        background-color: #232323;
        margin-top: 0px;
    } */

}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

/* @media only screen and (min-width: 992px) {} */

.signup-container {
    width: 100%;
    padding: 30px 0px;
    margin: 0px;
    text-align: center;
    background-color: #232323;
    overflow: auto;
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.no-dec {
    text-decoration: none;
}

.signup-plan-title {
    font-family: "Permanent Marker", "cursive";
    color: #FF2F46;
    margin-bottom: 0px;
}

.signup-plan-row {
    height: 10px;
    margin: auto;
    font-size: 12px;
}

.signup-plan-exit-button {
    display: block;
    width: 250px;
    height: 30px;
    margin: 0px auto;
    background-color: #FF2F46;
    color: white;
    border: none;
    border-radius: 10px; 
}

.signup-plan-exit-button:hover {
    cursor: pointer;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {

    .signup-table-title {
        font-size: 22px;
        color: white;
        width: 100%;
        margin: 10px auto;
    }

    .signup-table {
        text-align: center;
        background-color: #191919;
        color: white;
        width: 100%;
        margin: 25px auto;
        margin-bottom: 50px;
        padding: 25px 0px;
        border-radius: 10px;
    }

    .signup-plan-button {
        display: block;
        width: 100px;
        height: 30px;
        margin: 0px auto;
        margin-top: 30px;
        background-color: #FF2F46;
        color: white;
        border: none;
        border-radius: 10px;
    }

    .signup-plan-button:hover {cursor: pointer;}

    .form-input {
        display: block;
        margin: 15px auto;
        width: 85%;
        height: 30px;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        background-color: #232323;
        color: white;
        text-align: center;
        font-size: 15px;
    }

    /* .signup-footer {
        font-size: 10px;
        margin: 0 auto;
        padding-bottom: 50px;
        background-color: #232323;
    } */
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {

    .signup-table {
        background-color: #191919;
        color: white;
        width: 550px;
        margin: 25px auto;
        margin-bottom: 50px;
        padding: 25px;
        border-radius: 10px;
    }

    .signup-table-title {
        font-size: 30px;
        color: white;
        width: 100%;
        margin: 10px auto;
    }

    .signup-table-row-title {
        display: block;
        margin-top: 40px;
        width: 100%;
        text-align: center;
        font-size: 12px;

    }

    .signup-plan-button {
        display: block;
        width: 120px;
        height: 30px;
        margin: 0px auto;
        margin-top: 30px;
        background-color: #FF2F46;
        color: white;
        border: none;
        border-radius: 10px;
        font-size: 12px;
    }

    .signup-plan-button:hover {cursor: pointer;}

    .signup-table-apple {
        background-color: white;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        height: 35px;
    }

    .signup-table-google {
        background-color: #232323;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
    }

    .signup-table-facebook {
        background-color: #2B478D;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
    }

    .signup-table-twitter {
        background-color: #0096EE;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
    }

    .signup-table-email {
        background-color: #232323;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
    }

    .signup-table-apple:hover {cursor:pointer;}

    .signup-table-google:hover {cursor:pointer;}

    .signup-table-facebook:hover {cursor:pointer;}

    .signup-table-twitter:hover {cursor:pointer;}

    .signup-table-email:hover {cursor:pointer;}

    .signup-spacer {
        margin: 50px auto;
    }

    .form-input {
        display: block;
        margin: 15px auto;
        width: 85%;
        height: 30px;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        background-color: #232323;
        color: white;
        text-align: center;
        font-size: 15px;
    }

    .form-button {
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 85%;
    }

    .form-button {cursor: pointer}

    .signup-table-forgot-password {
        margin: 25px;
        color: #FF2F46
    }

    .signup-table-signup strong {
        color: #FF2F46;
    }

    /* .signup-footer {
        background-color: #232323;
        margin-top: 0px;
    } */

}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

/* @media only screen and (min-width: 992px) {} */

.signup-container {
    width: 100%;
    padding: 30px 0px;
    margin: 0px;
    text-align: center;
    background-color: #232323;
    overflow: auto;
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.no-dec {
    text-decoration: none;
}

.signup-plan-trial-title {
    font-family: "Permanent Marker", "cursive";
    color: #FF2F46;
    margin-bottom: 0px;
}

.signup-plan-trial-row {
    height: 10px;
    margin: auto;
    font-size: 12px;
}

.signup-plan-trial-exit-button {
    display: block;
    width: 250px;
    height: 30px;
    margin: 0px auto;
    background-color: #FF2F46;
    color: white;
    border: none;
    border-radius: 10px; 
}

.signup-plan-trial-exit-button:hover {
    cursor: pointer;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {

    .signup-table-title {
        font-size: 22px;
        color: white;
        width: 100%;
        margin: 10px auto;
    }

    .signup-table {
        text-align: center;
        background-color: #191919;
        color: white;
        width: 100%;
        margin: 25px auto;
        margin-bottom: 50px;
        padding: 25px 0px;
        border-radius: 10px;
    }

    .signup-plan-start-trial-button {
        display: block;
        width: 300px;
        height: 50px;
        margin: 0px auto;
        margin-top: -30px;
        margin-bottom: 30px;
        background-color: #FF2F46;
        color: white;
        border: none;
        border-radius: 10px;
        font-size: 18px;
    }

    .signup-plan-start-trial-button:hover {cursor: pointer;}

    .form-input {
        display: block;
        margin: 15px auto;
        width: 85%;
        height: 30px;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        background-color: #232323;
        color: white;
        text-align: center;
        font-size: 15px;
    }

    /* .signup-footer {
        font-size: 10px;
        margin: 0 auto;
        padding-bottom: 50px;
        background-color: #232323;
    } */
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {

    .signup-table {
        background-color: #191919;
        color: white;
        width: 550px;
        margin: 25px auto;
        margin-bottom: 50px;
        padding: 25px;
        border-radius: 10px;
    }

    .signup-table-title {
        font-size: 30px;
        color: white;
        width: 100%;
        margin: 10px auto;
    }

    .signup-table-row-title {
        display: block;
        margin-top: 40px;
        width: 100%;
        text-align: center;
        font-size: 12px;

    }

    .signup-plan-start-trial-button {
        display: block;
        width: 300px;
        height: 50px;
        margin: 0px auto;
        margin-top: -30px;
        margin-bottom: 30px;        
        background-color: #FF2F46;
        color: white;
        border: none;
        border-radius: 10px;
        font-size: 18px;
    }

    .signup-plan-start-trial-button:hover {cursor: pointer;}

    .signup-table-apple {
        background-color: white;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        height: 35px;
    }

    .signup-table-google {
        background-color: #232323;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
    }

    .signup-table-facebook {
        background-color: #2B478D;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
    }

    .signup-table-twitter {
        background-color: #0096EE;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
    }

    .signup-table-email {
        background-color: #232323;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
    }

    .signup-table-apple:hover {cursor:pointer;}

    .signup-table-google:hover {cursor:pointer;}

    .signup-table-facebook:hover {cursor:pointer;}

    .signup-table-twitter:hover {cursor:pointer;}

    .signup-table-email:hover {cursor:pointer;}

    .signup-spacer {
        margin: 50px auto;
    }

    .form-input {
        display: block;
        margin: 15px auto;
        width: 85%;
        height: 30px;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        background-color: #232323;
        color: white;
        text-align: center;
        font-size: 15px;
    }

    .form-button {
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 85%;
    }

    .form-button {cursor: pointer}

    .signup-table-forgot-password {
        margin: 25px;
        color: #FF2F46
    }

    .signup-table-signup strong {
        color: #FF2F46;
    }

    /* .signup-footer {
        background-color: #232323;
        margin-top: 0px;
    } */

}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

/* @media only screen and (min-width: 992px) {} */

.signup-image-upload-container {
    margin-top: -100;
    background-color: #232323;
    height: 100vh;
    padding-bottom: 200px;
}

.signup-image-upload-edit-section {
    width: 90%;
    padding: 10px;
    text-align: center;
    margin: auto;
    margin-bottom: 200px;
}

.edit-profile-file-name {
    width: 300px;
    margin: 5px auto;
    overflow-wrap: break-word;
}

.signup-image-upload-icon-container {
    margin: 30px auto;
    text-align: center;
    padding: 0px;
}

.signup-image-upload-image{
    display: block;
    padding: 0px;
    width: 175px;
    margin: 30px auto;
    margin-bottom: 0px;
    border-radius: 50%;
}

.signup-image-upload-container-s3 {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 175px;
    height: 175px;
    margin: 50px auto;
    border-radius: 50%;
    overflow:hidden;
    border: .1px solid black;
    background-size: cover;
    background-position: center center;
}

.signup-image-upload-form-button {
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
    margin-top:  20px;
}

.signup-image-upload-form-button {cursor: pointer}

.signup-image-upload-error-message {
    color: #FF2F46;
    font-family: "Poppins", "cursive";
    margin: 0px auto;
    margin-top: 20px;
}

.uploader__container {
    margin: 50px auto;
}

.signup-image-upload-button {
    width: 200px;
    height: 30px;
    background-color: black;
    color: white;
    border-radius: 20px;
    border: none;
}

.signup-image-upload-button:hover {
    cursor: pointer;
}

.bottom-button {
    margin-bottom: 200px;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */
@media only screen and (max-width: 600px) {
    .signup-image-upload-title-container {
        width: 100%;
        text-align: center;
        background-color:#232323;
        padding-top: -50px;
    }
    
    .signup-image-upload-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin: 0px;
        padding-top: 75px;
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .signup-image-upload-title-container {
        width: 100%;
        text-align: center;
        background-color:#232323;
        padding-top: -50px;
    }
    
    .signup-image-upload-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin: 0px;
        padding-top: 75px;
    }

    .signup-image-upload-form-input {
        width: 400px;
    }

    .signup-image-upload-form-button {
        width: 400px;
        margin: 10px auto;
        display: block;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}

.signup-profile-details-container {
    margin-top: -100;
    background-color: #232323;
    height: 100vh;
    padding-bottom: 200px;
}

.signup-profile-details-edit-section {
    width: 90%;
    padding: 10px;
    text-align: center;
    margin: auto;
    margin-bottom: 200px;
}

.signup-profile-details-image {
    width: 100px;
    margin: auto;
    margin-top: 50px;
    display: block;
}

.signup-profile-details-form-input {
    display: block;
    margin: 5px auto;
    width: 85%;
    height: 30px;
    border: solid 2px #FF2F46;
    border-radius: 5px;
    background-color: #232323;
    color: white;
    text-align: center;
    font-size: 15px;
}

.signup-profile-details-form-button {
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
    margin-top:  20px;
}

.signup-profile-details-form-button {cursor: pointer}

.signup-profile-details-error-message {
    color: #FF2F46;
    font-family: "Poppins", "cursive";
    margin: 0px auto;
    margin-top: 20px;
}

.bottom-button {
    margin-bottom: 200px;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */
@media only screen and (max-width: 600px) {
    .signup-profile-details-title-container {
        width: 100%;
        text-align: center;
        background-color:#232323;
        padding-top: -50px;
    }
    
    .signup-profile-details-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin: 0px;
        padding-top: 75px;    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .signup-profile-details-title-container {
        width: 100%;
        text-align: center;
    }
    
    .signup-profile-details-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin: 0px;
        padding-top: 75px;
    }

    .signup-profile-details-form-input {
        width: 400px;
    }

    .signup-profile-details-form-button {
        width: 400px;
        margin: 10px auto;
        display: block;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}

.signup-container {
    width: 100%;
    padding: 30px 0px;
    margin: 0px;
    text-align: center;
    background-color: #232323;
}

.confirmation {
    height: 100vh;
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.no-dec {
    text-decoration: none;
}

.signup-title {
    font-family: "Permanent Marker", "cursive";
}

.signup-message {
    font-family: "Poppins", "cursive";
    width: 85%;
    margin: 50px auto;
    margin-top: 40px;
    font-size: 15px;
}

.signup-continue {
    margin: 10px auto;
    display: block;
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 300px;
}

.signup-continue:hover {
    cursor: pointer;
}

.signup-create-event {
    width: 55px;
    height: 17px;
    color: white;
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    margin: 0px 2px;
    padding: auto;
}

.signup-create-event:hover {
    cursor: pointer;
}

/* .signup-footer {
    font-family: "Poppins", "cursive";
    font-size: 10px;
    margin: 300px auto;
    padding-bottom: 50px;
    background-color: #232323;
} */

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.no-dec {
    text-decoration: none;
}

.error-message {
    margin-top: -30px;
}

.g_error-message {
    margin-top: 30px;
}

.signin-logo {
    margin: 0px auto;
    width: 100px;
}

.signin-spacer {
    font-family: "Poppins", "cursive";
    color: white;
    margin-bottom: 55px;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .signin-container {
        width: 100%;
        height: 100vh;
        padding: 0px;
        padding-top: 25px;
        padding-bottom: 100px;
        margin: 0px;
        text-align: center;
        overflow: auto;
        background-color: #232323;
    }

    .signin-box {
        background-color: #191919;
        color: #FF2F46;
        width: 80%;
        margin: 25px auto;
        margin-bottom: -20px;
        padding: 25px;
        border-radius: 10px;
    }

    .signin-box-title {
        font-family: "Permanent Marker", "cursive";
        font-size: 35px;
        margin: 0px;
    }

    .signin-box-quote {
        font-family: "Poppins", "cursive";
    }

    .signin-with-google-container {
        width: 250px;
        margin: 0px auto;
        text-align: center;
    }

    .signin-box-google {
        background-color: #232323;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
    }

    .signin-form-input {
        display: block;
        margin: 15px auto;
        width: 250px;
        height: 30px;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        background-color: #232323;
        color: white;
        text-align: center;
        font-size: 15px;
    }

    .signin-form-button {
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 250px;
        font-family: "Poppins", "cursive";
        margin: auto;
    }

    .signin-form-button:hover {cursor: pointer}

    .signin-box-forgot-password {
        font-family: "Poppins", "cursive";
        margin: 25px 0px;
        color: #FF2F46
    }

    .signin-box-signup {
        font-family: "Poppins", "cursive";
    }

    .signin-box-signup strong {
        color: #FF2F46;
    }

    .signin-footer {
        font-family: "Poppins", "cursive";
        font-size: 10px;
        margin: 0 auto;
        margin-top: 50px;
        /* padding-top: 100px; */
        height: 50vw;
        background-color: #232323;
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .signin-container {
        width: 100%;
        height: 100hh;
        padding: 0px;
        padding-top: 25px;
        padding-bottom: 25px;
        margin: 0px;
        text-align: center;
        background-color: #232323;
        overflow: auto;

    }

    .signin-box {
        background-color: #191919;
        color: white;
        width: 550px;
        margin: 25px auto;
        margin-bottom: -50px;
        padding: 25px;
        border-radius: 10px;
        overflow: auto;

    }

    .signin-box-title {
        font-family: "Permanent Marker", "cursive";
        font-size: 35px;
        margin: 0px;
    }

    .signin-box-quote {
        font-family: "Poppins", "cursive";
    }

    .signin-with-google-container {
        width: 250px;
        margin: 0px auto;
        text-align: center;
    }

    .signin-form-input {
        display: block;
        margin: 15px auto;
        width: 300px;
        height: 30px;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        background-color: #232323;
        color: white;
        text-align: center;
        font-size: 15px;
    }

    .signin-form-button {
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 310px;
        font-family: "Poppins", "cursive";
    }

    .signin-form-button:hover {cursor: pointer}

    .signin-box-forgot-password {
        font-family: "Poppins", "cursive";
        margin: 25px 0px;
        padding: 0px;
        color: #FF2F46
    }

    .signin-box-signup {
        font-family: "Poppins", "cursive";
    }

    .signin-box-signup strong {
        color: #FF2F46;
    }

    .signin-footer {
        font-family: "Poppins", "cursive";
        background-color: #232323;
        margin: 0px auto;
        padding-top: 50px;
        margin-top: 70px;
        height: 50vh;
    }

}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    /* .signin-container {
        overflow: inherit;
        height: 100vh;
    } */

    /* .signin-box {
        background-color: #191919;
        color: white;
        width: 550px;
        margin: 25px auto;
        margin-bottom: -20px;
        padding: 25px;
        border-radius: 10px;
    } */

    /* .signin-footer {
        font-family: "Poppins", cursive;
        background-color: #232323;
        padding-top: 50px;
        height: 50vh;
        margin: 0px;
    } */
}
.forgot-password-container {
    width: 100%;
    height: 100vh;
    padding: 0px;
    padding-top: 25px;
    margin: 0px;
    text-align: center;
    background-color: #232323;
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.no-dec {
    text-decoration: none;
}

.forgot-password-title {
    font-family: "Permanent Marker", "cursive";
}

.forgot-password-message {
    font-family: "Poppins", "cursive";
    width: 300px;
    margin: 50px auto;
}

.forgot-password-form {
    background-color: #232323;
}

.forgot-password-button {
    margin: 10px auto;
    display: block;
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
}

.forgot-password-cancel {
    margin: 10px auto;
    display: block;
    background-color: white;
    border: none;
    border-radius: 5px;
    color: #232323;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
}

.forgot-password-submit:hover, .forgot-password-cancel:hover {
    cursor: pointer;
}

.forgot-password-footer {
    font-family: "Poppins", "cursive";
    font-size: 10px;
    margin: 300px auto;
    padding-bottom: 50px;
    background-color: #232323;
}

.forgot-logo {
    margin: 0px auto;
    margin-top: 30px;
    width: 100px;
}

.forgot-password-button:hover {
    cursor: pointer;
}

.forgot-password-form-error-message {
    color: #FF2F46;
    font-family: "Poppins", "cursive";
    width: 400px;
    margin: 0px auto;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */
@media only screen and (max-width: 600px) {
    .forgot-password-title-container {
        width: 100%;
        text-align: center;
    }
    
    .forgot-password-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .forgot-password-input {
        margin: 25px auto;
        display: block;
        width: 85%;
        height: 30px;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        background-color: #232323;
        color: white;
        text-align: center;
        font-size: 15px;
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .forgot-password-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
        font-size: 45px;
    }

    .forgot-password-form-input {
        width: 400px;
    }

    .forgot-password-form-button {
        width: 400px;
    }

    .forgot-password-input {
        margin: 25px auto;
        display: block;
        width: 480px;
        height: 30px;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        background-color: #232323;
        color: white;
        text-align: center;
        font-size: 15px;
    }

    .forgot-password-button {
        margin: 10px auto;
        display: block;
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 480px;
        font-family: "Poppins", "cursive";
    }
    
    .forgot-password-cancel {
        margin: 10px auto;
        display: block;
        background-color: white;
        border: none;
        border-radius: 5px;
        color: #232323;
        font-size: 15px;
        height: 35px;
        width: 480px;
        font-family: "Poppins", "cursive";
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .forgot-password-title {
        font-size: 55px;
    }
}

.reset-password-container {
    width: 100%;
    height: 100vh;
    padding: 0px;
    padding-top: 25px;
    margin: 0px;
    text-align: center;
    background-color: #232323;
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.no-dec {
    text-decoration: none;
}

.reset-password-title {
    font-family: "Permanent Marker", "cursive";
}

.reset-password-message {
    font-family: "Poppins", "cursive";
    width: 320px;
    margin: 50px auto;
}

.reset-password-form {
    background-color: #232323;
    padding-bottom: 20px;
}

.reset-password-button {
    margin: 10px auto;
    display: block;
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
}

.reset-password-cancel {
    margin: 10px auto;
    display: block;
    background-color: white;
    border: none;
    border-radius: 5px;
    color: #232323;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
}

.reset-password-submit:hover, .reset-password-cancel:hover {
    cursor: pointer;
}

.reset-password-footer {
    font-family: "Poppins", "cursive";
    font-size: 10px;
    margin: 0px auto;
    padding: 150px;
    background-color: #232323;
}

.reset-logo {
    margin: 0px auto;
    margin-top: 30px;
    width: 100px;
}

.reset-password-button:hover {
    cursor: pointer;
}

.reset-password-form-error-message {
    color: #FF2F46;
    font-family: "Poppins", "cursive";
    width: 400px;
    margin: 0px auto;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */
@media only screen and (max-width: 600px) {
    .reset-password-title-container {
        width: 100%;
        text-align: center;
    }
    
    .reset-password-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .reset-password-input {
        margin: 25px auto;
        display: block;
        width: 85%;
        height: 30px;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        background-color: #232323;
        color: white;
        text-align: center;
        font-size: 15px;
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .reset-password-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
        font-size: 45px;
    }

    .reset-password-form-input {
        width: 400px;
    }

    .reset-password-form-button {
        width: 400px;
    }

    .reset-password-input {
        margin: 25px auto;
        display: block;
        width: 480px;
        height: 30px;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        background-color: #232323;
        color: white;
        text-align: center;
        font-size: 15px;
    }

    .reset-password-button {
        margin: 10px auto;
        display: block;
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 480px;
        font-family: "Poppins", "cursive";
    }
    
    .reset-password-cancel {
        margin: 10px auto;
        display: block;
        background-color: white;
        border: none;
        border-radius: 5px;
        color: #232323;
        font-size: 15px;
        height: 35px;
        width: 480px;
        font-family: "Poppins", "cursive";
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .reset-password-title {
        font-size: 55px;
    }
}

.home-container {
    width: 100%;
    padding: 0px;
    /* padding-top: 25px; */
    padding-bottom: 500px;
    margin: 0px;
    /* font-family: "Poppins", "cursive"; */

}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.b {
    color: black;
}

.no-dec {
    text-decoration: none;
}

.hover:hover {
    border-bottom: 2px solid #FF2F46;
}

.no-pointer:hover {
    cursor: default;
}

.home-not-active {
    width: 95%;
    text-align: center;
    margin: 5px auto;
}

.home-feed-link {
    font-family: "Permanent Marker", "cursive";
    text-decoration: none;
    color: #FF2F46;
    font-size: 18px;
}

.home-feed-link:hover {
    text-decoration: underline;
}

.home-redirect-button {
    width: 55px;
    height: 26px;
    background-color:white;
    border: 1px solid #FF2F46;
    color: black;
    border-radius: 5px;
    text-align: center;
}

.home-redirect-button:hover {
    background-color:#FF2F46;
    color: white;
    cursor: pointer;
}

.launch-message-link:hover {
    border-bottom: 2px solid #FF2F46;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .td {display: none;}

    .d {display: none;}

    .tablet-view {display: none;}

    .home-header {
        display: block;
        width: 100%;
        height: 50px;
        background-color: white;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
    }

    .home-header-title {
        display: inline-block;
        vertical-align: top;
        width: 40%;
        padding: 0px 10px;
        margin-top: 10px;
    }

    .home-header-title:hover {cursor: default;}

    .home-header-items {
        list-style-type: none;
        width: 40%;
        float: right;  
        padding: 5px 10px;  
    }

    .home-header-items li {
        margin: 0px 5px;
        float: right;    
    }

    .launch-message {
        width: 90%;
        margin: 10px auto;
        margin-top: 60px;
        margin-bottom: -60px;
        text-align: center;
        font-size: 10px;
    }

    .home-create-event-message {
        width: 90%;
        margin: 0px auto;
        /* text-align: center; */
        font-size: 10px;
    }

    .home-message-dot {
        width: 10px;
        margin-bottom: 15px;
        margin-left: -10px;
    }

    .home-user-icon, .home-messages-icon {
        width: 18px;
        height: 18px;
        margin-right: 5px;
    }

    .home-greet {
        font-family: "Permanent Marker", "cursive";
        margin-left: 10px;
        margin-top: 75px;
    }

    .home-upcoming-container {
        width: 100%;
        margin: 0px;
    }

    .home-upcoming-title {
        font-family: "Permanent Marker", "cursive";
        margin-left: 10px;
    }

    .home-upcoming-statement {
        margin-left: 40px;
    }

    .home-available-container {
        width: 100%;
        height: 300px;
        display: block;
        margin: 0px;
        white-space: nowrap;
        overflow-x: scroll; 
        overflow-y: hidden; 
    }

    ::-webkit-scrollbar {
        width: 0px;
    }
      
    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #525252;
        opacity: .5;
    }

    .home-saved-container {
        width: 100%;
        height: 300px;
        display: block;
        margin: 0px;
        white-space: nowrap;
        overflow-x: scroll; 
        overflow-y: hidden; 
    }

    ::-webkit-scrollbar {
        width: 0px;
    }
      
    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #525252;
        opacity: .5;
    }

    .home-feed-container {
        width: 100%;
        display: block;
        text-align: center;
    }

    .home-feed {
        width: 90%;
        margin: 0px auto;
        margin-top: 30px;
    }

    .home-feed-date-time {
        margin-bottom: -10px;
        /* margin-left: 20px; */
    }

    .home-feed-stuff {
        /* margin-left: 20px; */
    }

    .home-available-title {
        font-family: "Permanent Marker", "cursive";
        margin-top: 50px;
        margin-left: 10px;
        margin-bottom: -20px;
    }

    .home-saved-title {
        font-family: "Permanent Marker", "cursive";
        margin-top: 20px;
        margin-left: 10px;
        margin-bottom: -10px;
        display: inline-block;
        vertical-align: top;
    }

    .home-feed-title {
        font-family: "Permanent Marker", "cursive";
        margin-top: 20px;
        margin-left: 10px;
        margin-bottom: -50px;
        display: inline-block;
        vertical-align: top;
    }

    .home-event-upcoming-box {
        border: #FF2F46 solid 2px;
        width: 97%;
        margin: 30px auto;
        text-align: center;
        border-radius: 10px;
    }

    .home-event-box {
        display: inline-block;
        vertical-align: top;
        border: #FF2F46 solid 2px;
        width: 225px;
        margin: 5px;
        text-align: center;
        border-radius: 10px;
    }

    .home-event-date {
        font-size: 10px;
    }

    .home-event-address {
        font-size: 10px;
    }

    .home-tab-icon {
        height: 10px;
        margin-left: 5px;
    }

    .home-event-address-link:hover {
        border-bottom: #FF2F46 solid 2px;
        cursor: pointer;
    }

    .home-event-title {
        font-family: "Permanent Marker", "cursive";
        width: 70%;
        font-size: 90%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: "center";
        margin:0px auto;
        display: inline-block;
    }

    .home-event-title:hover {
        cursor: pointer; /* IE 9 */ /* Safari 3-8 */
        transform: scale(1.2); 
    }

    .home-event-upcoming-address {
        font-size: 15px;
    }

    .home-event-address-link:hover {
        border-bottom: #FF2F46 solid 2px;
        cursor: pointer;
    }

    .home-event-upcoming-title {
        font-family: "Permanent Marker", "cursive";
        font-size: 25px;
        margin: 0px auto;
        width: 75%;
    }

    .home-event-upcoming-title:hover {
        cursor: pointer; /* IE 9 */ /* Safari 3-8 */
        transform: scale(1.2); 
    }

    .home-event-upcoming-going {
        font-size: 15px;
        margin: 20px auto;
    }

    .home-event-going {
        font-size: 10px;
    }

    .home-event-button {
        width: 100%;
        height: 40px;
        border: none;
        color: white;
        background-color: #FF2F46;
        opacity: 1;
        border-radius: 3px;
        font-size: 15px;
    }

    .home-event-button-private {
        width: 100%;
        height: 40px;
        border: none;
        color: white;
        background-color: #FF2F46;
        opacity: .5;
        border-radius: 3px;
        font-size: 15px;
    }

    .home-event-button-private:hover {
        cursor: not-allowed;
    }

    .home-event-button:hover {
        cursor: pointer;
        opacity: .7;
    }

    .back-to-top-container {
        margin-top: 50px;
        width: 100%;
   }

    .back-to-top {
        text-decoration: none;
    }

    .home-profiles-container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-bottom: -25px;
    }

    .home-rider-recommendations-container {
        margin: 35px auto;
        text-align: center;
    }

    .home-rider-recommendations-title {
        font-size: 12px;
    }

    .home-rider-profile-recommendations-box {        
        padding: 3px;
        width: 75px;
        margin: 5px 5px;
    }

    .home-rider-profile-image {
        width: 50px;
        border-radius: 50%;
        height: 50px;
    }

    .home-rider-profile-name {
        font-family: "Permanent Marker", "cursive";
        margin-top: -5px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 12px;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .mobile-nav-bar, .mobile {display: none}

    .d {display: none}

    .home-header {
        display: block;
        width: 100%;
        height: 50px;
        background-color: white;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
    }

    .home-header-title {
        display: inline-block;
        vertical-align: top;
        width: 20%;
        padding: 0px 10px;
        margin-top: 10px;
    }

    .home-header-title:hover {cursor: default;}

    .home-header-items {
        list-style-type: none;
        width: 72%;
        float: right;  
        padding: 5px 10px;
        padding-left: 0px;
        font-size: 12px;
    }

    .home-header-items li {
        margin: 2px 10px;
        float: right;    
    }

    .home-header-items li:hover {
        border-bottom: solid #FF2F46 2px;
    }

    .launch-message {
        width: 100%;
        margin: 10px auto;
        text-align: center;
    }

    .home-create-event-message {
        width: 90%;
        margin: 0px auto;
        margin-top: 20px;
        text-align: center;
        font-size: 10px;
    }

    .home-user-icon, .home-messages-icon {
        width: 18px;
        height: 18px;
        margin-right: 5px;
    }

    .home-message-dot {
        width: 10px;
        margin: 0px;
        margin-left: -4px;
    }

    .home-upcoming-container {
        width: 100%;
        height: 310px;
        margin: 0px;
        margin-top: 75px;
        background-color: #FF2F46;
    }

    .home-greet {
        font-family: "Permanent Marker", "cursive";
        margin-bottom: 0px;
        width: 80%;
        padding-top: 20px;
        padding-left: 20px;
    }

    .home-upcoming-title {
        margin-top: 0px;
        padding-left: 20px;
        padding-bottom: 15px;
        width: 80%;
        display: inline-block;
    }
    
    .home-title-short {
        margin-top: 20px;
        font-size: 22px;
    }

    .home-upcoming-border {
        color: white;
        width: 93%;
        margin: 0px auto;
    }

    .home-upcoming-statement-td {
        margin-left: 40px;
        text-align: center;
        margin-top: 50px;
    }

    .home-available-container {
        width: 100%;
        height: 300px;
        display: block;
        margin: 0px;
        white-space: nowrap;
        overflow-x: scroll; 
        overflow-y: hidden; 
    }

    ::-webkit-scrollbar {
        width: 0px;
    }
      
    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #525252;
        opacity: .5;
    }

    .home-saved-container {
        width: 100%;
        height: 300px;
        display: block;
        margin: 0px;
        white-space: nowrap;
        overflow-x: scroll; 
        overflow-y: hidden; 
    }

    ::-webkit-scrollbar {
        width: 0px;
    }
      
    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #525252;
        opacity: .5;
    }

    .home-feed-container {
        width: 100%;
        display: block;
    }

    .home-feed {
        width: 100%;
        margin-top: 30px;
    }

    .home-feed-date-time {
        margin-bottom: -10px;
        margin-left: 20px;
    }

    .home-feed-stuff {
        margin-left: 20px;
    }

    .home-available-title {
        font-family: "Permanent Marker", "cursive";
        margin-top: 30px;
        margin-left: 10px;
        margin-bottom: -10px;
        font-size: 20px;
        display: inline-block;
        vertical-align: top;
    }

    .home-saved-title {
        font-family: "Permanent Marker", "cursive";
        margin-top: 30px;
        margin-left: 10px;
        margin-bottom: -10px;
        font-size: 20px;
        display: inline-block;
        vertical-align: top;
    }

    .home-feed-title {
        font-family: "Permanent Marker", "cursive";
        margin-top: 30px;
        margin-left: 10px;
        margin-bottom: -50px;
        font-size: 20px;
        display: inline-block;
        vertical-align: top;
    }

    .title-attending-section {
        display: inline-block;
        vertical-align: top;
        width: 40%;
        height: 150px;
        margin-left: 20px;
        margin-top: 20px;
    }

    .title-attending-section h1 {
        margin-top: 10px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .title-attending-section a:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    .title-attending-section p {
        margin: 10px 0px;
        width: 220px;
    }

    .date-address-section {
        display: inline-block;
        vertical-align: top;
        width: 40%;
        height: 150px;
        margin-left: 20px;
    }

    .date-address-section a:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    .home-event-box {
        display: inline-block;
        vertical-align: top;
        border: #FF2F46 solid 2px;
        width: 300px;
        margin: 5px;
        text-align: center;
        border-radius: 10px;
    }

    .home-event-date {
        font-size: 10px;
    }

    .home-event-address {
        font-size: 10px;
    }

    .home-tab-icon {
        height: 10px;
        margin-left: 5px;
    }

    .home-event-address-link:hover {
        border-bottom: #FF2F46 solid 2px;
        cursor: pointer;
    }

    .home-event-title {
        font-family: "Permanent Marker", "cursive";
        width: 70%;
        font-size: 90%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: "center";
        margin:0px auto;
        display: inline-block;
    }

    .home-event-title:hover {
        cursor: pointer; /* IE 9 */ /* Safari 3-8 */
        transform: scale(1.2); 
    }

    .home-event-going {
        font-size: 10px;
    }

    .home-event-button {
        width: 100%;
        height: 40px;
        border: none;
        color: white;
        background-color: #FF2F46;
        opacity: 1;
        border-radius: 3px;
        font-size: 15px;
    }

    .home-event-button-private {
        width: 100%;
        height: 40px;
        border: none;
        color: white;
        background-color: #FF2F46;
        opacity: .5;
        border-radius: 3px;
        font-size: 15px;
    }

    .home-event-button-private:hover {
        cursor: not-allowed;
    }

    .home-event-button:hover {
        cursor: pointer;
        opacity: .7;
    }

    .back-to-top-container {
        margin-top: 50px;
        width: 100%;
        text-align: center;
    }

    .home-go-explore {
        margin-left: 20px;
        margin-top: 38px;
        margin-bottom: 0px;
        text-decoration: none;
        display: inline-block;
        vertical-align: middle;
        font-size: 12px;
    }

    .home-go-explore:hover {
        border-bottom: solid #FF2F46 1px;
    }

    .home-no-data {
        text-align: center;
    }

    .home-profiles-container {
        display: block;
        width: 600px;
        margin: 10px auto;
        /* flex-direction: row;
        justify-content: space-evenly; */
    }

    .home-rider-recommendations-container {
        margin: 35px auto;
        text-align: center;
    }

    .home-rider-recommendations-title {
        font-size: 12px;
        cursor: default;
    }

    .home-rider-profile-recommendations-box {    
        display: inline-block;    
        padding: 3px;
        width: 100px;
        margin: 5px 5px;
    }

    .home-rider-profile-image {
        width: 75px;
        border-radius: 50%;
        height: 75px;
    }

    .home-rider-profile-name {
        font-family: "Permanent Marker", "cursive";
        margin-top: -5px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 15px;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .mobile-nav-bar {display: none;}

    .tablet-view {display: none;}
    
    .d {display: inline-block;}

    .title-attending-section {
        width: 350px;
        margin-right: 20px;
    }

    .title-attending-section p {
        margin: 10px 0px;
        width: 220px;
    }

    .home-upcoming-container {
        width: 100%;
        height: 310px;
        margin: 0px;
        background-color: #FF2F46;
        margin-top: 75px;
    }

    .home-road-icon {
        margin: auto;
        margin-top: 15px;
        width: 150px;
    }

    .date-address-section {
        width: 300px;
        margin-left: 75px;
    }

    .home-available-container {
        width: 100%;
        height: 300px;
        display: block;
        margin: 0px;
        white-space: nowrap;
        overflow-x: scroll; 
        overflow-y: hidden; 
    }

    ::-webkit-scrollbar {
        width: 0px;
    }
      
    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #525252;
        opacity: .5;
    }

    .home-saved-container {
        width: 100%;
        height: 300px;
        display: block;
        margin: 0px;
        white-space: nowrap;
        overflow-x: scroll; 
        overflow-y: hidden; 
    }


    ::-webkit-scrollbar {
        width: 0px;
    }
      
    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #525252;
        opacity: .5;
    }

    .home-feed-container {
        width: 100%;
        display: block;
    }

    .home-feed {
        width: 100%;
        text-align: center;
        margin-top: 50px;
    }

    .home-feed-date-time {
        margin-bottom: -10px;
        margin-left: 20px;
    }

    .home-feed-stuff {
        margin-left: 20px;
    }

    .home-event-box {
        border: #FF2F46 solid 2px;
        width: 300px;
        margin: 10px;
        margin-bottom: 20px;
        border-radius: 10px;
    }

    .home-go-explore {
        text-decoration: none;
    }

    .home-go-explore:hover {
        border-bottom: solid #FF2F46 1px;
    }

    .home-no-data {
        text-align: centerzc;
    }
}

@media only screen and (max-width: 600px) {
    .mobile-nav-bar {
        width: 100%;
        background-color: #232323;
        display: block;
        position: fixed;
        bottom: 0;
    }

    .mobile-nav-bar ul {
        list-style-type: none;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-around;
        color: white;
        text-align: center;
        padding: 0px;
    }

    .mobile-nav-bar ul li {
        width: 15%;
        margin: auto;
        text-align: center;
    }

    .mobile-nav-bar ul li:hover {
        cursor: pointer;
        opacity: .7;
    }

    .mobile-nav-bar ul li h6 {
        margin: 0px;
    }

    .home-home-icon,
    .home-profile-icon,
    /* .home-host-icon, */
    .home-explore-icon,
    .home-settings-icon {
        width: 25px;
        height: 20px;
        /* margin: 5px auto; */
    }

    .home-logo-icon {
        height: 25px;
        /* margin: 5px; */
    }

}

.edit-profile-edit-section {
    width: 90%;
    padding: 10px;
    text-align: center;
    margin: auto;
    margin-bottom: 200px;
}

.edit-profile-image {
    width: 100px;
    margin: auto;
    margin-top: 50px;
    display: block;
}

.edit-profile-form-input {
    display: block;
    margin: 5px auto;
    width: 85%;
    height: 30px;
    border: solid 2px #FF2F46;
    border-radius: 5px;
    background-color: white;
    color: black;
    text-align: center;
    font-size: 15px;
}

.edit-profile-form-button {
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
    margin-top:  20px;
}

.edit-profile-form-button {cursor: pointer}

.edit-profile-error-message {
    color: #FF2F46;
    font-family: "Poppins", "cursive";
    margin: 0px auto;
    margin-top: 20px;
}

.edit-profile-form-input-socials-container {
    margin-bottom: 50px;
}

.edit-profile-form-input-social-container {
    display: block;
    margin: 15px auto;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */
@media only screen and (max-width: 600px) {
    .edit-profile-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }
    
    .edit-profile-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .edit-profile-form-input-social-label {
        font-size: 12px;
    }

    .edit-profile-form-input-socials {
        margin-left: 3px;
        border: 1px solid #FF2F46;
        border-radius: 4px;
        width: 120px;
        height: 18px;
        text-align: center;
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .edit-profile-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }
    
    .edit-profile-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .edit-profile-form-input {
        width: 400px;
    }

    .edit-profile-form-button {
        width: 400px;
        margin: 10px auto;
        display: block;
    }

    .edit-profile-form-input-social-label {
        font-size: 15px;
    }

    .edit-profile-form-input-socials {
        margin-left: 5px;
        border: 1px solid #FF2F46;
        border-radius: 4px;
        width: 150px;
        height: 18px;
        text-align: center;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}

div.fileContainer div.uploadPictureContainer {
    width: 500px;
}

div.fileContainer button.chooseFileButton {
    /* background-color: #FF2F46; */
    background-color: #232323;
}

div.fileContainer div.deleteImage {
    background: none;
    background-color: #FF2F46;
}

div.fileContainer {
    box-shadow: none;
}

/* .deleteImage {
    display: none;
} */

.upload-post-error-message {
    color: #FF2F46;
    font-family: "Poppins", "cursive";
    margin: 0px auto;
    margin-top: 20px;
    box-shadow: none;

}


/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */
@media only screen and (max-width: 600px) {
    .upload-post-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }
    
    .upload-post-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .upload-post-form-input {
        width: 85%;
        height: 30px;
        margin: 5px auto;
        text-align: center;
        display: block;
        background-color: white;
        color: black;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
    }

    .upload-post-section {
        width: 90%;
        padding: 10px;
        text-align: center;
        margin: auto;
        margin-bottom: 200px;
    }
    
    .upload-post-form-button {
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 85%;
        font-family: "Poppins", "cursive";
        display: block;
        margin: 0px auto;
        margin-top:  20px;
    }

    .link {
        text-align:justify;
    }
    
    .upload-post-form-button {cursor: pointer}
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .upload-post-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }
    
    .upload-post-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .upload-post-form-input {
        width: 400px;
        height: 30px;
        margin: 5px auto;
        text-align: center;
        display: block;
        background-color: white;
        color: black;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
    }

    .upload-post-form-button {
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 400px;
        font-family: "Poppins", "cursive";
        display: block;
        margin: 10px auto;
        margin-top:  20px;

    }
    
    .upload-post-form-button {cursor: pointer}
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}

.edit-profile-image-edit-section {
    width: 90%;
    padding: 10px;
    text-align: center;
    margin: auto;
    margin-bottom: 200px;
}

.edit-profile-file-name {
    width: 300px;
    margin: 5px auto;
    overflow-wrap: break-word;
}

.edit-profile-image-icon-container {
    margin: 30px auto;
    text-align: center;
    padding: 0px;
}

.edit-profile-image-image{
    display: block;
    padding: 0px;
    width: 175px;
    margin: 30px auto;
    margin-bottom: 0px;
    border-radius: 50%;
}

.edit-profile-image-container-s3 {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 175px;
    height: 175px;
    margin: 50px auto;
    border-radius: 50%;
    overflow:hidden;
    border: .1px solid black;
    background-size: cover;
    background-position: center center;
}

.edit-profile-image-form-button {
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
    margin-top:  20px;
}

.edit-profile-image-form-button {cursor: pointer}

.edit-profile-image-error-message {
    color: #FF2F46;
    font-family: "Poppins", "cursive";
    margin: 0px auto;
    margin-top: 20px;
}

.uploader__container {
    margin: 50px auto;
}

.edit-profile-image-button {
    width: 200px;
    height: 30px;
    background-color: #232323;
    color: white;
    border-radius: 20px;
    border: none;
}

.edit-profile-image-button:hover {
    cursor: pointer;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */
@media only screen and (max-width: 600px) {
    .edit-profile-image-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }
    
    .edit-profile-image-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .edit-profile-image-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }
    
    .edit-profile-image-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .edit-profile-image-form-input {
        width: 400px;
    }

    .edit-profile-image-form-button {
        width: 400px;
        margin: 10px auto;
        display: block;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}

.home-container {
    width: 100%;
    padding: 0px;
    padding-bottom: 500px;
    margin: 0px;
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.b {
    color: black;
}

.no-dec {
    text-decoration: none;
}

.font-1 {
    font-family: "Permanent Marker", "cursive";
}

.font-2 {
    font-family: "Poppins", "cursive";
}

.my-profile-image-container-s3 {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow:hidden;
    background-size: cover;
    background-position: center center;
    margin: auto;
}

.profile-pic-info-items {
    width: 100%;
    margin: 0px;
    display: inline-block;
    vertical-align:middle;
    margin-top: 75px;
}

.profile-pic-item {
    display: inline-block;
    vertical-align:middle;
    width: 100px;
    margin: 20px 0px 0px 20px;
}

.profile-admin-view {
    margin-top: 55px;
    background-color: #FF2F46;
    width: 100%;
    color: white;
    text-align: center;
    font-size: 12px;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {

    .profile-pic-info-items {
        width: 100%;
        margin: 0px;
        display: block;
        margin-top: 75px;
        text-align: center;
    }

    .profile-pic-item {
        display: block;
        width: 150px;
        margin: 0px auto;
    }

    .profile-info-items {
        display: inline-block;
        vertical-align: middle;
        width: 270px;
        margin: 20px;
        margin-bottom: 10px;
        margin-right: 30px;
        font-family: "Poppins", "cursive";
    }

    .profile-name-item {
        margin: 0px;
    }
    

    .profile-detail-item {
        margin: 0px;
    }

    .profile-message-status {
        width: 80%;
        margin: 10px auto;
        text-align: center;
    }

    .profile-follow-info {
        width: 100%;
        text-align: center;
        margin-top: 25px;
    }

    .profile-followers-link, .profile-following-link {
        margin: 20px;
        text-decoration: none;
        font-family: "Poppins", "cursive";
        font-size: 15px;
    }

    .profile-followers-link:hover, .profile-following-link:hover {
        border-bottom: 2px #FF2F46 solid;
    }

    .my-profile-button-container {
        width: 100%;
        margin-top: 25px;
        text-align: center;
    }

    .my-profile-button {
        width: 45%;
        height: 30px;
        margin: 5px;
        border: none;
        color: white;
        background-color: #FF2F46;
        border-radius: 5px;
        margin-bottom: 10px;
        display: inline-block;
        vertical-align: top;
        font-size: 12px;
    }

    .my-profile-button:hover {
        cursor: pointer;
    }

    .profile-tab-container {
        margin: 25px auto;
        width: 100%;   
        text-align: center;     
    }

    .profile-attending-tab, .profile-hosting-tab, .profile-posts-tab {
        display: inline-block;
        margin: 10px 9px;
    }

    .profile-attending-tab, .profile-hosting-tab, .profile-posts-tab:hover {
        cursor: pointer;
    }

    /* .profile-attending-tab {}

    .profile-hosting-tab {}

    .profile-posts-tab {} */
        
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .profile-pic-info-items {
        width: 100%;
        margin: 0px;
        margin-top: 10px;
        vertical-align:middle;
        margin-top: 75px;
    }

    .profile-pic-item {
        display: inline-block;
        vertical-align:middle;
        width: 110px;
        margin: 20px 0px 0px 20px;
    }

    .profile-info-items {
        display: inline-block;
        vertical-align:middle;
        width: 270px;
        margin: 20px;
        font-family: "Poppins", "cursive";

    }

    .profile-name-item {
        margin: 0px;
    }
    

    .profile-detail-item {
        margin: 0px;
    }

    .profile-message-status {
        width: 80%;
        margin: 10px auto;
        text-align: center;
    }

    .profile-follow-info {
        width: 100%;
        text-align: center;
        margin-top: 25px;
    }

    .profile-followers-link, .profile-following-link {
        margin: 20px;
        text-decoration: none;
        font-family: "Poppins", "cursive";
        font-size: 15px;
    }

    .profile-followers-link:hover, .profile-following-link:hover {
        border-bottom: 2px #FF2F46 solid;
    }

    .my-profile-button-container {
        width: 600px;
        margin: 10px auto;;
        text-align: center;
        margin-top: 25px;
    }

    .my-profile-button {
        width: 250px;
        height: 30px;
        margin: 5px;
        border: none;
        color: white;
        background-color: #FF2F46;
        border-radius: 5px;
        display: inline-block;
        vertical-align: top;
    }

    .my-profile-button:hover {
        cursor: pointer;
    }

    .profile-tab-container {
        margin: 25px auto;
        width: 100%;   
        text-align: center;     
    }

    .profile-attending-tab, .profile-hosting-tab, .profile-posts-tab {
        display: inline-block;
        margin: 10px 25px;
    }

    .profile-attending-tab, .profile-hosting-tab, .profile-posts-tab:hover {
        cursor: pointer;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .profile-pic-info-items {
        width: 1000px;
        margin: 0 auto;
        margin-top: 75px;
        display: block;
    }

    .profile-active-tab {
        width: 1000px;
        margin: auto;
    }
}
/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .profile-saved-component-container {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 150px;
        margin: 0px;
        margin-top: -40px;
        text-align: center;
    }

    .profile-posts-component-message-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }

    .profile-saved-statement {
        font-family: "Poppins", "cursive";
        text-align: center;
        width: 90%;
        margin: 10px auto;
    }

    .profile-saved-component-event-box {
        display: inline-block;
        width: 180px;
        padding: 10px;
        border-radius: 10px;
        margin: 10px;
        border: solid 1px black;
        text-align: center;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    .profile-saved-component-event-box p {
        margin: 10px auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .profile-saved-component-date {
        font-family: "Poppins", "cursive";
        opacity: .70;
        color: black;
        font-size: 10px;
    }

    .profile-saved-component-title {
        font-family: "Poppins", "cursive";
        font-size: 15px;
        color: black;
    }

    .profile-saved-component-city {
        font-family: "Permanent Marker", "cursive";
        color:#FF2F46;
        font-size: 15px;
    }

    .profile-saved-component-host {
        font-family: "Poppins", "cursive";
        font-size: 8px;
        color: black;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .profile-saved-component-container {
        width: 90%;
        padding: 20px;
        padding-bottom: 150px;
        margin: auto;
        margin-top: -40px;
        text-align: center;
    }

    .profile-posts-component-message-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }

    .profile-saved-statement {
        font-family: "Poppins", "cursive";
        text-align: center;
        width: 90%;
        margin: 10px auto;
    }

    .profile-saved-component-event-box {
        display: inline-block;
        width: 180px;
        padding: 10px;
        border-radius: 10px;
        margin: 10px;
        border: solid 1px black;
        text-align: center;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    .profile-saved-component-event-box p {
        margin: 10px auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .profile-saved-component-date {
        font-family: "Poppins", "cursive";
        opacity: .70;
        color: black;
        font-size: 10px;
    }

    .profile-saved-component-title {
        font-family: "Poppins", "cursive";
        font-size: 15px;
        color: black;
    }

    .profile-saved-component-city {
        font-family: "Permanent Marker", "cursive";
        color:#FF2F46;
        font-size: 15px;
    }

    .profile-saved-component-host {
        font-family: "Poppins", "cursive";
        font-size: 8px;
        color: black;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}
/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .profile-attending-component-container {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 150px;
        margin: 0px;
        margin-top: -40px;
        text-align: center;
    }

    .profile-posts-component-message-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }

    .profile-attending-statement {
        font-family: "Poppins", "cursive";
        text-align: center;
        width: 90%;
        margin: 10px auto;
    }

    .profile-attending-component-event-box {
        display: inline-block;
        width: 180px;
        padding: 10px;
        border-radius: 10px;
        margin: 10px;
        border: solid 1px black;
        text-align: center;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    .profile-attending-component-event-box p {
        margin: 10px auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .profile-attending-component-date {
        font-family: "Poppins", "cursive";
        opacity: .70;
        color: black;
        font-size: 10px;
    }

    .profile-attending-component-title {
        font-family: "Poppins", "cursive";
        font-size: 15px;
        color: black;
    }

    .profile-attending-component-city {
        font-family: "Permanent Marker", "cursive";
        color:#FF2F46;
        font-size: 15px;
    }

    .profile-attending-component-host {
        font-family: "Poppins", "cursive";
        font-size: 8px;
        color: black;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .profile-attending-component-container {
        width: 90%;
        padding: 20px;
        padding-bottom: 150px;
        margin: auto;
        margin-top: -40px;
        text-align: center;
    }

    .profile-posts-component-message-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }

    .profile-attending-statement {
        font-family: "Poppins", "cursive";
        text-align: center;
        width: 90%;
        margin: 10px auto;
    }

    .profile-attending-component-event-box {
        display: inline-block;
        width: 180px;
        padding: 10px;
        border-radius: 10px;
        margin: 10px;
        border: solid 1px black;
        text-align: center;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    .profile-attending-component-event-box p {
        margin: 10px auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .profile-attending-component-date {
        font-family: "Poppins", "cursive";
        opacity: .70;
        color: black;
        font-size: 10px;
    }

    .profile-attending-component-title {
        font-family: "Poppins", "cursive";
        font-size: 15px;
        color: black;
    }

    .profile-attending-component-city {
        font-family: "Permanent Marker", "cursive";
        color:#FF2F46;
        font-size: 15px;
    }

    .profile-attending-component-host {
        font-family: "Poppins", "cursive";
        font-size: 8px;
        color: black;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}
/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .profile-hosting-component-container {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 150px;
        margin: 0px;
        margin-top: -40px;
        text-align: center;

    }

    .profile-hosting-component-message-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }

    .profile-hosting-statement {
        font-family: "Poppins", "cursive";
        text-align: center;
        width: 90%;
        margin: 10px auto;
    }

    .profile-hosting-component-event-box {
        display: inline-block;
        width: 180px;
        padding: 10px;
        border-radius: 10px;
        margin: 10px;
        border: solid 1px black;
        text-align: center;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    .profile-hosting-component-event-box p {
        margin: 10px auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .profile-hosting-component-date {
        font-family: "Poppins", "cursive";
        opacity: .70;
        color: black;
        font-size: 10px;
    }

    .profile-hosting-component-title {
        font-family: "Poppins", "cursive";
        font-size: 15px;
        color: black;
    }

    .profile-hosting-component-city {
        font-family: "Permanent Marker", "cursive";
        color:#FF2F46;
        font-size: 15px;
    }

    .profile-hosting-component-host {
        font-family: "Poppins", "cursive";
        font-size: 8px;
        color: black;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .profile-hosting-component-container {
        width: 90%;
        padding: 20px;
        padding-bottom: 150px;
        margin: 0px auto;
        margin-top: -40px;
        text-align: center;

    }

    .profile-hosting-component-message-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }

    .profile-hosting-statement {
        font-family: "Poppins", "cursive";
        text-align: center;
        width: 90%;
        margin: 10px auto;
    }

    .profile-hosting-component-event-box {
        display: inline-block;
        width: 180px;
        padding: 10px;
        border-radius: 10px;
        margin: 10px;
        border: solid 1px black;
        text-align: center;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    .profile-hosting-component-event-box p {
        margin: 10px auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .profile-hosting-component-date {
        font-family: "Poppins", "cursive";
        opacity: .70;
        color: black;
        font-size: 10px;
    }

    .profile-hosting-component-title {
        font-family: "Poppins", "cursive";
        font-size: 15px;
        color: black;
    }

    .profile-hosting-component-city {
        font-family: "Permanent Marker", "cursive";
        color:#FF2F46;
        font-size: 15px;
    }

    .profile-hosting-component-host {
        font-family: "Poppins", "cursive";
        font-size: 8px;
        color: black;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}

.profile-posts-container {
    width: 90%;
    padding: 0px;
    margin: 10px auto;
}

  .profile-posts-post:hover {
    cursor: pointer;
  }
  
.profile-posts-confirm {
    background-color: white;
    border: solid 3px #FF2F46;
    border-radius: 10px;
    width: 300px;
    text-align: center;
    position:absolute;
    top: 40%;
    left: 0;
    right: 0;
    margin: auto;
    padding: 20px 10px;
}
 .profile-posts-delete-button {
    height: 25px;
    background-color: #FF2F46;
    color: white;
    border: none;
    border-radius: 5px;
 }

 .profile-posts-delete-button:hover {
    cursor: pointer;
 }

.profile-post-delete-confirm-button {
    height: 25px;
    background-color: #FF2F46;
    color: white;
    border: none;
    border-radius: 5px;
}

.profile-post-delete-confirm-button:hover {
    cursor: pointer;  
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .profile-posts-statement-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }
    
    .profile-posts-statement {
        font-family: "Poppins", "cursive";
        text-align: center;
        margin-bottom: 40px;
    }

    .profile-posts-upload {
        display: block;
        margin: 0px auto;
        margin-top:  20px;
        /* margin-bottom: 40px; */
        padding-top: 5px;
        text-align: center;
        background-color: #FF2F46;
        border-radius: 5px;
        color: white;
        height: 25px;
        width: 200px;
        font-size: 12px;
    }

    .profile-posts-post-container {
        -moz-column-count: 2;
             column-count: 2;
        grid-column-gap: 15px;
        -moz-column-gap: 15px;
             column-gap: 15px;
        padding: 10px;
        margin-top: 40px;
    }

    .profile-posts-post {
        margin: 0;
        display: grid;
        grid-template-rows: 1fr auto;
        page-break-inside: avoid;
        -moz-column-break-inside: avoid;
             break-inside: avoid;
        color: black;
    }

    .profile-posts-post > .profile-posts-image {
        grid-row: 1 / -1;
        grid-column: 1;
    }

    .profile-posts-image {
        max-width: 100%;
        display: block;
        border-radius: 10px;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        margin: 15px auto;
    }

    .profile-posts-info {
        color: black;
        margin: 0px auto;
        margin-bottom: 10px;
        width: 90%;
      }

    /* **************MODAL STUFF BELOW************** */
    
    .popup-content {
        margin: auto;
        background: rgb(255, 255, 255);
        width: 85%;
        padding: 5px;
        text-align: center;
    }

    .profile-posts-post-pop-up {
        /* width: 720px; */
        height: 700px;
        background-color: black;
        padding: 0px;
        overflow:scroll;
        text-align: center;
        align-content: center;
        border-radius: 10px;
        background-color: transparent;
        margin: auto;
    
      }
    
      .profile-posts-image-test {
        width: 85%;
        max-height: 100%;
        border-radius: 10px;
        display: inline-block;
        vertical-align: middle;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
      }
    
    .popup-content {
        padding: 0px;
        border-radius: 10px;
        background-color: transparent;
    }
    
    .modal {
        font-size: 12px;
    }
    
    .modal > .close {
        cursor: pointer;
        position: absolute;
        display: block;
        padding: 2px 5px;
        line-height: 20px;
        right: 15px;
        top: -15px;
        font-size: 24px;
        background: #FF2F46;
        border-radius: 18px;
        border: 2px solid #FF2F46;
        color: white
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .profile-posts-statement-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }
    
    .profile-posts-statement {
        font-family: "Poppins", "cursive";
        text-align: center;
        margin-bottom: 40px;
    }

    .profile-posts-upload {
        display: block;
        margin: 0px auto;
        margin-top:  20px;
        /* margin-bottom: 40px; */
        padding-top: 5px;
        text-align: center;
        background-color: #FF2F46;
        border-radius: 5px;
        color: white;
        height: 25px;
        width: 200px;
        font-size: 12px;
    }

    .profile-posts-post-container {
        -moz-column-count: 3;
             column-count: 3;
        grid-column-gap: 15px;
        -moz-column-gap: 15px;
             column-gap: 15px;
        padding: 10px;
        margin-top: 40px;
        width: 100%;
    }

    .profile-posts-post {
        margin: 0;
        display: grid;
        grid-template-rows: 1fr auto;
        /* margin-bottom: 20px; */
        page-break-inside: avoid;
        -moz-column-break-inside: avoid;
             break-inside: avoid;
        color: black;
    }

    .profile-posts-post > .profile-posts-image {
        grid-row: 1 / -1;
        grid-column: 1;
    }

    .profile-posts-image {
        max-width: 100%;
        display: block;
        border-radius: 10px;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        margin: 20px auto;
    }

    div.fileContainer div.errorsContainer {
        overflow-wrap: break-word;
        max-width: 100%;
    }

    /* **************MODAL STUFF BELOW************** */

    .popup-content {
        margin: auto;
        background: rgb(255, 255, 255);
        max-width: 40%;
    }

    .profile-posts-post-pop-up {
        /* width: 720px; */
        height: 700px;
        background-color: black;
        padding: 0px;
        overflow:scroll;
        text-align: center;
        align-content: center;
        border-radius: 10px;
        background-color: transparent;
        margin: auto;
    
      }
    
      .profile-posts-image-test {
        max-width: 100%;
        max-height: 100%;
        border-radius: 10px;
        display: inline-block;
        vertical-align: middle;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
      }
    
    .popup-content {
        padding: 0px;
        border-radius: 10px;
        background-color: transparent;
    }
    
    .modal {
        font-size: 12px;
    }
    
    .modal > .close {
        cursor: pointer;
        position: absolute;
        display: block;
        padding: 2px 5px;
        line-height: 20px;
        right: -10px;
        top: -10px;
        font-size: 24px;
        background: #FF2F46;
        border-radius: 18px;
        border: 2px solid #FF2F46;
        color: white
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}
/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .profile-feed-component-container {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 150px;
        margin: 0px;
        margin-top: -50px;
        text-align: center;
    }

    .profile-posts-component-message-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }

    .profile-feed-statement {
        font-family: "Poppins", "cursive";
        text-align: center;
        width: 90%;
        margin: 10px auto;
    }

    .profile-feed-component-event-box {
        display: inline-block;
        width: 180px;
        padding: 10px;
        border-radius: 10px;
        margin: 10px;
        border: solid 1px black;
        text-align: center;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    .profile-feed-component-event-box p {
        margin: 10px auto;
    }

    .profile-feed-component-date {
        font-family: "Poppins", "cursive";
        opacity: .70;
        color: black;
        font-size: 10px;
    }

    .profile-feed-component-title {
        font-family: "Poppins", "cursive";
        font-size: 15px;
        color: black;
    }

    .profile-feed-component-city {
        font-family: "Permanent Marker", "cursive";
        color:#FF2F46;
        font-size: 15px;
    }

    .profile-feed-component-host {
        font-family: "Poppins", "cursive";
        font-size: 8px;
        color: black;
    }

    .profile-feed {
        width: 90%;
        margin: 0px auto;
        margin-top: 30px;
    }

    .profile-feed-date-time {
        margin-bottom: -10px;
        /* margin-left: 20px; */
    }

    .profile-feed-stuff {
        /* margin-left: 20px; */
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .profile-feed-component-container {
        width: 90%;
        padding: 20px;
        padding-bottom: 150px;
        margin: auto;
        margin-top: -40px;
        text-align: center;
    }

    .profile-posts-component-message-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }

    .profile-feed-statement {
        font-family: "Poppins", "cursive";
        text-align: center;
        width: 90%;
        margin: 10px auto;
    }

    .profile-feed-component-event-box {
        display: inline-block;
        width: 180px;
        padding: 10px;
        border-radius: 10px;
        margin: 10px;
        border: solid 1px black;
        text-align: center;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    .profile-feed-component-event-box p {
        margin: 10px auto;
    }

    .profile-feed-component-date {
        font-family: "Poppins", "cursive";
        opacity: .70;
        color: black;
        font-size: 10px;
    }

    .profile-feed-component-title {
        font-family: "Poppins", "cursive";
        font-size: 15px;
        color: black;
    }

    .profile-feed-component-city {
        font-family: "Permanent Marker", "cursive";
        color:#FF2F46;
        font-size: 15px;
    }

    .profile-feed-component-host {
        font-family: "Poppins", "cursive";
        font-size: 8px;
        color: black;
    }

    .profile-feed {
        width: 90%;
        margin: 0px auto;
        margin-top: 30px;
    }

    .profile-feed-date-time {
        margin-bottom: -10px;
        /* margin-left: 20px; */
    }

    .profile-feed-stuff {
        /* margin-left: 20px; */
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}
.home-container {
    width: 100%;
    padding: 0px;
    padding-bottom: 500px;
    margin: 0px;
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.b {
    color: black;
}

.no-dec {
    text-decoration: none;
}

.font-1 {
    font-family: "Permanent Marker", "cursive";
}

.font-2 {
    font-family: "Poppins", "cursive";
}

.rider-info-button-container form {
    display: inline-block;
    margin-top: 10px;
}

.rider-info-button {
    width: 75px;
    margin-right: 10px;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    padding: 3px;
    display: inline-block;
}

.rider-info-button:hover {
    cursor: pointer;
    background-color: #FF2F46;
    color: white;
    border: 1px solid #FF2F46;
}

.rider-info-button-unfollow {
    width: 75px;
    margin-right: 10px;
    background-color: #FF2F46;
    border: 1px solid #FF2F46;
    color: white;
    border-radius: 5px;
    padding: 3px;
    display: inline-block;
}

.rider-info-button-unfollow:hover {
    cursor: pointer;
}

.rider-message-status {
    width: 80%;
    margin: 10px auto;
    text-align: center;
}

.home-message-link-guest {
    font-size: 13px;
}

.home-message-link-guest:hover {
    cursor: pointer;
    border-bottom: #FF2F46 solid 2px;
}

.rider-image-contianer-s3 {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow:hidden;
    background-size: cover;
    background-position: center center;
    margin: auto;
}

.link:hover {
    border-bottom: #FF2F46 solid 2px;
}

.rider-tip-button-container {
    width: 100%;
    text-align: center;
}

.rider-tip-button {
    background-color: #FF2F46;
    border: #FF2F46 1px solid;
    border-radius: 20px;
    padding: 8px;
    margin: 5px;
    text-align: center;
    margin-bottom: -20px;
    color: white;
    width: 50px;
}

.rider-tip-button-custom {
    background-color: #FF2F46;
    border: #FF2F46 1px solid;
    border-radius: 20px;
    padding: 8px;
    margin: 5px;
    text-align: center;
    margin-bottom: -20px;
    color: white;
}

.rider-tip-button-name {
    font-family: "Permanent Marker", "cursive";
}

.rider-tip-button:hover {
    cursor: pointer;
    background-color: white;
    color: #FF2F46;
    border: #FF2F46 1px solid;
}

.rider-tip-button-custom:hover {
    cursor: pointer;
    background-color: white;
    color: #FF2F46;
    border: #FF2F46 1px solid;
}
/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .extra-margin {
        margin-bottom: 10px;
    }

    .rider-pic-info-items {
        width: 100%;
        margin: 0px;
        display: block;
        margin-top: 75px;
        text-align: center;
    }

    .rider-pic-item {
        display: block;
        width: 150px;
        margin: 0px auto;
    }

    .rider-info-items {
        display: inline-block;
        vertical-align: middle;
        width: 270px;
        margin: 20px;
        margin-bottom: 10px;
        margin-right: 30px;
        font-family: "Poppins", "cursive";
    }

    .rider-name-item {
        margin: 0px;
    }
    

    .rider-detail-item {
        margin: 0px;
    }

    .rider-message-status {
        width: 80%;
        margin: 10px auto;
    }

    .rider-follow-info {
        width: 100%;
        text-align: center;
        margin-top: 25px;
    }

    .rider-followers-link, .rider-following-link {
        margin: 20px;
        text-decoration: none;
        font-family: "Poppins", "cursive";
        font-size: 15px;
    }

    .rider-followers-link:hover, .rider-following-link:hover {
        border-bottom: 2px red solid;
    }

    .rider-edit-button-container {
        width: 100%;
        text-align: center;
        margin-top: 25px;
    }

    .rider-edit-button {
        width: 50%;
        height: 30px;
        border: none;
        color: white;
        background-color: #FF2F46;
        border-radius: 5px;
    }

    .rider-edit-button:hover {
        cursor: pointer;
    }

    .rider-tab-container {
        margin: 25px auto;
        width: 100%;   
        text-align: center;     
    }

    .rider-attending-tab, .rider-hosting-tab, .rider-posts-tab {
        display: inline-block;
        margin: 10px 20px;
    }

    .rider-attending-tab, .rider-hosting-tab, .rider-posts-tab:hover {
        cursor: pointer;
    }

    /* .rider-attending-tab {}

    .rider-hosting-tab {}

    .rider-posts-tab {} */
        
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .rider-pic-info-items {
        width: 100%;
        margin: 0px;
        margin-top: 10px;
        vertical-align:middle;
        margin-top: 75px;
    }

    .rider-pic-item {
        display: inline-block;
        vertical-align:middle;
        width: 110px;
        margin: 20px 0px 0px 20px;
    }

    .rider-info-items {
        display: inline-block;
        vertical-align:middle;
        width: 270px;
        margin: 20px;
        font-family: "Poppins", "cursive";

    }

    .rider-name-item {
        margin: 0px;
    }
    
    .rider-detail-item {
        margin: 0px;
    }

    .rider-message-status {
        width: 80%;
        margin: 10px auto;
    }

    .rider-follow-info {
        width: 100%;
        text-align: center;
        margin-top: 25px;
    }

    .rider-followers-link, .rider-following-link {
        margin: 20px;
        text-decoration: none;
        font-family: "Poppins", "cursive";
        font-size: 18px;
    }

    .rider-followers-link:hover, .rider-following-link:hover {
        border-bottom: 2px red solid;
    }

    .rider-edit-button-container {
        width: 100%;
        text-align: center;
        margin-top: 25px;
    }

    .rider-edit-button {
        width: 50%;
        height: 30px;
        border: none;
        color: white;
        background-color: #FF2F46;
        border-radius: 5px;
    }

    .rider-edit-button:hover {
        cursor: pointer;
    }

    .rider-tab-container {
        margin: 25px auto;
        width: 100%;   
        text-align: center;     
    }

    .rider-attending-tab, .rider-hosting-tab, .rider-posts-tab {
        display: inline-block;
        margin: 10px 25px;
    }

    .rider-attending-tab, .rider-hosting-tab, .rider-posts-tab:hover {
        cursor: pointer;
    }

}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .rider-pic-info-items {
        width: 930px;
        margin: 0px auto;
        margin-top: 10px;
        vertical-align: middle;
        margin-top: 75px;
    }
}
.rider-attending-signin-button {
    width: 260px;
    height: 30px;
    border: none;
    color: white;
    background-color: #FF2F46;
    border-radius: 5px;
    margin: 20px;
}

.rider-hosting-signin-button:hover {
    cursor: pointer;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .rider-attending-component-container {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 150px;
        margin: 0px;
        margin-top: -40px;
        text-align: center;
    }

    .rider-attending-component-message-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }

    .rider-attending-statement {
        font-family: "Poppins", "cursive";
        text-align: center;
        width: 90%;
        margin: 10px auto;
    }

    .rider-attending-component-event-box {
        display: inline-block;
        width: 180px;
        padding: 10px;
        border-radius: 10px;
        margin: 10px;
        border: solid 1px black;
        text-align: center;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    .rider-attending-component-event-box p {
        margin: 10px auto;
    }

    .rider-attending-component-date {
        font-family: "Poppins", "cursive";
        opacity: .70;
        color: black;
        font-size: 10px;
    }

    .rider-attending-component-title {
        font-family: "Poppins", "cursive";
        font-size: 15px;
        color: black;
    }

    .rider-attending-component-city {
        font-family: "Permanent Marker", "cursive";
        color:#FF2F46;
        font-size: 15px;
    }

    .rider-attending-component-host {
        font-family: "Poppins", "cursive";
        font-size: 8px;
        color: black;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .rider-attending-component-container {
        width: 90%;
        padding: 20px;
        padding-bottom: 150px;
        margin: auto;
        margin-top: -40px;
        text-align: center;
    }

    .rider-posts-component-message-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }

    .rider-attending-statement {
        font-family: "Poppins", "cursive";
        text-align: center;
        width: 90%;
        margin: 10px auto;
    }

    .rider-attending-component-event-box {
        display: inline-block;
        width: 180px;
        padding: 10px;
        border-radius: 10px;
        margin: 10px;
        border: solid 1px black;
        text-align: center;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    .rider-attending-component-event-box p {
        margin: 10px auto;
    }

    .rider-attending-component-date {
        font-family: "Poppins", "cursive";
        opacity: .70;
        color: black;
        font-size: 10px;
    }

    .rider-attending-component-title {
        font-family: "Poppins", "cursive";
        font-size: 15px;
        color: black;
    }

    .rider-attending-component-city {
        font-family: "Permanent Marker", "cursive";
        color:#FF2F46;
        font-size: 15px;
    }

    .rider-attending-component-host {
        font-family: "Poppins", "cursive";
        font-size: 8px;
        color: black;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}
.rider-hosting-signin-button {
    width: 260px;
    height: 30px;
    border: none;
    color: white;
    background-color: #FF2F46;
    border-radius: 5px;
    margin: 20px;
}

.rider-hosting-signin-button {
    cursor: pointer;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .rider-hosting-component-container {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 150px;
        margin: 0px;
        margin-top: -40px;
        text-align: center;

    }

    .rider-hosting-component-message-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }

    .rider-hosting-statement {
        font-family: "Poppins", "cursive";
        text-align: center;
        width: 90%;
        margin: 10px auto;
    }

    .rider-hosting-component-event-box {
        display: inline-block;
        width: 180px;
        padding: 10px;
        border-radius: 10px;
        margin: 10px;
        border: solid 1px black;
        text-align: center;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    .rider-hosting-component-event-box p {
        margin: 10px auto;
    }

    .rider-hosting-component-date {
        font-family: "Poppins", "cursive";
        opacity: .70;
        color: black;
        font-size: 10px;
    }

    .rider-hosting-component-title {
        font-family: "Poppins", "cursive";
        font-size: 15px;
        color: black;
    }

    .rider-hosting-component-city {
        font-family: "Permanent Marker", "cursive";
        color:#FF2F46;
        font-size: 15px;
    }

    .rider-hosting-component-host {
        font-family: "Poppins", "cursive";
        font-size: 8px;
        color: black;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .rider-hosting-component-container {
        width: 90%;
        padding: 20px;
        padding-bottom: 150px;
        margin: 0px auto;
        margin-top: -40px;
        text-align: center;

    }

    .rider-hosting-component-message-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }

    .rider-hosting-statement {
        font-family: "Poppins", "cursive";
        text-align: center;
        width: 90%;
        margin: 10px auto;
    }

    .rider-hosting-component-event-box {
        display: inline-block;
        width: 180px;
        padding: 10px;
        border-radius: 10px;
        margin: 10px;
        border: solid 1px black;
        text-align: center;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    .rider-hosting-component-event-box p {
        margin: 10px auto;
    }

    .rider-hosting-component-date {
        font-family: "Poppins", "cursive";
        opacity: .70;
        color: black;
        font-size: 10px;
    }

    .rider-hosting-component-title {
        font-family: "Poppins", "cursive";
        font-size: 15px;
        color: black;
    }

    .rider-hosting-component-city {
        font-family: "Permanent Marker", "cursive";
        color:#FF2F46;
        font-size: 15px;
    }

    .rider-hosting-component-host {
        font-family: "Poppins", "cursive";
        font-size: 8px;
        color: black;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}

.rider-posts-container {
    width: 90%;
    padding: 0px;
    margin: 10px auto;
}

.rider-posts-post:hover {
cursor: pointer;
}
  
.rider-posts-signin-button {
    width: 260px;
    height: 30px;
    border: none;
    color: white;
    background-color: #FF2F46;
    border-radius: 5px;
    margin: 20px;
}

.rider-posts-signin-button:hover {
    cursor: pointer;
}

.margin-up {
    margin-top: 200px;
}

.rider-detail-socials-container {
    margin: 20px auto;
}

.rider-detail-socials-icon {
    width: 15px;
    margin-right: 10px;
    margin-top: 5px;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .rider-posts-statement-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }

    .rider-posts-component-message-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }
    
    .rider-posts-statement {
        font-family: "Poppins", "cursive";
        text-align: center;
        width: 100%;
        margin: 10px auto;
    }

    .rider-posts-upload {
        display: block;
        margin: 0px auto;
        margin-top:  20px;
        /* margin-bottom: 40px; */
        padding-top: 5px;
        text-align: center;
        background-color: #FF2F46;
        border-radius: 5px;
        color: white;
        height: 25px;
        width: 200px;
        font-size: 12px;
    }

    .rider-posts-post-container {
        -moz-column-count: 2;
             column-count: 2;
        grid-column-gap: 15px;
        -moz-column-gap: 15px;
             column-gap: 15px;
        padding: 10px;
        padding-bottom: 250px;
        margin-top: 40px;
    }

    .rider-posts-post {
        margin: 0;
        display: grid;
        grid-template-rows: 1fr auto;
        page-break-inside: avoid;
        -moz-column-break-inside: avoid;
             break-inside: avoid;
        color: black;
    }

    .rider-posts-post > .rider-posts-image {
        grid-row: 1 / -1;
        grid-column: 1;
    }

    .rider-posts-image {
        max-width: 100%;
        display: block;
        border-radius: 10px;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        margin: 15px auto;
    }

    .rider-posts-info {
        color: black;
        margin: 0px auto;
        margin-bottom: 10px;
        width: 90%;
      }

    .rider-posts-signin-redirect {
        text-align: center;
        margin: 10px auto;
        margin-top: -200px;
    }

    /* **************MODAL STUFF BELOW************** */
    
    .popup-content {
        margin: auto;
        background: rgb(255, 255, 255);
        width: 85%;
        padding: 5px;
        text-align: center;
    }

    .rider-posts-post-pop-up {
        /* width: 720px; */
        height: 700px;
        background-color: black;
        padding: 0px;
        overflow:scroll;
        text-align: center;
        align-content: center;
        border-radius: 10px;
        background-color: transparent;
        margin: auto;
    
      }
    
      .rider-posts-image-test {
        width: 85%;
        max-height: 100%;
        border-radius: 10px;
        display: inline-block;
        vertical-align: middle;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
      }
    
    .popup-content {
        padding: 0px;
        border-radius: 10px;
        background-color: transparent;
    }
    
    .modal {
        font-size: 12px;
    }
    
    .modal > .close {
        cursor: pointer;
        position: absolute;
        display: block;
        padding: 2px 5px;
        line-height: 20px;
        right: 15px;
        top: -15px;
        font-size: 24px;
        background: #FF2F46;
        border-radius: 18px;
        border: 2px solid #FF2F46;
        color: white
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .rider-posts-statement-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }
    
    .rider-posts-statement {
        font-family: "Poppins", "cursive";
        text-align: center;
    }

    .rider-posts-upload {
        display: block;
        margin: 0px auto;
        margin-top:  20px;
        /* margin-bottom: 40px; */
        padding-top: 5px;
        text-align: center;
        background-color: #FF2F46;
        border-radius: 5px;
        color: white;
        height: 25px;
        width: 200px;
        font-size: 12px;
    }

    .rider-posts-post-container {
        -moz-column-count: 3;
             column-count: 3;
        grid-column-gap: 15px;
        -moz-column-gap: 15px;
             column-gap: 15px;
        padding: 10px;
        margin-top: 10px;
        width: 100%
    }

    .rider-posts-post {
        margin: 0;
        display: grid;
        grid-template-rows: 1fr auto;
        /* margin-bottom: 20px; */
        page-break-inside: avoid;
        -moz-column-break-inside: avoid;
             break-inside: avoid;
        color: black;
    }

    .rider-posts-post > .rider-posts-image {
        grid-row: 1 / -1;
        grid-column: 1;
    }

    .rider-posts-image {
        max-width: 100%;
        display: block;
        border-radius: 10px;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        margin: 20px auto;
    }

    div.fileContainer div.errorsContainer {
        overflow-wrap: break-word;
        max-width: 100%;
    }

    .rider-posts-signin-redirect {
        text-align: center;
        margin: 50px auto;
        margin-bottom: 100px;
    }

    /* **************MODAL STUFF BELOW************** */

    .popup-content {
        margin: auto;
        background: rgb(255, 255, 255);
        max-width: 40%;
    }

    .rider-posts-post-pop-up {
        /* width: 720px; */
        height: 700px;
        background-color: black;
        padding: 0px;
        overflow:scroll;
        text-align: center;
        align-content: center;
        border-radius: 10px;
        background-color: transparent;
        margin: auto;
    
      }
    
      .rider-posts-image-test {
        max-width: 100%;
        max-height: 100%;
        border-radius: 10px;
        display: inline-block;
        vertical-align: middle;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
      }
    
    .popup-content {
        padding: 0px;
        border-radius: 10px;
        background-color: transparent;
    }
    
    .modal {
        font-size: 12px;
    }
    
    .modal > .close {
        cursor: pointer;
        position: absolute;
        display: block;
        padding: 2px 5px;
        line-height: 20px;
        right: -10px;
        top: -10px;
        font-size: 24px;
        background: #FF2F46;
        border-radius: 18px;
        border: 2px solid #FF2F46;
        color: white
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}
.explore-container {
    width: 100%;
    padding: 0px;
    /* padding-top: 25px; */
    padding-bottom: 500px;
    margin: 0px;
    /* background-color: #232323; */
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.b {
    color: black;
}

.no-dec {
    text-decoration: none;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {

    .explore-title-container {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        margin-top: 75px;
    }

    .explore-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .explore-form {
        width: 100%;
        text-align: center;
    }

    .explore-form-input {
        display: block;
        margin: 20px auto;
        width: 85%;
        height: 30px;
        border: solid 2px #FF2F46;
        border-radius: 5px;
        background-color: white;
        color: black;
        text-align: center;
        font-size: 15px;
        font-family: "Poppins", "cursive";
    }

    .explore-form-button {
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 85%;
        font-family: "Poppins", "cursive";
    }

    .search-event-zero-found {
        width: 100%;
        text-align: center;
        margin-top: 100px;
    }

    .explore-event-view {
        width: 90%;
        margin: 10px 0px;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 601px) {

    .explore-title-container {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        margin-top: 75px;
    }

    .explore-title {
        font-family: "Permanent Marker", "cursive";
        color: red;
        margin-bottom: 0px;
    }

    .explore-search-items {
        margin: 50px auto;
    }

    .explore-form {
        width: 100%;
        text-align: center;
    }

    .explore-form-input {
        display: block;
        margin: 20px auto;
        width: 400px;
        height: 30px;
        border: solid 2px #FF2F46;
        border-radius: 5px;
        background-color: white;
        color: black;
        text-align: center;
        font-size: 15px;
        font-family: "Poppins", "cursive";
    }

    .explore-form-button {
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 400px;
        font-family: "Poppins", "cursive";
    }

    .search-event-zero-found {
        width: 100%;
        text-align: center;
        margin-top: 100px;
    }

    .explore-event-view {
        width: 280px;
        margin: 8px;
    }

}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}

.create-event-container {
    width: 100%;
    padding: 0px;
    /* padding-top: 25px; */
    padding-bottom: 200px;
    margin: 0px;
    /* background-color: #232323; */
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.b {
    color: black;
}

.no-dec {
    text-decoration: none;
}

.create-event-privacy-select {
    width: 90px;
    padding: 5px;
    border-radius: 5px;
    font-size: 15px;
    margin: 15px auto;
}

.create-event-privacy-select:hover {
    cursor: pointer;
}

.create-event-select {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    width: 100px;
    padding: 5px;
    border-radius: 5px;
    font-size: 15px;
    margin: 15px auto;
    text-align: center;
}

.create-event-datepicker-container {
    width: 400px;
    margin: auto;
}

.create-event-form-error-message {
    color: #FF2F46;
    font-family: "Poppins", "cursive";
    margin: 0px auto;
}

.react-datepicker__input-container {
    margin: 0px auto;
    margin-left: -7px;
}

.create-event-form-meet-location-hint {
    font-family: "Poppins", "cursive";
    margin: 0px auto;
    font-size: 10px;
}

.create-event-form-input-external-link {
    width: 100%;
    height: 30px;
    margin: 5px auto;
    text-align: center;
    display: block;
    background-color: white;
    color: black;
    border: solid 1px #FF2F46;
    border-radius: 5px;
    font-size: 15px;
    font-family: "Poppins", "cursive";
}


/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {

    .create-event-title-container {
        width: 100%;
        text-align: center;
        margin-bottom: 25px;
        margin-top: 75px;
    }

    .create-event-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .create-event-form {
        width: 100%;
        text-align: center;
    }

    .create-event-form-input {
        width: 404px;
        height: 30px;
        margin: 5px auto;
        text-align: center;
        display: block;
        background-color: white;
        color: black;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
        white-space: pre-wrap;
    }

    .text-box {
        width: 400px;
        height: 100px;
        resize: none;
        white-space: pre-wrap;
    }

    .create-event-form-input-loc-container {
        overflow:hidden;
        margin: auto;
    }

    .create-event-form-button {
        background-color: #FF2F46;
        color: white;
        width: 400px;
        height: 35px;
        margin: 5px auto;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
        cursor: pointer;
    }

    .create-event-options-switch-title {
        width: 100%;
        margin: 0px auto;
        margin-top: 50px;
        text-align: center;
    }

    .create-event-switches {
        display: flex;
        justify-content: space-evenly;
        width: 400px;
        margin: 0px auto;

    }

    .create-event-switch {
        width: 150px;
    }

    .create-event-user-invitees {
        margin: 0px auto;
    }

    .create-event-user-invitees p {
        display: inline-block;
        width: 30%;
        margin: 5px;
        margin-top: 20px;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .create-event-title-container {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        margin-top: 75px;
    }

    .create-event-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .create-event-form {
        width: 100%;
        text-align: center;
    }

    .create-event-form-input {
        width: 400px;
        height: 30px;
        margin: 5px auto;
        text-align: center;
        display: block;
        background-color: white;
        color: black;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
        white-space: pre-wrap;
    }

    .text-box {
        height: 100px;
        resize: none;
        white-space: pre-wrap;
    }

    .create-event-form-button {
        background-color: #FF2F46;
        color: white;
        width: 400px;
        height: 35px;
        margin: 5px auto;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
        cursor: pointer;
    }

    .create-event-options-switch-title {
        width: 100%;
        margin: 0px auto;
        margin-top: 50px;
        text-align: center;
    }

    .create-event-switches {
        display: flex;
        justify-content: space-evenly;
        width: 400px;
        margin: 0px auto;

    }

    .create-event-switch {
        width: 150px;
    }

    .create-event-user-invitees-container {
        width: 650px;
        margin: auto;
    }

    .create-event-user-invitees {
        margin: 0px auto;
    }

    .create-event-user-invitees p {
        display: inline-block;
        width: 30%;
        margin: 10px auto;
        margin-top: 20px;
    }
    
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}

/* // this.handlePlaceSelect = this.handlePlaceSelect.bind(this)
// this.handleChange = this.handleChange.bind(this)
// this.handleSubmit = this.handleSubmit.bind(this)

/* handleChange(event) {
    this.setState({[event.target.name]: event.target.value})
}

handleSubmit(event) {
event.preventDefault()
this.props.dispatch(addParlor(this.state))
this.clearForm()
}

handlePlaceSelect() {
    let addressObject = this.autocomplete.getPlace()
    let address = addressObject.address_components
    this.setState({
        name: addressObject.name,
        street_address: `${address[0].long_name} ${address[1].long_name}`,
        city: address[4].long_name,
        state: address[6].short_name,
        zip_code: address[8].short_name,
        googleMapLink: addressObject.url
    })
} */


/* <div>

    <form onSubmit={this.handleSubmit}>

        <input id="autocomplete"
            className="input-field"
            ref="input"
            type="text"
        />

        <input 
            name={"name"}
            value={this.state.name}
            placeholder={"Name"}
            onChange={this.handleChange}
        />

        <input 
            name={"street_address"}
            value={this.state.street_address}
            placeholder={"Street Address"}
            onChange={this.handleChange}
        />

        <input 
            name={"city"}
            value={this.state.city}
            placeholder={"City"}
            onChange={this.handleChange}
        />

        <input
            name={"state"}
            value={this.state.state}
            placeholder={"State"}
            onChange={this.handleChange}
        />
        
        <input 
            name={"zip_code"}
            value={this.state.zip_code}
            placeholder={"Zipcode"}
            onChange={this.handleChange}
        />
        
        <button onSubmit={this.handleSubmit}>Submit</button>

    </form>
</div> */

/* 

[
    {
        long_name: "1714", 
        short_name: "1714", 
        types: [
            "street_number"
        ]
    }, 
    
    {
        long_name: "Nandina Way", 
        short_name: "Nandina Way", 
        types: [
            "route"
        ]
    }, 
    
    {
        long_name: "Antioch", 
        short_name: "Antioch", 
        types: [
            "locality", 
            "political"
        ]
    },
    
    {
        long_name: "Contra Costa County", 
        short_name: "Contra Costa County", 
        types: [
            "administrative_area_level_2", 
            "political"
        ]
    }, 
    
    {
        long_name: "California", 
        short_name: "CA", 
        types: [
            "administrative_area_level_1", 
            "political"
        ]
    }, 
    
    {
        long_name: "United States", 
        short_name: "US", 
        types: [
            "country", 
            "political"
        ]
    },
    
    {
        long_name: "94531", 
        short_name: "94531", 
        types: [
            "postal_code"
        ]
    }, 
    
    {
        long_name: "7409", 
        short_name: "7409", 
        types: [
            "postal_code_suffix"
        ]
    }
]


*/


.event-details-container {
    width: 100%;
    padding: 0px;
    padding-bottom: 500px;
    margin: 0px;
}

.links {
    text-decoration: none;
}

.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.b {
    color: black;
}

.no-dec {
    text-decoration: none;
}

.eventdetails-map {
    width: 400px;
    height: 200px;
}

.map {
    width: 100px;
    text-align: center;
    display: block;
}

.event-details-user-going {
    color: black;
    margin: 10px;
}

.event-details-user-going:hover {
    color: #FF2F46
}

.eventdetails-coming-soon-title {
    color: #FF2F46;
}

.eventdetails-coming-soon-title:hover {
    cursor: default;
}

.eventdetails-riders-going-title {
    margin-bottom: -5px;
}

.eventdetails-riders-going-title:hover {
    cursor: default;
}

.event-details-external-link:hover {
    cursor: pointer;
    border-bottom: solid #FF2F46 2px;
}


/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .map-container {
        width: 99%; /* 99 due to border*/
        height: 300px;
        margin: 0px auto;
        border: 1px #FF2F46 solid;
    } 
    
    .event-details-title-container {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        margin-top: 75px;
    }

    .event-details-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .eventdetails-owner-buttons {
        text-align: center;
    }

    .eventdetails-form-button {
        background-color: #FF2F46;
        color: white;
        width: 95%;
        height: 35px;
        margin: 5px auto;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
        cursor: pointer;
    }

    .eventdetails-details-container {
        text-align: center;
        margin: 50px 0px;
        width: 100%;
    }

    /* .eventdetails-map {
        width: 200px;
        height: 100px;
        border: solid #FF2F46 1px;
        border-radius: 5px;
        margin: 10px auto;
    } */

    .event-details-users-going-container {
        width: 100%;
        padding: 0px;
    }

    .chat-container {
        width: 90%;
        margin: 0px auto;
        margin-top: 50px;
        text-align:  center;
    }

    .messages-box {
        width: 100%;
        height: 300px;
        background-color: white;
        margin: 0px auto;
        border-radius: 5px;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        /* overflow-y: auto; */
    }

    .typing-container {
        width: 100%;
    }

    .message-form-input {
        width: 80%;
        height: 30px;
        margin: 30px auto;
        margin-bottom: 5px;
        text-align: center;
        display: inline-block;
        background-color: white;
        color: black;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        font-size: 15px;
    }

    .eventdetails-post-button {
        background-color: #FF2F46;
        color: white;
        width: 80%;
        height: 35px;
        margin: 5px auto;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
        cursor: pointer;
    }

    .message-send {
        height: 13px;
        margin-left: 10px;
    }

    .description-container {
        white-space: pre-line;
    }

    .event-details-gif-container {
        width: 100%;
    }

    .event-details-gif {
        width: 100%;
        margin: 0px;
        padding: 0px;
        border: none;
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .map-container {
        width: 80%;
        height: 300px;
        margin: 0px auto;
        border: 1px #FF2F46 solid;
        border-radius: 10px;
    } 

    .event-details-title-container {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        margin-top: 75px;
    }

    .event-details-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .eventdetails-owner-buttons {
        text-align: center;
    }

    .eventdetails-form-button {
        background-color: #FF2F46;
        color: white;
        width: 400px;
        height: 35px;
        margin: 10px;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
        cursor: pointer;
    }

    .eventdetails-details-container {
        text-align: center;
        margin: 50px auto;
        width: 95%;
    }

    .eventdetails-details-title {
        font-size: 30px;
    }

    .description-container {
        width: 40%;
        font-family: "Poppins", "cursive";
        padding-top: 50px;
        white-space: pre-line;
    }

    .chat-container {
        font-family: "Poppins", "cursive";
        padding-top: 50px;
        width: 40%;
        margin: 0px auto;
        text-align:  center;
    }

    .description-container, .chat-container {
        display: inline-block;
        vertical-align: top;
        margin: 0px 25px;
    }

    .messages-box {
        width: 100%;
        height: 300px;
        background-color: white;
        border-radius: 5px;
        font-family: "Poppins", "cursive";
        margin-bottom: -20px;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        overflow-y: auto;
    }

    .typing-container {
        width: 100%;
        margin-top: 20px;
    }

    .message-form-input {
        width: 80%;
        height: 30px;
        margin: 30px auto;
        margin-bottom: 5px;
        text-align: center;
        display: inline-block;
        background-color: white;
        color: black;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
    }

    .eventdetails-post-button {
        background-color: #FF2F46;
        color: white;
        width: 80%;
        height: 35px;
        margin: 5px auto;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
        cursor: pointer;
    }

    .message-send {
        height: 13px;
        margin-left: 10px;
    }
    
    .event-details-gif-container {
        width: 100%;
    }

    .event-details-gif {
        width: 100%;
        margin: 0px;
        padding: 0px;
        border: none;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {

    .eventdetails-details-container {
        width: 1000px;
    }
}


.edit-event-container {
    width: 100%;
    padding: 0px;
    /* padding-top: 25px; */
    padding-bottom: 200px;
    margin: 0px;
    /* background-color: #232323; */
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.b {
    color: black;
}

.no-dec {
    text-decoration: none;
}

.edit-event-privacy-select {
    width: 90px;
    padding: 5px;
    border-radius: 5px;
    font-size: 15px;
    margin: 15px auto;
}

.edit-event-privacy-select:hover {
    cursor: pointer;
}

.edit-event-select {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    width: 100px;
    padding: 5px;
    border-radius: 5px;
    font-size: 15px;
    margin: 15px auto;
    text-align: center;
}

.edit-event-datepicker-container {
    width: 400px;
    margin: auto;
}

.edit-event-form-error-message {
    color: #FF2F46;
    font-family: "Poppins", "cursive";
    margin: 0px auto;
}

.back {
    margin-top: 25px;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {

    .edit-event-title-container {
        width: 100%;
        text-align: center;
        margin-bottom: 25px;
        margin-top: 75px;
    }

    .edit-event-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .edit-event-form {
        width: 100%;
        text-align: center;
    }

    .edit-event-form-input {
        width: 400px;
        height: 30px;
        margin: 5px auto;
        text-align: center;
        display: block;
        background-color: white;
        color: black;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
    }

    .edit-event-form-input::-webkit-input-placeholder { /* WebKit browsers */
        color:    black;
         opacity: 1 !important;
    }

    .text-box {
        heightk: 100px;
        resize: none;
    }

    .edit-event-form-input-loc-container {
        overflow:hidden;
        margin: auto;
    }

    .edit-event-form-button {
        background-color: #FF2F46;
        color: white;
        width: 400px;
        height: 35px;
        margin: 5px auto;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
        cursor: pointer;
    }

    .edit-event-options-switch-title {
        width: 100%;
        margin: 0px auto;
        margin-top: 50px;
        text-align: center;
    }

    .edit-event-switches {
        display: flex;
        justify-content: space-evenly;
        width: 400px;
        margin: 0px auto;

    }

    .edit-event-switch {
        width: 150px;
    }

    .edit-event-user-invitees {
        margin: 0px auto;
    }

    .edit-event-user-invitees p {
        display: inline-block;
        width: 30%;
        margin: 5px;
        margin-top: 20px;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .edit-event-title-container {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        margin-top: 75px;
    }

    .edit-event-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .edit-event-form {
        width: 100%;
        text-align: center;
    }

    .edit-event-form-input {
        width: 400px;
        height: 30px;
        margin: 5px auto;
        text-align: center;
        display: block;
        background-color: white;
        color: black;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
    }

    .edit-event-form-input::-webkit-input-placeholder { /* WebKit browsers */
        color:    black;
         opacity: 1 !important;
    }

    .text-box {
        height: 100px;
        resize: none;
    }

    .edit-event-form-button {
        background-color: #FF2F46;
        color: white;
        width: 400px;
        height: 35px;
        margin: 5px auto;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
        cursor: pointer;
    }

    .edit-event-options-switch-title {
        width: 100%;
        margin: 0px auto;
        margin-top: 50px;
        text-align: center;
    }

    .edit-event-switches {
        display: flex;
        justify-content: space-evenly;
        width: 400px;
        margin: 0px auto;

    }

    .edit-event-switch {
        width: 150px;
    }

    .edit-event-user-invitees-container {
        width: 650px;
        margin: auto;
    }

    .edit-event-user-invitees {
        margin: 0px auto;
    }

    .edit-event-user-invitees p {
        display: inline-block;
        width: 30%;
        margin: 10px auto;
        margin-top: 20px;
    }
    
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}


.messages-container {
    width: 100%;
    padding: 0px;
    padding-bottom: 500px;
    margin: 0px;
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.b {
    color: black;
}

.no-dec {
    text-decoration: none;
}


/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {

    .messages-container {
        width: 100%;
        margin: 0px;
        padding-bottom: 200px;
    }

    .messages-title-container {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        margin-top: 75px;
    }

    .messages-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .messages-create-new-message-statement-container {
        width: 100%;
        text-align: center;
        margin: 30px auto;

    }

    .messages-create-new-message-statement {
        color: black;
    }

    .no-messages {
        width: 100%;
        margin-top: 100px;
        text-align: center;
        opacity: .5;
    }

    .messages-create-new-message-statement:hover {
        cursor: pointer;
        border-bottom: 2px solid #FF2F46;
    }

    .messages-new-message-icon {
        height: 15px;
    }

    .messages-messages-container {
        width: 100%;
        margin: auto;
    }

    .messages-message-container {
        display: block;
        width: 100%;
        margin: 30px auto;
        padding: 0px;
    }

    .messages-message-container:hover {
        opacity: .5;
        cursor: pointer;
    }

    .messages-message-user-icon, .messages-message-user-name-preview, .messages-message-read-status {
        display: inline-block;
        vertical-align: middle;
    }

    .messages-message-user-icon {
        justify-content: center;
        text-align: center;
        margin-left: 20px;
        margin-right: -20px;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        overflow:hidden;
        background-size: cover;
        background-position: center center;
    }

    .messages-message-user-name-preview {
        font-size: 13px;
        margin-left: 50px;
        color: black;
        width: 40%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .messages-message-read-status {
        position: relative;
        float: right;
        margin-right: 30px;

    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {

    .messages-container {
        width: 100%;
        margin: 0px;
        padding-bottom: 200px;
    }

    .messages-title-container {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        margin-top: 75px;
    }

    .messages-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .messages-create-new-message-statement-container {
        width: 100%;
        text-align: center;
        margin: 30px auto;

    }

    .messages-create-new-message-statement {
        color: black;
    }

    .no-messages {
        width: 100%;
        margin-top: 100px;
        text-align: center;
        opacity: .5;
    }

    .messages-create-new-message-statement:hover {
        cursor: pointer;
        border-bottom: 2px solid #FF2F46;
    }

    .messages-new-message-icon {
        height: 12px;
    }

    .messages-messages-container {
        width: 630px;
        margin: auto;
    }

    .messages-message-container {
        display: block;
        width: 100%;
        margin: 30px auto;
        padding: 0px;
    }

    .messages-message-container:hover {
        opacity: .5;
        cursor: pointer;
    }

    .messages-message-user-icon, .messages-message-user-name-preview, .messages-message-read-status {
        display: inline-block;
        vertical-align: middle;
    }

    .messages-message-user-icon {
        justify-content: center;
        text-align: center;
        margin-left: 20px;
        margin-right: -20px;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        overflow:hidden;
        background-size: cover;
        background-position: center center;
    }

    .messages-message-user-name-preview {
        margin-left: 70px;
        color: black;
        width: 50%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .messages-message-read-status {
        position: relative;
        float: right;
        margin-right: 30px;

    }
    
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}

.message-container {
    min-width: 100%;
    min-height: 100%;
    padding: 0px;
    /* padding-top: 25px; */
    padding-bottom: 150px;
    margin: 0px;
    /* background-color: #232323; */
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.b {
    color: black;
}

.no-dec {
    text-decoration: none;
}

.message-image-container-s3 {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow:hidden;
    margin: 10px;
    background-size: cover;
    background-position: center center;
}

.message-image-image-s3 {
    width:120px;
    margin: 10px;
}

.message-date-time img {
    height: 20px;
    width: 20px;
    display: center;
    margin: auto 5px;
    border-radius: 50%;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .arrow {
        width: 25px;
        margin: 20px 10px;
    }

    .arrow:hover {
        cursor: pointer;
    }

    .message-user-info {
        padding:0px;
        margin: 0px;
        margin-top: 75px;
        width: 100%;
    }

    .message-profile-icon {
        width: 75px;
        margin: 0px 20px;
    }

    .message-profile-icon:hover {
        cursor: pointer;
        color: #FF2F46;
    }

    .message-profile-name {
        margin: 10px 20px;
        font-family: "Permanent Marker", "cursive";
        color: black;

    }

    .message-profile-name:hover {
        cursor: pointer;
        color: #FF2F46;
    }

    .message-profile-status {
        margin: 10px 20px;
        font-family: "Indie Flower", "cursive";
        color: black;

    }

    .message-profile-status:hover {
        cursor:default;
    }

    .message-display {
        width: 90%;
        /* height: 320px; */
        height: 45vh;
        border: 1px solid #FF2F46;
        border-radius: 5px;
        margin: 20px auto;
        margin-bottom: 100px;
        padding: 5px;
        overflow-y: auto;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    .message-set {
        width: 100%;
        display: block;
        text-align: center;
        margin-top: 45px;
    }

    .message-date-time {
        margin: 10px auto;
        margin-top: 20px;
        font-size: 12px;
    }

    .message-text {
        width: 80%;
        margin:10px auto;
        margin-bottom: 20px;
        overflow-wrap: break-word;
        display: center;
    }

    .no-message-text {
        margin:50px auto;
        text-align: center;
        opacity: .5;
    }

    .no-message-text:hover {
        cursor: default;
    }

    .footer-form-container {
        width: 100%;
        display: block;
        position: fixed;
        bottom: 0;
        background-color: white;
    }

    .message-form {
        margin: 25px auto;
        margin-top: 0px;
        text-align: center;
    }
    
    .message-input {
        margin: 25px auto;
        width: 380px;
        height: 30px;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        background-color: white;
        color: black;
        font-size: 15px;
        font-family: "Poppins", "cursive";
    }

    .message-continue:hover {
        cursor: pointer;
    }

    .message-airplane {
        width: 30px;
        margin: 10px;
        text-align: center;
    }
    
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {

    .arrow {
        width: 25px;
        margin: 20px 10px;
    }

    .arrow:hover {
        cursor: pointer;
    }

    .message-user-info {
        padding:0px;
        margin: 0px;
        margin-top: 75px;
        width: 100%;
    }

    .message-profile-icon {
        width: 75px;
        margin: 0px 20px;
    }

    .message-profile-icon:hover {
        cursor: pointer;
        color: #FF2F46;
    }

    .message-profile-name {
        margin: 10px 20px;
        font-family: "Permanent Marker", "cursive";
        color: black;
    }

    .message-profile-name:hover {
        cursor: pointer;
        color: #FF2F46;
    }

    .message-profile-status {
        margin: 10px 20px;
        font-family: "Indie Flower", "cursive";
        color: black;

    }

    .message-profile-status:hover {
        cursor:default;
    }

    .message-display {
        width: 550px;
        /* height: 320px; */
        height: 45vh;
        border: 1px solid #FF2F46;
        border-radius: 5px;
        margin: 20px auto;
        padding: 5px;
        overflow-y: auto;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    .message-set {
        width: 100%;
        display: block;
        text-align: center;
        margin-top: 45px;
    }

    .message-date-time {
        margin: 10px auto;
        margin-top: 20px;
    }

    .message-date-time:hover {
        cursor: default;
    }

    .message-text {
        width: 80%;
        margin:10px auto;
        margin-bottom: 20px;
        overflow-wrap: break-word;
    }

    .message-text:hover {
        cursor: default;
    }

    .no-message-text {
        margin:30px auto;
        text-align: center;
        opacity: .5;
    }

    .no-message-text:hover {
        cursor: default;
    }

    .footer-form-container {
        width: 100%;
        display: block;
        /* position: fixed;
        bottom: 0; */
        background-color: white;
    }

    .message-form {
        margin: 25px auto;
        margin-top: 0px;
        text-align: center;
    }
    
    .message-input {
        margin: 25px auto;
        width: 380px;
        height: 30px;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        background-color: white;
        color: black;
        font-size: 15px;
        font-family: "Poppins", "cursive";
    }

    .message-continue:hover {
        cursor: pointer;
    }

    .message-airplane {
        width: 30px;
        margin: 10px;
        text-align: center;
    }

    .airplane {
        margin-bottom: -10px;
    }

    .message-input, .message-airplane {
        display: inline-block;
    }
    
  
    
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .message-user-info {
        width: 1000px;
        margin: auto;
        margin-top: 75px;
    }

}
.new-message-container {
    padding-bottom: 100px;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .new-message-form-input {
        display: block;
        margin: 20px auto;
        width: 85%;
        height: 30px;
        border: solid 2px #FF2F46;
        border-radius: 5px;
        background-color: white;
        color: black;
        text-align: center;
        font-size: 15px;
        font-family: "Poppins", "cursive";

    }
    
    .new-message-form-button {
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 85%;
        font-family: "Poppins", "cursive";
    }
    
    .new-message-form-button {cursor: pointer}
    .new-message-title {
        font-family: "Permanent Marker", "cursive";
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .new-message-zero-found {
        width: 100%;
        text-align: center;
        margin-top: 100px;
    }

    .new-message-user-icons-container {
        width: 100%;
        padding: 0px;
        text-align: center;
    }

    .new-message-profile-container-user {
        width: 75px;
        margin: 30px;
        display: inline-block;
        vertical-align: top;
        font-size: 15px;
    }

    .new-message-profile-container-user {
        text-align: center;
    }

    .new-message-user-icon-link {
        text-decoration: none;
        padding: 0px;
    }

    .new-message-user-icon {
        padding: 0px;
        margin: 0px;
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }

    .new-message-text-name-user {
        width: 100%;
        margin: 0px;
        font-family: "Permanent Marker", "cursive";
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .new-message-form-input {
        display: block;
        margin: 20px auto;
        width: 400px;
        height: 30px;
        border: solid 2px #FF2F46;
        border-radius: 5px;
        background-color: white;
        color: black;
        text-align: center;
        font-size: 15px;
        font-family: "Poppins", "cursive";
    }
    
    .new-message-form-button {
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 400px;
        font-family: "Poppins", "cursive";
    }
    
    .new-message-form-button {cursor: pointer}
    .new-message-title {
        font-family: "Permanent Marker", "cursive";
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .new-message-zero-found {
        width: 100%;
        text-align: center;
        margin-top: 100px;
    }

    .new-message-user-icons-container {
        width: 100%;
        padding: 0px;
        text-align: center;
    }

    .new-message-profile-container-user {
        width: 75px;
        margin: 30px;
        display: inline-block;
        vertical-align: top;
    }

    .new-message-profile-container-user {
        text-align: center;
    }

    .new-message-user-icon-link {
        text-decoration: none;
        padding: 0px;
    }

    .new-message-user-icon {
        padding: 0px;
        margin: 0px;
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }

    .new-message-text-name-user {
        width: 100%;
        margin: 0px;
        font-family: "Permanent Marker", "cursive";
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .new-message-user-icons-container {
        width: 1000px;
        padding: 0px;
        text-align: center;
        margin: 0px auto;
    }
}

.settings-container {
    width: 100%;
    padding: 0px;
    /* padding-top: 25px; */
    padding-bottom: 300px;
    margin: 0px;
    /* background-color: #232323; */
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.b {
    color: black;
}

.no-dec {
    text-decoration: none;
}

@media only screen and (max-width: 600px) {

    .settings-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .settings-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .settings-items-container {
        height: 100%;
        padding: 0px 20px;
        margin: 0px;
        font-family: "Poppins", "cursive";
    }

    .settings-items-container h4 {
        margin-bottom: 0px;
    }

    .settings-items-container ul {
        list-style-type: none;
        margin-top: 0px;
        padding-left: 10px;
    }

    .settings-items-container li {
        margin-bottom: 10px;
    }
}

@media only screen and (min-width: 600px) {
    .settings-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .settings-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .settings-items-container {
        height: 100%;
        padding: 0px 20px;
        margin: 0px;
        font-family: "Poppins", "cursive";
        text-align: center;
    }

    .settings-items-container h4 {
        margin-bottom: 10px;
        font-weight: bolder;
        font-size: larger;
    }

    .settings-items-container ul {
        list-style-type: none;
        margin-top: 0px;
        padding-left: 10px;
    }

    .settings-items-container li {
        margin-bottom: 10px;
    }
}

/* @media only screen and (min-width: 992px) {} */

.followers-container {
    padding-bottom: 100px;
}


/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .followers-title {
        font-family: "Permanent Marker", "cursive";
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .followers-user-icons-container {
        width: 100%;
        padding: 0px;
        text-align: center;
    }

    .followers-profile-container-add,
    .followers-profile-container-user {
        width: 100px;
        padding: 10px;
        margin: 10px;
        display: inline-block;
        vertical-align: top;
    }

    .followers-add-icon-link {
        text-decoration: none;
        padding: 0px;
    }

    .followers-add-icon {
        padding: 0px;
        margin: 0px;
        margin-top: 10px;
        width: 70px;
    }

    .followers-text-add-name {
        font-family: "Permanent Marker", "cursive";
        margin-top: 6px;
        margin-right: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .followers-profile-container-user {
        text-align: center;
    }

    .followers-user-icon-link {
        text-decoration: none;
        padding: 0px;
    }

    .followers-user-icon {
        padding: 0px;
        margin: 0px;
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }

    .followers-text-name-user {
        width: 100%;
        margin: 0px;
        font-family: "Permanent Marker", "cursive";
        font-size: 15px;
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .followers-title {
        font-family: "Permanent Marker", "cursive";
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .followers-user-icons-container {
        width: 100%;
        padding: 0px;
        text-align: center;
    }

    .followers-profile-container-add,
    .followers-profile-container-user {
        width: 100px;
        padding: 10px;
        margin: 10px;
        display: inline-block;
        vertical-align: top;
    }

    .followers-add-icon-link {
        text-decoration: none;
        padding: 0px;
    }

    .followers-add-icon {
        padding: 0px;
        margin: 0px;
        margin-top: 10px;
        width: 70px;
    }

    .followers-text-add-name {
        font-family: "Permanent Marker", "cursive";
        margin-top: 6px;
        margin-right: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .followers-profile-container-user {
        text-align: center;
    }

    .followers-user-icon-link {
        text-decoration: none;
        padding: 0px;
    }

    .followers-user-icon {
        padding: 0px;
        margin: 0px;
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }

    .followers-text-name-user {
        width: 100%;
        margin: 0px;
        font-family: "Permanent Marker", "cursive";
        font-size: 15px;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .followers-user-icons-container {
        width: 1000px;
        padding: 0px;
        text-align: center;
        margin: 0px auto;
    }
}


.following-container {
    padding-bottom: 100px;
}


/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .following-title {
        font-family: "Permanent Marker", "cursive";
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .following-user-icons-container {
        width: 100%;
        padding: 0px;
        text-align: center;
    }

    .following-profile-container-add,
    .following-profile-container-user {
        width: 100px;
        padding: 10px;
        margin: 10px;
        display: inline-block;
        vertical-align: top;
    }

    .following-add-icon-link {
        text-decoration: none;
        padding: 0px;
    }

    .following-add-icon {
        padding: 0px;
        margin: 0px;
        margin-top: 10px;
        width: 70px;
    }

    .following-text-add-name {
        font-family: "Permanent Marker", "cursive";
        margin-top: 6px;
        margin-right: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .following-profile-container-user {
        text-align: center;
    }

    .following-user-icon-link {
        text-decoration: none;
        padding: 0px;
    }

    .following-user-icon {
        padding: 0px;
        margin: 0px;
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }

    .following-text-name-user {
        width: 100%;
        margin: 0px;
        font-family: "Permanent Marker", "cursive";
        font-size: 15px;
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .following-title {
        font-family: "Permanent Marker", "cursive";
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .following-user-icons-container {
        width: 100%;
        padding: 0px;
        text-align: center;
    }

    .following-profile-container-add,
    .following-profile-container-user {
        width: 100px;
        padding: 10px;
        margin: 10px;
        display: inline-block;
        vertical-align: top;
    }

    .following-add-icon-link {
        text-decoration: none;
        padding: 0px;
    }

    .following-add-icon {
        padding: 0px;
        margin: 0px;
        margin-top: 10px;
        width: 70px;
    }

    .following-text-add-name {
        font-family: "Permanent Marker", "cursive";
        margin-top: 6px;
        margin-right: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .following-profile-container-user {
        text-align: center;
    }

    .following-user-icon-link {
        text-decoration: none;
        padding: 0px;
    }

    .following-user-icon {
        padding: 0px;
        margin: 0px;
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }

    .following-text-name-user {
        width: 100%;
        margin: 0px;
        font-family: "Permanent Marker", "cursive";
        font-size: 15px;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .following-user-icons-container {
        width: 1000px;
        padding: 0px;
        text-align: center;
        margin: 0px auto;
    }
}


.search-rider-container {
    padding-bottom: 100px;
}

.search-rider-title {
    margin-top: 75px;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .search-rider-form-input {
        display: block;
        margin: 20px auto;
        width: 85%;
        height: 30px;
        border: solid 2px #FF2F46;
        border-radius: 5px;
        background-color: white;
        color: black;
        text-align: center;
        font-size: 15px;
        font-family: "Poppins", "cursive";

    }
    
    .search-rider-form-button {
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 85%;
        font-family: "Poppins", "cursive";
    }
    
    .search-rider-form-button {cursor: pointer}
    .search-rider-title {
        font-family: "Permanent Marker", "cursive";
        width: 100%;
        text-align: center;
    }

    .search-rider-zero-found {
        width: 100%;
        text-align: center;
        margin-top: 100px;
    }

    .search-rider-user-icons-container {
        width: 100%;
        padding: 0px;
        text-align: center;
    }

    .search-rider-profile-container-user {
        width: 75px;
        margin: 30px;
        display: inline-block;
        vertical-align: top;
        font-size: 15px;
    }

    .search-rider-profile-container-user {
        text-align: center;
    }

    .search-rider-user-icon-link {
        text-decoration: none;
        padding: 0px;
    }

    .search-rider-user-icon {
        padding: 0px;
        margin: 0px;
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }

    .search-rider-text-name-user {
        width: 100%;
        margin: 0px;
        font-family: "Permanent Marker", "cursive";
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .search-rider-form-input {
        display: block;
        margin: 20px auto;
        width: 400px;
        height: 30px;
        border: solid 2px #FF2F46;
        border-radius: 5px;
        background-color: white;
        color: black;
        text-align: center;
        font-size: 15px;
        font-family: "Poppins", "cursive";
    }
    
    .search-rider-form-button {
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 400px;
        font-family: "Poppins", "cursive";
    }
    
    .search-rider-form-button {cursor: pointer}
    .search-rider-title {
        font-family: "Permanent Marker", "cursive";
        width: 100%;
        text-align: center;
    }

    .search-rider-zero-found {
        width: 100%;
        text-align: center;
        margin-top: 100px;
    }

    .search-rider-user-icons-container {
        width: 100%;
        padding: 0px;
        text-align: center;
    }

    .search-rider-profile-container-user {
        width: 75px;
        margin: 30px;
        display: inline-block;
        vertical-align: top;
    }

    .search-rider-profile-container-user {
        text-align: center;
    }

    .search-rider-user-icon-link {
        text-decoration: none;
        padding: 0px;
    }

    .search-rider-user-icon {
        padding: 0px;
        margin: 0px;
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }

    .search-rider-text-name-user {
        width: 100%;
        margin: 0px;
        font-family: "Permanent Marker", "cursive";
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .search-rider-user-icons-container {
        width: 1000px;
        padding: 0px;
        text-align: center;
        margin: 0px auto;
    }
}

.change-email-section {
    width: 90%;
    padding: 10px;
    text-align: center;
    margin: auto;
}

.change-email-form-input {
    display: block;
    margin: 15px auto;
    width: 85%;
    height: 30px;
    border: solid 2px #FF2F46;
    border-radius: 5px;
    background-color: white;
    color: black;
    text-align: center;
    font-size: 15px;
}

.change-email-form-button {
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
    margin: 10px auto;
}

.change-email-form-button {cursor: pointer}

.change-email-error-message {
    color: #FF2F46;
    font-family: "Poppins", "cursive";
    margin: 0px auto;
}
/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .change-email-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .change-email-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .change-email-section {
        text-align: center;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .change-email-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .change-email-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .change-email-form-input {
        width: 400px;
    }

    .change-email-form-button {
        width: 400px;
        display: block;
        margin: 15px auto;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}
.change-password-section {
    width: 90%;
    padding: 10px;
    text-align: center;
    margin: auto;
}

.change-password-form-input {
    display: block;
    margin: 15px auto;
    width: 85%;
    height: 30px;
    border: solid 2px #FF2F46;
    border-radius: 5px;
    background-color: white;
    color: black;
    text-align: center;
    font-size: 15px;
}

.change-password-form-button {
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
    margin: 10px auto;
}

.change-password-form-button {cursor: pointer};

.change-password-error-message {
    color: #FF2F46;
    font-family: "Poppins", "cursive";
    margin: 0px auto;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .change-password-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .change-password-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .change-password-section {
        text-align: center;
    }

    .change-password-forgot-password-statement {
        margin: 0px;
    }

    .change-password-forgot-password-link {
        margin: 0px;
        font-family: "Poppins", "cursive";
        color: #FF2F46
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .change-password-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .change-password-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .change-password-form-input {
        display: block;
        margin: 20px auto;
        width: 400px;
        height: 30px;
        border: solid 2px #FF2F46;
        border-radius: 5px;
        background-color: white;
        color: black;
        text-align: center;
        font-size: 15px;
    }

    .change-password-forgot-password-statement {
        margin: 20px auto;
    }

    .change-password-form-button {
        display: inline-block;
        width: 400px;
    }

    .change-password-forgot-password-link {
        margin: 0px;
        font-family: "Poppins", "cursive";
        color: #FF2F46
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .change-password-form-button {
        display: block;
        width: 400px;
    }
}
.email-updates-marketing-section {
    width: 90%;
    padding: 10px;
    text-align: center;
    margin: auto;
}

.email-updates-marketing-button {
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
    margin-top: 30px;
}

.email-updates-marketing-button {cursor: pointer}
/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .email-updates-marketing-title-container {
        width: 100%;
        text-align: center;
    }

    .email-updates-marketing-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .email-updates-marketing-section {
        text-align: center;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .email-updates-marketing-title-container {
        width: 100%;
        text-align: center;
    }

    .email-updates-marketing-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
        font-size: 45px;
    }

    .email-updates-marketing-button {
        width: 400px;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .email-updates-marketing-title {
        font-size: 55px;
    }
}
.hosted-events-history-section {
    width: 90%;
    padding: 10px;
    text-align: center;
    margin: auto;
}


.hosted-events-history-button {
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
    margin-top: 30px;
}

.hosted-events-history-button {cursor: pointer}

.hosted-events-history-event {
    font-family: "Poppins", "cursive";
    margin: 25px auto;
    width: auto;
}

.hosted-events-history-event:hover {
    color: #FF2F46;
    cursor: pointer;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .hosted-events-history-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .hosted-events-history-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .hosted-events-history-section {
        text-align: center;
        margin-bottom: 150px;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .hosted-events-history-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .hosted-events-history-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .hosted-events-history-button {
        width: 400px;
    }

    .hosted-events-history-section {
        text-align: center;
        margin-bottom: 150px;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}
.attended-events-history-section {
    width: 90%;
    padding: 10px;
    text-align: center;
    margin: auto;
}

.attended-events-history-button {
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
    margin-top: 30px;
}

.attended-events-history-button {cursor: pointer}

.attended-events-history-event {
    font-family: "Poppins", "cursive";
    margin: 25px auto;
    width: auto;
}

.attended-events-history-event:hover {
    color: #FF2F46;
    cursor: pointer;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .attended-events-history-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .attended-events-history-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .attended-events-history-section {
        text-align: center;
        margin-bottom: 150px;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .attended-events-history-title-container {
        width: 100%;
        text-align: center;
        margin-bottom: -10px;
        margin-top: 75px;
    }

    .attended-events-history-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
    }

    .attended-events-history-button {
        width: 400px;
    }

    .attended-events-history-section {
        text-align: center;
        margin-bottom: 150px;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}
.change-payment-section {
    width: 90%;
    padding: 10px;
    text-align: center;
    margin-top: 50px;
}


.change-payment-button {
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
}

.change-payment-button {cursor: pointer}

.change-payment-event {
    font-family: "Poppins", "cursive";
}
/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .change-payment-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .change-payment-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .change-payment-section {
        text-align: center;
        width: 80%;
        margin: auto;
        margin-top: 50px;
    }

    .change-payment-input {
        width: 85%;
        height: 30px;
        margin: 5px auto;
        text-align: center;
        display: block;
        background-color: white;
        color: black;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .change-payment-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .change-payment-title {
        font-family: "Permanent Marker", "cursive";
        color: red;
        margin-bottom: 0px;
    }

    .change-payment-section {
        text-align: center;
        width: 70%;
        margin: auto;
        margin-top: 50px;
    }

    .change-payment-input {
        width: 400px;
        height: 30px;
        margin: 5px auto;
        text-align: center;
        display: block;
        background-color: white;
        color: black;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
    }

    .change-payment-button {
        width: 400px;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .change-payment-title-container {
        width: 100%;
        text-align: center;
        margin-bottom: 0px;
        margin-top: 75px;
    }

    .change-payment-section {
        text-align: center;
        width: 800px;
        margin: auto;
        margin-top: 50px;
    }
}
.next-payment-date-section {
    width: 90%;
    padding: 10px;
    text-align: center;
}


.next-payment-date-button {
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
    margin-top: 50px;
}

.next-payment-date-button {cursor: pointer}

.next-payment-date-event {
    font-family: "Poppins", "cursive";
}
/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .next-payment-date-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .next-payment-date-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .next-payment-date-section {
        text-align: center;
        width: 80%;
        margin: auto;
        margin-top: 50px;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .next-payment-date-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .next-payment-date-title {
        font-family: "Permanent Marker", "cursive";
        color: red;
        margin-bottom: 0px;
    }

    .next-payment-date-section {
        text-align: center;
        width: 70%;
        margin: auto;
        margin-top: 50px;
    }

    .next-payment-date-button {
        width: 400px;
        margin-top: 50px;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .next-payment-date-title-container {
        width: 100%;
        text-align: center;
        margin-bottom: 0px;
        margin-top: 75px;
    }

    .next-payment-date-section {
        text-align: center;
        width: 800px;
        margin: auto;
        margin-top: 50px;
    }
}
.cancel-membership-section {
    width: 90%;
    padding: 10px;
    text-align: center;
}


.cancel-membership-button {
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
    margin-top: 30px;
}

.cancel-membership-button {cursor: pointer}

.cancel-membership-event {
    font-family: "Poppins", "cursive";
}
/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .cancel-membership-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .cancel-membership-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .cancel-membership-section {
        text-align: center;
        width: 80%;
        margin: auto;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .cancel-membership-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .cancel-membership-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 50px;
    }

    .cancel-membership-section {
        text-align: center;
        width: 70%;
        margin: auto;
    }

    .cancel-membership-button {
        width: 400px;
        margin: 15px auto;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .cancel-membership-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-top: 75px;
    }

    .cancel-membership-section {
        text-align: center;
        width: 800px;
        margin: auto;
    }

    .cancel-membership-button {
        display: block;
        margin: 25px auto;
    }
}
.cancel-membership-section {
    width: 90%;
    padding: 10px;
    text-align: center;
}


.cancel-membership-button {
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
    margin-top: 30px;
}

.cancel-membership-button {cursor: pointer}

.cancel-membership-event {
    font-family: "Poppins", "cursive";
}
/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .cancel-membership-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .cancel-membership-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .cancel-membership-section {
        text-align: center;
        width: 80%;
        margin: auto;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .cancel-membership-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .cancel-membership-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 50px;
    }

    .cancel-membership-section {
        text-align: center;
        width: 70%;
        margin: auto;
    }

    .cancel-membership-button {
        width: 400px;
        margin: 15px auto;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .cancel-membership-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-top: 75px;
    }

    .cancel-membership-section {
        text-align: center;
        width: 800px;
        margin: auto;
    }

    .cancel-membership-button {
        display: block;
        margin: 25px auto;
    }
}
.terms-of-service-section {
    width: 90%;
    padding: 10px;
    text-align: center;
    font-family: "Poppins", "cursive";
    padding-bottom: 200px;
}


.terms-of-service-button {
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
    margin-top: 30px;
}

.terms-of-service-button {cursor: pointer}

.terms-of-service-event {
    font-family: "Poppins", "cursive";
}
/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .terms-of-service-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .terms-of-service-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .terms-of-service-section {
        text-align: center;
        width: 80%;
        margin: auto;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .terms-of-service-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .terms-of-service-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .terms-of-service-section {
        text-align: center;
        width: 70%;
        margin: auto;
    }

    .terms-of-service-button {
        width: 400px;
    }

}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .terms-of-service-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
        margin-top: 75px;
    }

    .terms-of-service-section {
        text-align: center;
        width: 800px;
        margin: auto;
    }
}
.privacy-section {
    width: 90%;
    padding: 10px;
    text-align: center;
    font-family: "Poppins", "cursive";
    padding-bottom: 200px;
}


.privacy-button {
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
    margin-top: 30px;
}

.privacy-button {cursor: pointer}

.privacy-event {
    font-family: "Poppins", "cursive";
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .privacy-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .privacy-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .privacy-section {
        text-align: center;
        width: 80%;
        margin: auto;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .privacy-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .privacy-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .privacy-section {
        text-align: center;
        width: 70%;
        margin: auto;
    }

    .privacy-button {
        width: 400px;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .privacy-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
        margin-top: 75px;
    }

    .privacy-section {
        text-align: center;
        width: 800px;
        margin: auto;
    }

}
.cookies-section {
    width: 90%;
    padding: 10px;
    text-align: center;
    font-family: "Poppins", "cursive";
    padding-bottom: 200px;
}


.cookies-button {
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
    margin-top: 30px;
}

.cookies-button {cursor: pointer}

.cookies-event {
    font-family: "Poppins", "cursive";
}
/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .cookies-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .cookies-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .cookies-section {
        text-align: center;
        width: 80%;
        margin: auto;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .cookies-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .cookies-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .cookies-section {
        text-align: center;
        width: 70%;
        margin: auto;
    }

    .cookies-button {
        width: 400px;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .cookies-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .cookies-section {
        text-align: center;
        width: 800px;
        margin: auto;
    }
}
.blocked-users-section {
    width: 90%;
    padding: 10px;
    text-align: center;
    margin: auto;
}


.blocked-users-button {
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
    margin-top: 30px;
}

.blocked-users-button {cursor: pointer}

.blocked-users-event {
    font-family: "Poppins", "cursive";
}
/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .blocked-users-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .blocked-users-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .blocked-users-section {
        text-align: center;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .blocked-users-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .blocked-users-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .blocked-users-button {
        width: 400px;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}
.help-and-feedback-section {
    width: 90%;
    padding: 10px;
    text-align: center;
    font-family: "Poppins", "cursive";
    padding-bottom: 200px;
}

.help-and-feedback-button {
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
    margin-top: 30px;
}

.help-and-feedback-button {cursor: pointer}

.help-and-feedback-event {
    font-family: "Poppins", "cursive";
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .help-and-feedback-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .help-and-feedback-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .help-and-feedback-section {
        text-align: center;
        width: 80%;
        margin: auto;
    }

    .help-and-feedback-form {
        width: 80%;
        display: block;
        margin: auto;
    }

    .help-and-feedback-types {
        width: 200px;
        height: 25px;
        text-align: center;
        background-color: white;
        color: black;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
    }

    .help-and-feedback-textarea {
        width: 90%;
        height: 150px;
        margin: 5px auto;
        display: block;
        background-color: white;
        color: black;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
        resize: none;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .help-and-feedback-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .help-and-feedback-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .help-and-feedback-section {
        text-align: center;
        width: 80%;
        margin: auto;
    }

    .help-and-feedback-form {
        width: 400px;
        margin: auto;
    }

    .help-and-feedback-types {
        width: 200px;
        height: 25px;
        text-align: center;
        background-color: white;
        color: black;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
    }

    .help-and-feedback-textarea {
        width: 400px;
        height: 200px;
        margin: 5px auto;
        display: block;
        background-color: white;
        color: black;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        font-size: 18px;
        font-family: "Poppins", "cursive";
        resize: none;
    }

    .help-and-feedback-button {
        width: 400px;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .help-and-feedback-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .help-and-feedback-section {
        text-align: center;
        width: 80%;
        margin: auto;
    }
}
.about-section {
    width: 90%;
    padding: 10px;
    text-align: center;
    margin: auto;
    margin-bottom: 200px;
}

.about-button {
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
    margin-top: 30px;
}

.about-button {cursor: pointer}

.about-tag {
    margin-top: 50px;
}

.about-follow-icons {
    width: 100%;
}

.about-social-icon-link {
    margin: 8px;
}

.about-social-icon {
    height: 35px;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .about-title-container {
        width: 100%;
        text-align: center;
        margin: auto;
        margin-top: 75px;
    }

    .about-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
        margin-top: 75px;
        width: 100%;
    }

    .about-section {
        text-align: center;
    }

    .about-header {
        margin-top: 75px;
    }

    .about-content {
        width: 100%;
        margin: auto;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .about-title-container {
        width: 400px;
        text-align: center;
        margin: auto;
        margin-top: 75px;
    }

    .about-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
    }

    .about-button {
        width: 400px;
    }

    .about-header {
        margin-top: 80px;
    }

    .about-content {
        width: 400px;
        margin: auto;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .about-title-container {
        width: 500px;
        text-align: center;
        margin: auto;
        margin-top: 75px;
    }

    .about-header {
        margin-top: 85px;
    }

    .about-content {
        width: 500px;
        margin: auto;
    }
}
.rider-tip-jar-overview-statement {
    width: 90%;
    margin: 20px auto;
    margin-top: 25px;
    font-size: 12px;
}

.rider-tip-jar-guide-link:hover {
    color: #FF2F46;
    text-decoration: underline;
}

.rider-tip-jar-enable-button-container {
    width: 100%;
    text-align: center;
    margin-top: 45px;
}

.rider-tip-jar-enable-button {
    background-color: #FF2F46;
    color: white;
    border: none;
    border-radius: 5px;
    height: 30px;
    margin: 10px auto;
}

.rider-tip-jar-enable-button:hover {
    cursor: pointer;
}

.rider-tip-jar-details {
    width: 95%;
    margin: 50px auto;
    margin-top: 50px;
    margin-bottom: 200px;
}

.rider-tip-jar-total {
    margin: 0px auto;
}

.rider-tip-jar-cashout-button {
    background-color: #FF2F46;
    color: white;
    border: #FF2F46 solid 1px;
    border-radius: 5px;
    height: 25px;
    width: 120px;
    margin-top: 10px;
}

.rider-tip-jar-cashout-button:hover {
    cursor: pointer;
}

.rider-tip-jar-disable-button {
    background-color: white;
    color: #FF2F46;
    border: #FF2F46 solid 1px;
    border-radius: 5px;
    height: 25px;
    width: 120px;
    margin-top: 10px;
    display: block;
}

.rider-tip-jar-disable-button-confirm {
    background-color: #FF2F46;
    color: white;
    border: #FF2F46 solid 1px;
    border-radius: 5px;
    height: 25px;
    width: 120px;
    margin-top: 10px;
    display: block;
}

.rider-tip-jar-disable-button-confirm:hover {
    cursor: pointer;
}

.rider-tip-jar-disable-button:hover {
    cursor: pointer;
    background-color: #FF2F46;
    color: white;
}

.rider-tip-jar-no-tip-statement {
    margin: 10px auto;
    margin-top: 50px;
    font-size: 12px;
    width: 90%;
}

.rider-tip-jar-disable-statement {
    font-size: 12px;
}

.rider-tip-jar-tipper-container {
    width: 100%;
    margin-top: 50px;
}

.rider-tip-jar-tipper {
    margin-top: 20px;
    margin-left: 10px;
}
/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .rider-tip-jar-enable-button {
        width: 50%;
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) { 
    .rider-tip-jar-enable-button {
        width: 300px;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}
.tip-jar-cashout-total {
    width: 100%;
    margin-top: 30px;
}

.tip-jar-cashout-total p, .tip-jar-cashout-total button {
    font-size: 14px;
    margin-left: 20px;
}

.tip-jar-cashout-paypal-logo {
    width: 65px;
    margin-left: 10px;
    margin-bottom: -4px;
}

.tip-jar-cashout-paypal-account {
    background-color: white;
    color: #FF2F46;
    border: #FF2F46 solid 1px;
    border-radius: 5px;
    height: 25px;
}

.tip-jar-cashout-paypal-account:hover {
    cursor: pointer;
    background-color: #FF2F46;
    color: white;
}

.tip-jar-cashout-continue-container {
    width: 100%;
    text-align: center;
    margin-top: 75px;
}

.tip-jar-cashout-continue {
    width: 250px;
    margin: 10px auto;
    text-align: center;
    background-color: #FF2F46;
    color: white;
    border: none;
    border-radius: 5px;
    height: 30px;
    display: block;
}

.tip-jar-cashout-account-method-form-container {
    width: 90%;
    margin: 20px auto;
    margin-bottom: 150px;
    margin-top: 50px;
}

.tip-jar-cashout-account-method-form {
    text-align: center;
}

.tip-jar-cashout-account-method-form label, 
.tip-jar-cashout-account-method-form input,
.tip-jar-cashout-account-method-form button {
    display: block;
}

.tip-jar-cashout-account-method-form label {
    font-size: 12px;
}

.tip-jar-cashout-account-method-form input {
    margin: 10px auto;
    margin-bottom: 20px;
    width: 250px;
    padding: 10px 5px;
    border: #FF2F46 solid 1px;
    border-radius: 5px;
}

.tip-jar-cashout-account-method-form button {
    margin: 10px auto;
    color: white;
    background-color: #FF2F46;
    border: none;
    height: 30px;
    width: 252px;
    border-radius: 5px;

}

.tip-jar-cashout-account-method-form button:hover {
    cursor: pointer;
}

.tip-jar-cashout-continue:hover {
    cursor: pointer;
}

.tip-jar-confirm-cashout-confirmation {
    width: 90%;
    text-align: center;
    margin: 10px auto;
    margin-top: -45px;
    font-size: 15px;
}

.tip-jar-confirm-cashout-container {
    width: 100%;
    text-align: center;
    margin-bottom: 150px;
}

.tip-jar-confirm-cashout-continue {
    width: 250px;
    margin-top: 20px;
    text-align: center;
    background-color: #00aa00;
    color: white;
    border: none;
    border-radius: 5px;
    height: 30px;
}

.tip-jar-confirm-cashout-continue:hover {
    cursor: pointer;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) { 
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}
.tip-jar-rules-guide-guide-title-container {
    width: 100%;
    text-align: center;
}
 
.tip-jar-rules-guide-guide-steps {
    width: 95%;
    margin: 10px auto;
}

.guide-content {
    margin: 25px auto;
}

.tip-jar-rules-guide-rules-title-container {
    width: 100%;
    text-align: center;
}

.tip-jar-rules-guide-rules-steps {
    width: 95%;
    margin: 10px auto;
    margin-bottom: 200px;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) { 
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}
.motorcyk-platform-rules-container {
    width: 90%;
    margin: 50px auto;
}

.motorcyk-platform-rules-content-item {
    margin: 10px;
    margin-left: 10px;
    font-size: 12px;
}

.motorcyk-platform-rules-container-final {
    margin-bottom: 150px;
}
/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) { 
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}
