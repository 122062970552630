.loading-content {
    width: 150px;
    text-align: center;
    position:absolute;
    top: 40%;
    left: 0;
    right: 0;
    margin:auto;
}

.loading-icon {
    width: 100px;
}



