.forgot-password-container {
    width: 100%;
    height: 100vh;
    padding: 0px;
    padding-top: 25px;
    margin: 0px;
    text-align: center;
    background-color: #232323;
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.no-dec {
    text-decoration: none;
}

.forgot-password-title {
    font-family: "Permanent Marker", "cursive";
}

.forgot-password-message {
    font-family: "Poppins", "cursive";
    width: 300px;
    margin: 50px auto;
}

.forgot-password-form {
    background-color: #232323;
}

.forgot-password-button {
    margin: 10px auto;
    display: block;
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
}

.forgot-password-cancel {
    margin: 10px auto;
    display: block;
    background-color: white;
    border: none;
    border-radius: 5px;
    color: #232323;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
}

.forgot-password-submit:hover, .forgot-password-cancel:hover {
    cursor: pointer;
}

.forgot-password-footer {
    font-family: "Poppins", "cursive";
    font-size: 10px;
    margin: 300px auto;
    padding-bottom: 50px;
    background-color: #232323;
}

.forgot-logo {
    margin: 0px auto;
    margin-top: 30px;
    width: 100px;
}

.forgot-password-button:hover {
    cursor: pointer;
}

.forgot-password-form-error-message {
    color: #FF2F46;
    font-family: "Poppins", "cursive";
    width: 400px;
    margin: 0px auto;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */
@media only screen and (max-width: 600px) {
    .forgot-password-title-container {
        width: 100%;
        text-align: center;
    }
    
    .forgot-password-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .forgot-password-input {
        margin: 25px auto;
        display: block;
        width: 85%;
        height: 30px;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        background-color: #232323;
        color: white;
        text-align: center;
        font-size: 15px;
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .forgot-password-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
        font-size: 45px;
    }

    .forgot-password-form-input {
        width: 400px;
    }

    .forgot-password-form-button {
        width: 400px;
    }

    .forgot-password-input {
        margin: 25px auto;
        display: block;
        width: 480px;
        height: 30px;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        background-color: #232323;
        color: white;
        text-align: center;
        font-size: 15px;
    }

    .forgot-password-button {
        margin: 10px auto;
        display: block;
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 480px;
        font-family: "Poppins", "cursive";
    }
    
    .forgot-password-cancel {
        margin: 10px auto;
        display: block;
        background-color: white;
        border: none;
        border-radius: 5px;
        color: #232323;
        font-size: 15px;
        height: 35px;
        width: 480px;
        font-family: "Poppins", "cursive";
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .forgot-password-title {
        font-size: 55px;
    }
}
