/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .profile-saved-component-container {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 150px;
        margin: 0px;
        margin-top: -40px;
        text-align: center;
    }

    .profile-posts-component-message-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }

    .profile-saved-statement {
        font-family: "Poppins", "cursive";
        text-align: center;
        width: 90%;
        margin: 10px auto;
    }

    .profile-saved-component-event-box {
        display: inline-block;
        width: 180px;
        padding: 10px;
        border-radius: 10px;
        margin: 10px;
        border: solid 1px black;
        text-align: center;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    .profile-saved-component-event-box p {
        margin: 10px auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .profile-saved-component-date {
        font-family: "Poppins", "cursive";
        opacity: .70;
        color: black;
        font-size: 10px;
    }

    .profile-saved-component-title {
        font-family: "Poppins", "cursive";
        font-size: 15px;
        color: black;
    }

    .profile-saved-component-city {
        font-family: "Permanent Marker", "cursive";
        color:#FF2F46;
        font-size: 15px;
    }

    .profile-saved-component-host {
        font-family: "Poppins", "cursive";
        font-size: 8px;
        color: black;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .profile-saved-component-container {
        width: 90%;
        padding: 20px;
        padding-bottom: 150px;
        margin: auto;
        margin-top: -40px;
        text-align: center;
    }

    .profile-posts-component-message-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }

    .profile-saved-statement {
        font-family: "Poppins", "cursive";
        text-align: center;
        width: 90%;
        margin: 10px auto;
    }

    .profile-saved-component-event-box {
        display: inline-block;
        width: 180px;
        padding: 10px;
        border-radius: 10px;
        margin: 10px;
        border: solid 1px black;
        text-align: center;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    .profile-saved-component-event-box p {
        margin: 10px auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .profile-saved-component-date {
        font-family: "Poppins", "cursive";
        opacity: .70;
        color: black;
        font-size: 10px;
    }

    .profile-saved-component-title {
        font-family: "Poppins", "cursive";
        font-size: 15px;
        color: black;
    }

    .profile-saved-component-city {
        font-family: "Permanent Marker", "cursive";
        color:#FF2F46;
        font-size: 15px;
    }

    .profile-saved-component-host {
        font-family: "Poppins", "cursive";
        font-size: 8px;
        color: black;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}