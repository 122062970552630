.signup-image-upload-container {
    margin-top: -100;
    background-color: #232323;
    height: 100vh;
    padding-bottom: 200px;
}

.signup-image-upload-edit-section {
    width: 90%;
    padding: 10px;
    text-align: center;
    margin: auto;
    margin-bottom: 200px;
}

.edit-profile-file-name {
    width: 300px;
    margin: 5px auto;
    overflow-wrap: break-word;
}

.signup-image-upload-icon-container {
    margin: 30px auto;
    text-align: center;
    padding: 0px;
}

.signup-image-upload-image{
    display: block;
    padding: 0px;
    width: 175px;
    margin: 30px auto;
    margin-bottom: 0px;
    border-radius: 50%;
}

.signup-image-upload-container-s3 {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 175px;
    height: 175px;
    margin: 50px auto;
    border-radius: 50%;
    overflow:hidden;
    border: .1px solid black;
    background-size: cover;
    background-position: center center;
}

.signup-image-upload-form-button {
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
    margin-top:  20px;
}

.signup-image-upload-form-button {cursor: pointer}

.signup-image-upload-error-message {
    color: #FF2F46;
    font-family: "Poppins", "cursive";
    margin: 0px auto;
    margin-top: 20px;
}

.uploader__container {
    margin: 50px auto;
}

.signup-image-upload-button {
    width: 200px;
    height: 30px;
    background-color: black;
    color: white;
    border-radius: 20px;
    border: none;
}

.signup-image-upload-button:hover {
    cursor: pointer;
}

.bottom-button {
    margin-bottom: 200px;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */
@media only screen and (max-width: 600px) {
    .signup-image-upload-title-container {
        width: 100%;
        text-align: center;
        background-color:#232323;
        padding-top: -50px;
    }
    
    .signup-image-upload-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin: 0px;
        padding-top: 75px;
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .signup-image-upload-title-container {
        width: 100%;
        text-align: center;
        background-color:#232323;
        padding-top: -50px;
    }
    
    .signup-image-upload-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin: 0px;
        padding-top: 75px;
    }

    .signup-image-upload-form-input {
        width: 400px;
    }

    .signup-image-upload-form-button {
        width: 400px;
        margin: 10px auto;
        display: block;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}
