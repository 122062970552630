.message-container {
    min-width: 100%;
    min-height: 100%;
    padding: 0px;
    /* padding-top: 25px; */
    padding-bottom: 150px;
    margin: 0px;
    /* background-color: #232323; */
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.b {
    color: black;
}

.no-dec {
    text-decoration: none;
}

.message-image-container-s3 {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow:hidden;
    margin: 10px;
    background-size: cover;
    background-position: center center;
}

.message-image-image-s3 {
    width:120px;
    margin: 10px;
}

.message-date-time img {
    height: 20px;
    width: 20px;
    display: center;
    margin: auto 5px;
    border-radius: 50%;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .arrow {
        width: 25px;
        margin: 20px 10px;
    }

    .arrow:hover {
        cursor: pointer;
    }

    .message-user-info {
        padding:0px;
        margin: 0px;
        margin-top: 75px;
        width: 100%;
    }

    .message-profile-icon {
        width: 75px;
        margin: 0px 20px;
    }

    .message-profile-icon:hover {
        cursor: pointer;
        color: #FF2F46;
    }

    .message-profile-name {
        margin: 10px 20px;
        font-family: "Permanent Marker", "cursive";
        color: black;

    }

    .message-profile-name:hover {
        cursor: pointer;
        color: #FF2F46;
    }

    .message-profile-status {
        margin: 10px 20px;
        font-family: "Indie Flower", "cursive";
        color: black;

    }

    .message-profile-status:hover {
        cursor:default;
    }

    .message-display {
        width: 90%;
        /* height: 320px; */
        height: 45vh;
        border: 1px solid #FF2F46;
        border-radius: 5px;
        margin: 20px auto;
        margin-bottom: 100px;
        padding: 5px;
        overflow-y: auto;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    .message-set {
        width: 100%;
        display: block;
        text-align: center;
        margin-top: 45px;
    }

    .message-date-time {
        margin: 10px auto;
        margin-top: 20px;
        font-size: 12px;
    }

    .message-text {
        width: 80%;
        margin:10px auto;
        margin-bottom: 20px;
        overflow-wrap: break-word;
        display: center;
    }

    .no-message-text {
        margin:50px auto;
        text-align: center;
        opacity: .5;
    }

    .no-message-text:hover {
        cursor: default;
    }

    .footer-form-container {
        width: 100%;
        display: block;
        position: fixed;
        bottom: 0;
        background-color: white;
    }

    .message-form {
        margin: 25px auto;
        margin-top: 0px;
        text-align: center;
    }
    
    .message-input {
        margin: 25px auto;
        width: 380px;
        height: 30px;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        background-color: white;
        color: black;
        font-size: 15px;
        font-family: "Poppins", "cursive";
    }

    .message-continue:hover {
        cursor: pointer;
    }

    .message-airplane {
        width: 30px;
        margin: 10px;
        text-align: center;
    }
    
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {

    .arrow {
        width: 25px;
        margin: 20px 10px;
    }

    .arrow:hover {
        cursor: pointer;
    }

    .message-user-info {
        padding:0px;
        margin: 0px;
        margin-top: 75px;
        width: 100%;
    }

    .message-profile-icon {
        width: 75px;
        margin: 0px 20px;
    }

    .message-profile-icon:hover {
        cursor: pointer;
        color: #FF2F46;
    }

    .message-profile-name {
        margin: 10px 20px;
        font-family: "Permanent Marker", "cursive";
        color: black;
    }

    .message-profile-name:hover {
        cursor: pointer;
        color: #FF2F46;
    }

    .message-profile-status {
        margin: 10px 20px;
        font-family: "Indie Flower", "cursive";
        color: black;

    }

    .message-profile-status:hover {
        cursor:default;
    }

    .message-display {
        width: 550px;
        /* height: 320px; */
        height: 45vh;
        border: 1px solid #FF2F46;
        border-radius: 5px;
        margin: 20px auto;
        padding: 5px;
        overflow-y: auto;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    .message-set {
        width: 100%;
        display: block;
        text-align: center;
        margin-top: 45px;
    }

    .message-date-time {
        margin: 10px auto;
        margin-top: 20px;
    }

    .message-date-time:hover {
        cursor: default;
    }

    .message-text {
        width: 80%;
        margin:10px auto;
        margin-bottom: 20px;
        overflow-wrap: break-word;
    }

    .message-text:hover {
        cursor: default;
    }

    .no-message-text {
        margin:30px auto;
        text-align: center;
        opacity: .5;
    }

    .no-message-text:hover {
        cursor: default;
    }

    .footer-form-container {
        width: 100%;
        display: block;
        /* position: fixed;
        bottom: 0; */
        background-color: white;
    }

    .message-form {
        margin: 25px auto;
        margin-top: 0px;
        text-align: center;
    }
    
    .message-input {
        margin: 25px auto;
        width: 380px;
        height: 30px;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        background-color: white;
        color: black;
        font-size: 15px;
        font-family: "Poppins", "cursive";
    }

    .message-continue:hover {
        cursor: pointer;
    }

    .message-airplane {
        width: 30px;
        margin: 10px;
        text-align: center;
    }

    .airplane {
        margin-bottom: -10px;
    }

    .message-input, .message-airplane {
        display: inline-block;
    }
    
  
    
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .message-user-info {
        width: 1000px;
        margin: auto;
        margin-top: 75px;
    }

}