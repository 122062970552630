.signup-profile-details-container {
    margin-top: -100;
    background-color: #232323;
    height: 100vh;
    padding-bottom: 200px;
}

.signup-profile-details-edit-section {
    width: 90%;
    padding: 10px;
    text-align: center;
    margin: auto;
    margin-bottom: 200px;
}

.signup-profile-details-image {
    width: 100px;
    margin: auto;
    margin-top: 50px;
    display: block;
}

.signup-profile-details-form-input {
    display: block;
    margin: 5px auto;
    width: 85%;
    height: 30px;
    border: solid 2px #FF2F46;
    border-radius: 5px;
    background-color: #232323;
    color: white;
    text-align: center;
    font-size: 15px;
}

.signup-profile-details-form-button {
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
    margin-top:  20px;
}

.signup-profile-details-form-button {cursor: pointer}

.signup-profile-details-error-message {
    color: #FF2F46;
    font-family: "Poppins", "cursive";
    margin: 0px auto;
    margin-top: 20px;
}

.bottom-button {
    margin-bottom: 200px;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */
@media only screen and (max-width: 600px) {
    .signup-profile-details-title-container {
        width: 100%;
        text-align: center;
        background-color:#232323;
        padding-top: -50px;
    }
    
    .signup-profile-details-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin: 0px;
        padding-top: 75px;    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .signup-profile-details-title-container {
        width: 100%;
        text-align: center;
    }
    
    .signup-profile-details-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin: 0px;
        padding-top: 75px;
    }

    .signup-profile-details-form-input {
        width: 400px;
    }

    .signup-profile-details-form-button {
        width: 400px;
        margin: 10px auto;
        display: block;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}
