.signup-container {
    width: 100%;
    padding: 30px 0px;
    margin: 0px;
    text-align: center;
    background-color: #232323;
    overflow: auto;
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.no-dec {
    text-decoration: none;
}

.signup-plan-title {
    font-family: "Permanent Marker", "cursive";
    color: #FF2F46;
    margin-bottom: 0px;
}

.signup-plan-row {
    height: 10px;
    margin: auto;
    font-size: 12px;
}

.signup-plan-exit-button {
    display: block;
    width: 250px;
    height: 30px;
    margin: 0px auto;
    background-color: #FF2F46;
    color: white;
    border: none;
    border-radius: 10px; 
}

.signup-plan-exit-button:hover {
    cursor: pointer;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {

    .signup-table-title {
        font-size: 22px;
        color: white;
        width: 100%;
        margin: 10px auto;
    }

    .signup-table {
        text-align: center;
        background-color: #191919;
        color: white;
        width: 100%;
        margin: 25px auto;
        margin-bottom: 50px;
        padding: 25px 0px;
        border-radius: 10px;
    }

    .signup-plan-button {
        display: block;
        width: 100px;
        height: 30px;
        margin: 0px auto;
        margin-top: 30px;
        background-color: #FF2F46;
        color: white;
        border: none;
        border-radius: 10px;
    }

    .signup-plan-button:hover {cursor: pointer;}

    .form-input {
        display: block;
        margin: 15px auto;
        width: 85%;
        height: 30px;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        background-color: #232323;
        color: white;
        text-align: center;
        font-size: 15px;
    }

    /* .signup-footer {
        font-size: 10px;
        margin: 0 auto;
        padding-bottom: 50px;
        background-color: #232323;
    } */
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {

    .signup-table {
        background-color: #191919;
        color: white;
        width: 550px;
        margin: 25px auto;
        margin-bottom: 50px;
        padding: 25px;
        border-radius: 10px;
    }

    .signup-table-title {
        font-size: 30px;
        color: white;
        width: 100%;
        margin: 10px auto;
    }

    .signup-table-row-title {
        display: block;
        margin-top: 40px;
        width: 100%;
        text-align: center;
        font-size: 12px;

    }

    .signup-plan-button {
        display: block;
        width: 120px;
        height: 30px;
        margin: 0px auto;
        margin-top: 30px;
        background-color: #FF2F46;
        color: white;
        border: none;
        border-radius: 10px;
        font-size: 12px;
    }

    .signup-plan-button:hover {cursor: pointer;}

    .signup-table-apple {
        background-color: white;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        height: 35px;
    }

    .signup-table-google {
        background-color: #232323;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
    }

    .signup-table-facebook {
        background-color: #2B478D;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
    }

    .signup-table-twitter {
        background-color: #0096EE;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
    }

    .signup-table-email {
        background-color: #232323;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
    }

    .signup-table-apple:hover {cursor:pointer;}

    .signup-table-google:hover {cursor:pointer;}

    .signup-table-facebook:hover {cursor:pointer;}

    .signup-table-twitter:hover {cursor:pointer;}

    .signup-table-email:hover {cursor:pointer;}

    .signup-spacer {
        margin: 50px auto;
    }

    .form-input {
        display: block;
        margin: 15px auto;
        width: 85%;
        height: 30px;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        background-color: #232323;
        color: white;
        text-align: center;
        font-size: 15px;
    }

    .form-button {
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 85%;
    }

    .form-button {cursor: pointer}

    .signup-table-forgot-password {
        margin: 25px;
        color: #FF2F46
    }

    .signup-table-signup strong {
        color: #FF2F46;
    }

    /* .signup-footer {
        background-color: #232323;
        margin-top: 0px;
    } */

}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

/* @media only screen and (min-width: 992px) {} */
