.home-container {
    width: 100%;
    padding: 0px;
    /* padding-top: 25px; */
    padding-bottom: 500px;
    margin: 0px;
    /* font-family: "Poppins", "cursive"; */

}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.b {
    color: black;
}

.no-dec {
    text-decoration: none;
}

.hover:hover {
    border-bottom: 2px solid #FF2F46;
}

.no-pointer:hover {
    cursor: default;
}

.home-not-active {
    width: 95%;
    text-align: center;
    margin: 5px auto;
}

.home-feed-link {
    font-family: "Permanent Marker", "cursive";
    text-decoration: none;
    color: #FF2F46;
    font-size: 18px;
}

.home-feed-link:hover {
    text-decoration: underline;
}

.home-redirect-button {
    width: 55px;
    height: 26px;
    background-color:white;
    border: 1px solid #FF2F46;
    color: black;
    border-radius: 5px;
    text-align: center;
}

.home-redirect-button:hover {
    background-color:#FF2F46;
    color: white;
    cursor: pointer;
}

.launch-message-link:hover {
    border-bottom: 2px solid #FF2F46;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .td {display: none;}

    .d {display: none;}

    .tablet-view {display: none;}

    .home-header {
        display: block;
        width: 100%;
        height: 50px;
        background-color: white;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
    }

    .home-header-title {
        display: inline-block;
        vertical-align: top;
        width: 40%;
        padding: 0px 10px;
        margin-top: 10px;
    }

    .home-header-title:hover {cursor: default;}

    .home-header-items {
        list-style-type: none;
        width: 40%;
        float: right;  
        padding: 5px 10px;  
    }

    .home-header-items li {
        margin: 0px 5px;
        float: right;    
    }

    .launch-message {
        width: 90%;
        margin: 10px auto;
        margin-top: 60px;
        margin-bottom: -60px;
        text-align: center;
        font-size: 10px;
    }

    .home-create-event-message {
        width: 90%;
        margin: 0px auto;
        /* text-align: center; */
        font-size: 10px;
    }

    .home-message-dot {
        width: 10px;
        margin-bottom: 15px;
        margin-left: -10px;
    }

    .home-user-icon, .home-messages-icon {
        width: 18px;
        height: 18px;
        margin-right: 5px;
    }

    .home-greet {
        font-family: "Permanent Marker", "cursive";
        margin-left: 10px;
        margin-top: 75px;
    }

    .home-upcoming-container {
        width: 100%;
        margin: 0px;
    }

    .home-upcoming-title {
        font-family: "Permanent Marker", "cursive";
        margin-left: 10px;
    }

    .home-upcoming-statement {
        margin-left: 40px;
    }

    .home-available-container {
        width: 100%;
        height: 300px;
        display: block;
        margin: 0px;
        white-space: nowrap;
        overflow-x: scroll; 
        overflow-y: hidden; 
    }

    ::-webkit-scrollbar {
        width: 0px;
    }
      
    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #525252;
        opacity: .5;
    }

    .home-saved-container {
        width: 100%;
        height: 300px;
        display: block;
        margin: 0px;
        white-space: nowrap;
        overflow-x: scroll; 
        overflow-y: hidden; 
    }

    ::-webkit-scrollbar {
        width: 0px;
    }
      
    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #525252;
        opacity: .5;
    }

    .home-feed-container {
        width: 100%;
        display: block;
        text-align: center;
    }

    .home-feed {
        width: 90%;
        margin: 0px auto;
        margin-top: 30px;
    }

    .home-feed-date-time {
        margin-bottom: -10px;
        /* margin-left: 20px; */
    }

    .home-feed-stuff {
        /* margin-left: 20px; */
    }

    .home-available-title {
        font-family: "Permanent Marker", "cursive";
        margin-top: 50px;
        margin-left: 10px;
        margin-bottom: -20px;
    }

    .home-saved-title {
        font-family: "Permanent Marker", "cursive";
        margin-top: 20px;
        margin-left: 10px;
        margin-bottom: -10px;
        display: inline-block;
        vertical-align: top;
    }

    .home-feed-title {
        font-family: "Permanent Marker", "cursive";
        margin-top: 20px;
        margin-left: 10px;
        margin-bottom: -50px;
        display: inline-block;
        vertical-align: top;
    }

    .home-event-upcoming-box {
        border: #FF2F46 solid 2px;
        width: 97%;
        margin: 30px auto;
        text-align: center;
        border-radius: 10px;
    }

    .home-event-box {
        display: inline-block;
        vertical-align: top;
        border: #FF2F46 solid 2px;
        width: 225px;
        margin: 5px;
        text-align: center;
        border-radius: 10px;
    }

    .home-event-date {
        font-size: 10px;
    }

    .home-event-address {
        font-size: 10px;
    }

    .home-tab-icon {
        height: 10px;
        margin-left: 5px;
    }

    .home-event-address-link:hover {
        border-bottom: #FF2F46 solid 2px;
        cursor: pointer;
    }

    .home-event-title {
        font-family: "Permanent Marker", "cursive";
        width: 70%;
        font-size: 90%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: "center";
        margin:0px auto;
        display: inline-block;
    }

    .home-event-title:hover {
        cursor: pointer;
        -ms-transform: scale(1.2); /* IE 9 */
        -webkit-transform: scale(1.2); /* Safari 3-8 */
        transform: scale(1.2); 
    }

    .home-event-upcoming-address {
        font-size: 15px;
    }

    .home-event-address-link:hover {
        border-bottom: #FF2F46 solid 2px;
        cursor: pointer;
    }

    .home-event-upcoming-title {
        font-family: "Permanent Marker", "cursive";
        font-size: 25px;
        margin: 0px auto;
        width: 75%;
    }

    .home-event-upcoming-title:hover {
        cursor: pointer;
        -ms-transform: scale(1.2); /* IE 9 */
        -webkit-transform: scale(1.2); /* Safari 3-8 */
        transform: scale(1.2); 
    }

    .home-event-upcoming-going {
        font-size: 15px;
        margin: 20px auto;
    }

    .home-event-going {
        font-size: 10px;
    }

    .home-event-button {
        width: 100%;
        height: 40px;
        border: none;
        color: white;
        background-color: #FF2F46;
        opacity: 1;
        border-radius: 3px;
        font-size: 15px;
    }

    .home-event-button-private {
        width: 100%;
        height: 40px;
        border: none;
        color: white;
        background-color: #FF2F46;
        opacity: .5;
        border-radius: 3px;
        font-size: 15px;
    }

    .home-event-button-private:hover {
        cursor: not-allowed;
    }

    .home-event-button:hover {
        cursor: pointer;
        opacity: .7;
    }

    .back-to-top-container {
        margin-top: 50px;
        width: 100%;
   }

    .back-to-top {
        text-decoration: none;
    }

    .home-profiles-container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-bottom: -25px;
    }

    .home-rider-recommendations-container {
        margin: 35px auto;
        text-align: center;
    }

    .home-rider-recommendations-title {
        font-size: 12px;
    }

    .home-rider-profile-recommendations-box {        
        padding: 3px;
        width: 75px;
        margin: 5px 5px;
    }

    .home-rider-profile-image {
        width: 50px;
        border-radius: 50%;
        height: 50px;
    }

    .home-rider-profile-name {
        font-family: "Permanent Marker", "cursive";
        margin-top: -5px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 12px;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .mobile-nav-bar, .mobile {display: none}

    .d {display: none}

    .home-header {
        display: block;
        width: 100%;
        height: 50px;
        background-color: white;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
    }

    .home-header-title {
        display: inline-block;
        vertical-align: top;
        width: 20%;
        padding: 0px 10px;
        margin-top: 10px;
    }

    .home-header-title:hover {cursor: default;}

    .home-header-items {
        list-style-type: none;
        width: 72%;
        float: right;  
        padding: 5px 10px;
        padding-left: 0px;
        font-size: 12px;
    }

    .home-header-items li {
        margin: 2px 10px;
        float: right;    
    }

    .home-header-items li:hover {
        border-bottom: solid #FF2F46 2px;
    }

    .launch-message {
        width: 100%;
        margin: 10px auto;
        text-align: center;
    }

    .home-create-event-message {
        width: 90%;
        margin: 0px auto;
        margin-top: 20px;
        text-align: center;
        font-size: 10px;
    }

    .home-user-icon, .home-messages-icon {
        width: 18px;
        height: 18px;
        margin-right: 5px;
    }

    .home-message-dot {
        width: 10px;
        margin: 0px;
        margin-left: -4px;
    }

    .home-upcoming-container {
        width: 100%;
        height: 310px;
        margin: 0px;
        margin-top: 75px;
        background-color: #FF2F46;
    }

    .home-greet {
        font-family: "Permanent Marker", "cursive";
        margin-bottom: 0px;
        width: 80%;
        padding-top: 20px;
        padding-left: 20px;
    }

    .home-upcoming-title {
        margin-top: 0px;
        padding-left: 20px;
        padding-bottom: 15px;
        width: 80%;
        display: inline-block;
    }
    
    .home-title-short {
        margin-top: 20px;
        font-size: 22px;
    }

    .home-upcoming-border {
        color: white;
        width: 93%;
        margin: 0px auto;
    }

    .home-upcoming-statement-td {
        margin-left: 40px;
        text-align: center;
        margin-top: 50px;
    }

    .home-available-container {
        width: 100%;
        height: 300px;
        display: block;
        margin: 0px;
        white-space: nowrap;
        overflow-x: scroll; 
        overflow-y: hidden; 
    }

    ::-webkit-scrollbar {
        width: 0px;
    }
      
    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #525252;
        opacity: .5;
    }

    .home-saved-container {
        width: 100%;
        height: 300px;
        display: block;
        margin: 0px;
        white-space: nowrap;
        overflow-x: scroll; 
        overflow-y: hidden; 
    }

    ::-webkit-scrollbar {
        width: 0px;
    }
      
    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #525252;
        opacity: .5;
    }

    .home-feed-container {
        width: 100%;
        display: block;
    }

    .home-feed {
        width: 100%;
        margin-top: 30px;
    }

    .home-feed-date-time {
        margin-bottom: -10px;
        margin-left: 20px;
    }

    .home-feed-stuff {
        margin-left: 20px;
    }

    .home-available-title {
        font-family: "Permanent Marker", "cursive";
        margin-top: 30px;
        margin-left: 10px;
        margin-bottom: -10px;
        font-size: 20px;
        display: inline-block;
        vertical-align: top;
    }

    .home-saved-title {
        font-family: "Permanent Marker", "cursive";
        margin-top: 30px;
        margin-left: 10px;
        margin-bottom: -10px;
        font-size: 20px;
        display: inline-block;
        vertical-align: top;
    }

    .home-feed-title {
        font-family: "Permanent Marker", "cursive";
        margin-top: 30px;
        margin-left: 10px;
        margin-bottom: -50px;
        font-size: 20px;
        display: inline-block;
        vertical-align: top;
    }

    .title-attending-section {
        display: inline-block;
        vertical-align: top;
        width: 40%;
        height: 150px;
        margin-left: 20px;
        margin-top: 20px;
    }

    .title-attending-section h1 {
        margin-top: 10px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .title-attending-section a:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    .title-attending-section p {
        margin: 10px 0px;
        width: 220px;
    }

    .date-address-section {
        display: inline-block;
        vertical-align: top;
        width: 40%;
        height: 150px;
        margin-left: 20px;
    }

    .date-address-section a:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    .home-event-box {
        display: inline-block;
        vertical-align: top;
        border: #FF2F46 solid 2px;
        width: 300px;
        margin: 5px;
        text-align: center;
        border-radius: 10px;
    }

    .home-event-date {
        font-size: 10px;
    }

    .home-event-address {
        font-size: 10px;
    }

    .home-tab-icon {
        height: 10px;
        margin-left: 5px;
    }

    .home-event-address-link:hover {
        border-bottom: #FF2F46 solid 2px;
        cursor: pointer;
    }

    .home-event-title {
        font-family: "Permanent Marker", "cursive";
        width: 70%;
        font-size: 90%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: "center";
        margin:0px auto;
        display: inline-block;
    }

    .home-event-title:hover {
        cursor: pointer;
        -ms-transform: scale(1.2); /* IE 9 */
        -webkit-transform: scale(1.2); /* Safari 3-8 */
        transform: scale(1.2); 
    }

    .home-event-going {
        font-size: 10px;
    }

    .home-event-button {
        width: 100%;
        height: 40px;
        border: none;
        color: white;
        background-color: #FF2F46;
        opacity: 1;
        border-radius: 3px;
        font-size: 15px;
    }

    .home-event-button-private {
        width: 100%;
        height: 40px;
        border: none;
        color: white;
        background-color: #FF2F46;
        opacity: .5;
        border-radius: 3px;
        font-size: 15px;
    }

    .home-event-button-private:hover {
        cursor: not-allowed;
    }

    .home-event-button:hover {
        cursor: pointer;
        opacity: .7;
    }

    .back-to-top-container {
        margin-top: 50px;
        width: 100%;
        text-align: center;
    }

    .home-go-explore {
        margin-left: 20px;
        margin-top: 38px;
        margin-bottom: 0px;
        text-decoration: none;
        display: inline-block;
        vertical-align: middle;
        font-size: 12px;
    }

    .home-go-explore:hover {
        border-bottom: solid #FF2F46 1px;
    }

    .home-no-data {
        text-align: center;
    }

    .home-profiles-container {
        display: block;
        width: 600px;
        margin: 10px auto;
        /* flex-direction: row;
        justify-content: space-evenly; */
    }

    .home-rider-recommendations-container {
        margin: 35px auto;
        text-align: center;
    }

    .home-rider-recommendations-title {
        font-size: 12px;
        cursor: default;
    }

    .home-rider-profile-recommendations-box {    
        display: inline-block;    
        padding: 3px;
        width: 100px;
        margin: 5px 5px;
    }

    .home-rider-profile-image {
        width: 75px;
        border-radius: 50%;
        height: 75px;
    }

    .home-rider-profile-name {
        font-family: "Permanent Marker", "cursive";
        margin-top: -5px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 15px;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .mobile-nav-bar {display: none;}

    .tablet-view {display: none;}
    
    .d {display: inline-block;}

    .title-attending-section {
        width: 350px;
        margin-right: 20px;
    }

    .title-attending-section p {
        margin: 10px 0px;
        width: 220px;
    }

    .home-upcoming-container {
        width: 100%;
        height: 310px;
        margin: 0px;
        background-color: #FF2F46;
        margin-top: 75px;
    }

    .home-road-icon {
        margin: auto;
        margin-top: 15px;
        width: 150px;
    }

    .date-address-section {
        width: 300px;
        margin-left: 75px;
    }

    .home-available-container {
        width: 100%;
        height: 300px;
        display: block;
        margin: 0px;
        white-space: nowrap;
        overflow-x: scroll; 
        overflow-y: hidden; 
    }

    ::-webkit-scrollbar {
        width: 0px;
    }
      
    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #525252;
        opacity: .5;
    }

    .home-saved-container {
        width: 100%;
        height: 300px;
        display: block;
        margin: 0px;
        white-space: nowrap;
        overflow-x: scroll; 
        overflow-y: hidden; 
    }


    ::-webkit-scrollbar {
        width: 0px;
    }
      
    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #525252;
        opacity: .5;
    }

    .home-feed-container {
        width: 100%;
        display: block;
    }

    .home-feed {
        width: 100%;
        text-align: center;
        margin-top: 50px;
    }

    .home-feed-date-time {
        margin-bottom: -10px;
        margin-left: 20px;
    }

    .home-feed-stuff {
        margin-left: 20px;
    }

    .home-event-box {
        border: #FF2F46 solid 2px;
        width: 300px;
        margin: 10px;
        margin-bottom: 20px;
        border-radius: 10px;
    }

    .home-go-explore {
        text-decoration: none;
    }

    .home-go-explore:hover {
        border-bottom: solid #FF2F46 1px;
    }

    .home-no-data {
        text-align: centerzc;
    }
}
