.rider-posts-container {
    width: 90%;
    padding: 0px;
    margin: 10px auto;
}

.rider-posts-post:hover {
cursor: pointer;
}
  
.rider-posts-signin-button {
    width: 260px;
    height: 30px;
    border: none;
    color: white;
    background-color: #FF2F46;
    border-radius: 5px;
    margin: 20px;
}

.rider-posts-signin-button:hover {
    cursor: pointer;
}

.margin-up {
    margin-top: 200px;
}

.rider-detail-socials-container {
    margin: 20px auto;
}

.rider-detail-socials-icon {
    width: 15px;
    margin-right: 10px;
    margin-top: 5px;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .rider-posts-statement-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }

    .rider-posts-component-message-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }
    
    .rider-posts-statement {
        font-family: "Poppins", "cursive";
        text-align: center;
        width: 100%;
        margin: 10px auto;
    }

    .rider-posts-upload {
        display: block;
        margin: 0px auto;
        margin-top:  20px;
        /* margin-bottom: 40px; */
        padding-top: 5px;
        text-align: center;
        background-color: #FF2F46;
        border-radius: 5px;
        color: white;
        height: 25px;
        width: 200px;
        font-size: 12px;
    }

    .rider-posts-post-container {
        column-count: 2;
        column-gap: 15px;
        padding: 10px;
        padding-bottom: 250px;
        margin-top: 40px;
    }

    .rider-posts-post {
        margin: 0;
        display: grid;
        grid-template-rows: 1fr auto;
        break-inside: avoid;
        color: black;
    }

    .rider-posts-post > .rider-posts-image {
        grid-row: 1 / -1;
        grid-column: 1;
    }

    .rider-posts-image {
        max-width: 100%;
        display: block;
        border-radius: 10px;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        margin: 15px auto;
    }

    .rider-posts-info {
        color: black;
        margin: 0px auto;
        margin-bottom: 10px;
        width: 90%;
      }

    .rider-posts-signin-redirect {
        text-align: center;
        margin: 10px auto;
        margin-top: -200px;
    }

    /* **************MODAL STUFF BELOW************** */
    
    .popup-content {
        margin: auto;
        background: rgb(255, 255, 255);
        width: 85%;
        padding: 5px;
        text-align: center;
    }

    .rider-posts-post-pop-up {
        /* width: 720px; */
        height: 700px;
        background-color: black;
        padding: 0px;
        overflow:scroll;
        text-align: center;
        align-content: center;
        border-radius: 10px;
        background-color: transparent;
        margin: auto;
    
      }
    
      .rider-posts-image-test {
        width: 85%;
        max-height: 100%;
        border-radius: 10px;
        display: inline-block;
        vertical-align: middle;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
      }
    
    .popup-content {
        padding: 0px;
        border-radius: 10px;
        background-color: transparent;
    }
    
    .modal {
        font-size: 12px;
    }
    
    .modal > .close {
        cursor: pointer;
        position: absolute;
        display: block;
        padding: 2px 5px;
        line-height: 20px;
        right: 15px;
        top: -15px;
        font-size: 24px;
        background: #FF2F46;
        border-radius: 18px;
        border: 2px solid #FF2F46;
        color: white
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .rider-posts-statement-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }
    
    .rider-posts-statement {
        font-family: "Poppins", "cursive";
        text-align: center;
    }

    .rider-posts-upload {
        display: block;
        margin: 0px auto;
        margin-top:  20px;
        /* margin-bottom: 40px; */
        padding-top: 5px;
        text-align: center;
        background-color: #FF2F46;
        border-radius: 5px;
        color: white;
        height: 25px;
        width: 200px;
        font-size: 12px;
    }

    .rider-posts-post-container {
        column-count: 3;
        column-gap: 15px;
        padding: 10px;
        margin-top: 10px;
        width: 100%
    }

    .rider-posts-post {
        margin: 0;
        display: grid;
        grid-template-rows: 1fr auto;
        /* margin-bottom: 20px; */
        break-inside: avoid;
        color: black;
    }

    .rider-posts-post > .rider-posts-image {
        grid-row: 1 / -1;
        grid-column: 1;
    }

    .rider-posts-image {
        max-width: 100%;
        display: block;
        border-radius: 10px;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        margin: 20px auto;
    }

    div.fileContainer div.errorsContainer {
        overflow-wrap: break-word;
        max-width: 100%;
    }

    .rider-posts-signin-redirect {
        text-align: center;
        margin: 50px auto;
        margin-bottom: 100px;
    }

    /* **************MODAL STUFF BELOW************** */

    .popup-content {
        margin: auto;
        background: rgb(255, 255, 255);
        max-width: 40%;
    }

    .rider-posts-post-pop-up {
        /* width: 720px; */
        height: 700px;
        background-color: black;
        padding: 0px;
        overflow:scroll;
        text-align: center;
        align-content: center;
        border-radius: 10px;
        background-color: transparent;
        margin: auto;
    
      }
    
      .rider-posts-image-test {
        max-width: 100%;
        max-height: 100%;
        border-radius: 10px;
        display: inline-block;
        vertical-align: middle;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
      }
    
    .popup-content {
        padding: 0px;
        border-radius: 10px;
        background-color: transparent;
    }
    
    .modal {
        font-size: 12px;
    }
    
    .modal > .close {
        cursor: pointer;
        position: absolute;
        display: block;
        padding: 2px 5px;
        line-height: 20px;
        right: -10px;
        top: -10px;
        font-size: 24px;
        background: #FF2F46;
        border-radius: 18px;
        border: 2px solid #FF2F46;
        color: white
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}