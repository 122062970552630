.rider-tip-jar-overview-statement {
    width: 90%;
    margin: 20px auto;
    margin-top: 25px;
    font-size: 12px;
}

.rider-tip-jar-guide-link:hover {
    color: #FF2F46;
    text-decoration: underline;
}

.rider-tip-jar-enable-button-container {
    width: 100%;
    text-align: center;
    margin-top: 45px;
}

.rider-tip-jar-enable-button {
    background-color: #FF2F46;
    color: white;
    border: none;
    border-radius: 5px;
    height: 30px;
    margin: 10px auto;
}

.rider-tip-jar-enable-button:hover {
    cursor: pointer;
}

.rider-tip-jar-details {
    width: 95%;
    margin: 50px auto;
    margin-top: 50px;
    margin-bottom: 200px;
}

.rider-tip-jar-total {
    margin: 0px auto;
}

.rider-tip-jar-cashout-button {
    background-color: #FF2F46;
    color: white;
    border: #FF2F46 solid 1px;
    border-radius: 5px;
    height: 25px;
    width: 120px;
    margin-top: 10px;
}

.rider-tip-jar-cashout-button:hover {
    cursor: pointer;
}

.rider-tip-jar-disable-button {
    background-color: white;
    color: #FF2F46;
    border: #FF2F46 solid 1px;
    border-radius: 5px;
    height: 25px;
    width: 120px;
    margin-top: 10px;
    display: block;
}

.rider-tip-jar-disable-button-confirm {
    background-color: #FF2F46;
    color: white;
    border: #FF2F46 solid 1px;
    border-radius: 5px;
    height: 25px;
    width: 120px;
    margin-top: 10px;
    display: block;
}

.rider-tip-jar-disable-button-confirm:hover {
    cursor: pointer;
}

.rider-tip-jar-disable-button:hover {
    cursor: pointer;
    background-color: #FF2F46;
    color: white;
}

.rider-tip-jar-no-tip-statement {
    margin: 10px auto;
    margin-top: 50px;
    font-size: 12px;
    width: 90%;
}

.rider-tip-jar-disable-statement {
    font-size: 12px;
}

.rider-tip-jar-tipper-container {
    width: 100%;
    margin-top: 50px;
}

.rider-tip-jar-tipper {
    margin-top: 20px;
    margin-left: 10px;
}
/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .rider-tip-jar-enable-button {
        width: 50%;
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) { 
    .rider-tip-jar-enable-button {
        width: 300px;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}