.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.no-dec {
    text-decoration: none;
}

.error-message {
    margin-top: -30px;
}

.g_error-message {
    margin-top: 30px;
}

.signin-logo {
    margin: 0px auto;
    width: 100px;
}

.signin-spacer {
    font-family: "Poppins", "cursive";
    color: white;
    margin-bottom: 55px;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .signin-container {
        width: 100%;
        height: 100vh;
        padding: 0px;
        padding-top: 25px;
        padding-bottom: 100px;
        margin: 0px;
        text-align: center;
        overflow: auto;
        background-color: #232323;
    }

    .signin-box {
        background-color: #191919;
        color: #FF2F46;
        width: 80%;
        margin: 25px auto;
        margin-bottom: -20px;
        padding: 25px;
        border-radius: 10px;
    }

    .signin-box-title {
        font-family: "Permanent Marker", "cursive";
        font-size: 35px;
        margin: 0px;
    }

    .signin-box-quote {
        font-family: "Poppins", "cursive";
    }

    .signin-with-google-container {
        width: 250px;
        margin: 0px auto;
        text-align: center;
    }

    .signin-box-google {
        background-color: #232323;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
    }

    .signin-form-input {
        display: block;
        margin: 15px auto;
        width: 250px;
        height: 30px;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        background-color: #232323;
        color: white;
        text-align: center;
        font-size: 15px;
    }

    .signin-form-button {
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 250px;
        font-family: "Poppins", "cursive";
        margin: auto;
    }

    .signin-form-button:hover {cursor: pointer}

    .signin-box-forgot-password {
        font-family: "Poppins", "cursive";
        margin: 25px 0px;
        color: #FF2F46
    }

    .signin-box-signup {
        font-family: "Poppins", "cursive";
    }

    .signin-box-signup strong {
        color: #FF2F46;
    }

    .signin-footer {
        font-family: "Poppins", "cursive";
        font-size: 10px;
        margin: 0 auto;
        margin-top: 50px;
        /* padding-top: 100px; */
        height: 50vw;
        background-color: #232323;
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .signin-container {
        width: 100%;
        height: 100hh;
        padding: 0px;
        padding-top: 25px;
        padding-bottom: 25px;
        margin: 0px;
        text-align: center;
        background-color: #232323;
        overflow: auto;

    }

    .signin-box {
        background-color: #191919;
        color: white;
        width: 550px;
        margin: 25px auto;
        margin-bottom: -50px;
        padding: 25px;
        border-radius: 10px;
        overflow: auto;

    }

    .signin-box-title {
        font-family: "Permanent Marker", "cursive";
        font-size: 35px;
        margin: 0px;
    }

    .signin-box-quote {
        font-family: "Poppins", "cursive";
    }

    .signin-with-google-container {
        width: 250px;
        margin: 0px auto;
        text-align: center;
    }

    .signin-form-input {
        display: block;
        margin: 15px auto;
        width: 300px;
        height: 30px;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        background-color: #232323;
        color: white;
        text-align: center;
        font-size: 15px;
    }

    .signin-form-button {
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 310px;
        font-family: "Poppins", "cursive";
    }

    .signin-form-button:hover {cursor: pointer}

    .signin-box-forgot-password {
        font-family: "Poppins", "cursive";
        margin: 25px 0px;
        padding: 0px;
        color: #FF2F46
    }

    .signin-box-signup {
        font-family: "Poppins", "cursive";
    }

    .signin-box-signup strong {
        color: #FF2F46;
    }

    .signin-footer {
        font-family: "Poppins", "cursive";
        background-color: #232323;
        margin: 0px auto;
        padding-top: 50px;
        margin-top: 70px;
        height: 50vh;
    }

}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    /* .signin-container {
        overflow: inherit;
        height: 100vh;
    } */

    /* .signin-box {
        background-color: #191919;
        color: white;
        width: 550px;
        margin: 25px auto;
        margin-bottom: -20px;
        padding: 25px;
        border-radius: 10px;
    } */

    /* .signin-footer {
        font-family: "Poppins", cursive;
        background-color: #232323;
        padding-top: 50px;
        height: 50vh;
        margin: 0px;
    } */
}