.search-rider-container {
    padding-bottom: 100px;
}

.search-rider-title {
    margin-top: 75px;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .search-rider-form-input {
        display: block;
        margin: 20px auto;
        width: 85%;
        height: 30px;
        border: solid 2px #FF2F46;
        border-radius: 5px;
        background-color: white;
        color: black;
        text-align: center;
        font-size: 15px;
        font-family: "Poppins", "cursive";

    }
    
    .search-rider-form-button {
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 85%;
        font-family: "Poppins", "cursive";
    }
    
    .search-rider-form-button {cursor: pointer}
    .search-rider-title {
        font-family: "Permanent Marker", "cursive";
        width: 100%;
        text-align: center;
    }

    .search-rider-zero-found {
        width: 100%;
        text-align: center;
        margin-top: 100px;
    }

    .search-rider-user-icons-container {
        width: 100%;
        padding: 0px;
        text-align: center;
    }

    .search-rider-profile-container-user {
        width: 75px;
        margin: 30px;
        display: inline-block;
        vertical-align: top;
        font-size: 15px;
    }

    .search-rider-profile-container-user {
        text-align: center;
    }

    .search-rider-user-icon-link {
        text-decoration: none;
        padding: 0px;
    }

    .search-rider-user-icon {
        padding: 0px;
        margin: 0px;
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }

    .search-rider-text-name-user {
        width: 100%;
        margin: 0px;
        font-family: "Permanent Marker", "cursive";
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .search-rider-form-input {
        display: block;
        margin: 20px auto;
        width: 400px;
        height: 30px;
        border: solid 2px #FF2F46;
        border-radius: 5px;
        background-color: white;
        color: black;
        text-align: center;
        font-size: 15px;
        font-family: "Poppins", "cursive";
    }
    
    .search-rider-form-button {
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 400px;
        font-family: "Poppins", "cursive";
    }
    
    .search-rider-form-button {cursor: pointer}
    .search-rider-title {
        font-family: "Permanent Marker", "cursive";
        width: 100%;
        text-align: center;
    }

    .search-rider-zero-found {
        width: 100%;
        text-align: center;
        margin-top: 100px;
    }

    .search-rider-user-icons-container {
        width: 100%;
        padding: 0px;
        text-align: center;
    }

    .search-rider-profile-container-user {
        width: 75px;
        margin: 30px;
        display: inline-block;
        vertical-align: top;
    }

    .search-rider-profile-container-user {
        text-align: center;
    }

    .search-rider-user-icon-link {
        text-decoration: none;
        padding: 0px;
    }

    .search-rider-user-icon {
        padding: 0px;
        margin: 0px;
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }

    .search-rider-text-name-user {
        width: 100%;
        margin: 0px;
        font-family: "Permanent Marker", "cursive";
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .search-rider-user-icons-container {
        width: 1000px;
        padding: 0px;
        text-align: center;
        margin: 0px auto;
    }
}
