.create-event-container {
    width: 100%;
    padding: 0px;
    /* padding-top: 25px; */
    padding-bottom: 200px;
    margin: 0px;
    /* background-color: #232323; */
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.b {
    color: black;
}

.no-dec {
    text-decoration: none;
}

.create-event-privacy-select {
    width: 90px;
    padding: 5px;
    border-radius: 5px;
    font-size: 15px;
    margin: 15px auto;
}

.create-event-privacy-select:hover {
    cursor: pointer;
}

.create-event-select {
    appearance: none;
    width: 100px;
    padding: 5px;
    border-radius: 5px;
    font-size: 15px;
    margin: 15px auto;
    text-align: center;
}

.create-event-datepicker-container {
    width: 400px;
    margin: auto;
}

.create-event-form-error-message {
    color: #FF2F46;
    font-family: "Poppins", "cursive";
    margin: 0px auto;
}

.react-datepicker__input-container {
    margin: 0px auto;
    margin-left: -7px;
}

.create-event-form-meet-location-hint {
    font-family: "Poppins", "cursive";
    margin: 0px auto;
    font-size: 10px;
}

.create-event-form-input-external-link {
    width: 100%;
    height: 30px;
    margin: 5px auto;
    text-align: center;
    display: block;
    background-color: white;
    color: black;
    border: solid 1px #FF2F46;
    border-radius: 5px;
    font-size: 15px;
    font-family: "Poppins", "cursive";
}


/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {

    .create-event-title-container {
        width: 100%;
        text-align: center;
        margin-bottom: 25px;
        margin-top: 75px;
    }

    .create-event-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .create-event-form {
        width: 100%;
        text-align: center;
    }

    .create-event-form-input {
        width: 404px;
        height: 30px;
        margin: 5px auto;
        text-align: center;
        display: block;
        background-color: white;
        color: black;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
        white-space: pre-wrap;
    }

    .text-box {
        width: 400px;
        height: 100px;
        resize: none;
        white-space: pre-wrap;
    }

    .create-event-form-input-loc-container {
        overflow:hidden;
        margin: auto;
    }

    .create-event-form-button {
        background-color: #FF2F46;
        color: white;
        width: 400px;
        height: 35px;
        margin: 5px auto;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
        cursor: pointer;
    }

    .create-event-options-switch-title {
        width: 100%;
        margin: 0px auto;
        margin-top: 50px;
        text-align: center;
    }

    .create-event-switches {
        display: flex;
        justify-content: space-evenly;
        width: 400px;
        margin: 0px auto;

    }

    .create-event-switch {
        width: 150px;
    }

    .create-event-user-invitees {
        margin: 0px auto;
    }

    .create-event-user-invitees p {
        display: inline-block;
        width: 30%;
        margin: 5px;
        margin-top: 20px;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .create-event-title-container {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        margin-top: 75px;
    }

    .create-event-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .create-event-form {
        width: 100%;
        text-align: center;
    }

    .create-event-form-input {
        width: 400px;
        height: 30px;
        margin: 5px auto;
        text-align: center;
        display: block;
        background-color: white;
        color: black;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
        white-space: pre-wrap;
    }

    .text-box {
        height: 100px;
        resize: none;
        white-space: pre-wrap;
    }

    .create-event-form-button {
        background-color: #FF2F46;
        color: white;
        width: 400px;
        height: 35px;
        margin: 5px auto;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
        cursor: pointer;
    }

    .create-event-options-switch-title {
        width: 100%;
        margin: 0px auto;
        margin-top: 50px;
        text-align: center;
    }

    .create-event-switches {
        display: flex;
        justify-content: space-evenly;
        width: 400px;
        margin: 0px auto;

    }

    .create-event-switch {
        width: 150px;
    }

    .create-event-user-invitees-container {
        width: 650px;
        margin: auto;
    }

    .create-event-user-invitees {
        margin: 0px auto;
    }

    .create-event-user-invitees p {
        display: inline-block;
        width: 30%;
        margin: 10px auto;
        margin-top: 20px;
    }
    
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}

/* // this.handlePlaceSelect = this.handlePlaceSelect.bind(this)
// this.handleChange = this.handleChange.bind(this)
// this.handleSubmit = this.handleSubmit.bind(this)

/* handleChange(event) {
    this.setState({[event.target.name]: event.target.value})
}

handleSubmit(event) {
event.preventDefault()
this.props.dispatch(addParlor(this.state))
this.clearForm()
}

handlePlaceSelect() {
    let addressObject = this.autocomplete.getPlace()
    let address = addressObject.address_components
    this.setState({
        name: addressObject.name,
        street_address: `${address[0].long_name} ${address[1].long_name}`,
        city: address[4].long_name,
        state: address[6].short_name,
        zip_code: address[8].short_name,
        googleMapLink: addressObject.url
    })
} */


/* <div>

    <form onSubmit={this.handleSubmit}>

        <input id="autocomplete"
            className="input-field"
            ref="input"
            type="text"
        />

        <input 
            name={"name"}
            value={this.state.name}
            placeholder={"Name"}
            onChange={this.handleChange}
        />

        <input 
            name={"street_address"}
            value={this.state.street_address}
            placeholder={"Street Address"}
            onChange={this.handleChange}
        />

        <input 
            name={"city"}
            value={this.state.city}
            placeholder={"City"}
            onChange={this.handleChange}
        />

        <input
            name={"state"}
            value={this.state.state}
            placeholder={"State"}
            onChange={this.handleChange}
        />
        
        <input 
            name={"zip_code"}
            value={this.state.zip_code}
            placeholder={"Zipcode"}
            onChange={this.handleChange}
        />
        
        <button onSubmit={this.handleSubmit}>Submit</button>

    </form>
</div> */

/* 

[
    {
        long_name: "1714", 
        short_name: "1714", 
        types: [
            "street_number"
        ]
    }, 
    
    {
        long_name: "Nandina Way", 
        short_name: "Nandina Way", 
        types: [
            "route"
        ]
    }, 
    
    {
        long_name: "Antioch", 
        short_name: "Antioch", 
        types: [
            "locality", 
            "political"
        ]
    },
    
    {
        long_name: "Contra Costa County", 
        short_name: "Contra Costa County", 
        types: [
            "administrative_area_level_2", 
            "political"
        ]
    }, 
    
    {
        long_name: "California", 
        short_name: "CA", 
        types: [
            "administrative_area_level_1", 
            "political"
        ]
    }, 
    
    {
        long_name: "United States", 
        short_name: "US", 
        types: [
            "country", 
            "political"
        ]
    },
    
    {
        long_name: "94531", 
        short_name: "94531", 
        types: [
            "postal_code"
        ]
    }, 
    
    {
        long_name: "7409", 
        short_name: "7409", 
        types: [
            "postal_code_suffix"
        ]
    }
]


*/

