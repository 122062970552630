.about-section {
    width: 90%;
    padding: 10px;
    text-align: center;
    margin: auto;
    margin-bottom: 200px;
}

.about-button {
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
    margin-top: 30px;
}

.about-button {cursor: pointer}

.about-tag {
    margin-top: 50px;
}

.about-follow-icons {
    width: 100%;
}

.about-social-icon-link {
    margin: 8px;
}

.about-social-icon {
    height: 35px;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .about-title-container {
        width: 100%;
        text-align: center;
        margin: auto;
        margin-top: 75px;
    }

    .about-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
        margin-top: 75px;
        width: 100%;
    }

    .about-section {
        text-align: center;
    }

    .about-header {
        margin-top: 75px;
    }

    .about-content {
        width: 100%;
        margin: auto;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .about-title-container {
        width: 400px;
        text-align: center;
        margin: auto;
        margin-top: 75px;
    }

    .about-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
    }

    .about-button {
        width: 400px;
    }

    .about-header {
        margin-top: 80px;
    }

    .about-content {
        width: 400px;
        margin: auto;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .about-title-container {
        width: 500px;
        text-align: center;
        margin: auto;
        margin-top: 75px;
    }

    .about-header {
        margin-top: 85px;
    }

    .about-content {
        width: 500px;
        margin: auto;
    }
}