.home-container {
    width: 100%;
    padding: 0px;
    padding-bottom: 500px;
    margin: 0px;
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.b {
    color: black;
}

.no-dec {
    text-decoration: none;
}

.font-1 {
    font-family: "Permanent Marker", "cursive";
}

.font-2 {
    font-family: "Poppins", "cursive";
}

.my-profile-image-container-s3 {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow:hidden;
    background-size: cover;
    background-position: center center;
    margin: auto;
}

.profile-pic-info-items {
    width: 100%;
    margin: 0px;
    display: inline-block;
    vertical-align:middle;
    margin-top: 75px;
}

.profile-pic-item {
    display: inline-block;
    vertical-align:middle;
    width: 100px;
    margin: 20px 0px 0px 20px;
}

.profile-admin-view {
    margin-top: 55px;
    background-color: #FF2F46;
    width: 100%;
    color: white;
    text-align: center;
    font-size: 12px;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {

    .profile-pic-info-items {
        width: 100%;
        margin: 0px;
        display: block;
        margin-top: 75px;
        text-align: center;
    }

    .profile-pic-item {
        display: block;
        width: 150px;
        margin: 0px auto;
    }

    .profile-info-items {
        display: inline-block;
        vertical-align: middle;
        width: 270px;
        margin: 20px;
        margin-bottom: 10px;
        margin-right: 30px;
        font-family: "Poppins", "cursive";
    }

    .profile-name-item {
        margin: 0px;
    }
    

    .profile-detail-item {
        margin: 0px;
    }

    .profile-message-status {
        width: 80%;
        margin: 10px auto;
        text-align: center;
    }

    .profile-follow-info {
        width: 100%;
        text-align: center;
        margin-top: 25px;
    }

    .profile-followers-link, .profile-following-link {
        margin: 20px;
        text-decoration: none;
        font-family: "Poppins", "cursive";
        font-size: 15px;
    }

    .profile-followers-link:hover, .profile-following-link:hover {
        border-bottom: 2px #FF2F46 solid;
    }

    .my-profile-button-container {
        width: 100%;
        margin-top: 25px;
        text-align: center;
    }

    .my-profile-button {
        width: 45%;
        height: 30px;
        margin: 5px;
        border: none;
        color: white;
        background-color: #FF2F46;
        border-radius: 5px;
        margin-bottom: 10px;
        display: inline-block;
        vertical-align: top;
        font-size: 12px;
    }

    .my-profile-button:hover {
        cursor: pointer;
    }

    .profile-tab-container {
        margin: 25px auto;
        width: 100%;   
        text-align: center;     
    }

    .profile-attending-tab, .profile-hosting-tab, .profile-posts-tab {
        display: inline-block;
        margin: 10px 9px;
    }

    .profile-attending-tab, .profile-hosting-tab, .profile-posts-tab:hover {
        cursor: pointer;
    }

    /* .profile-attending-tab {}

    .profile-hosting-tab {}

    .profile-posts-tab {} */
        
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .profile-pic-info-items {
        width: 100%;
        margin: 0px;
        margin-top: 10px;
        vertical-align:middle;
        margin-top: 75px;
    }

    .profile-pic-item {
        display: inline-block;
        vertical-align:middle;
        width: 110px;
        margin: 20px 0px 0px 20px;
    }

    .profile-info-items {
        display: inline-block;
        vertical-align:middle;
        width: 270px;
        margin: 20px;
        font-family: "Poppins", "cursive";

    }

    .profile-name-item {
        margin: 0px;
    }
    

    .profile-detail-item {
        margin: 0px;
    }

    .profile-message-status {
        width: 80%;
        margin: 10px auto;
        text-align: center;
    }

    .profile-follow-info {
        width: 100%;
        text-align: center;
        margin-top: 25px;
    }

    .profile-followers-link, .profile-following-link {
        margin: 20px;
        text-decoration: none;
        font-family: "Poppins", "cursive";
        font-size: 15px;
    }

    .profile-followers-link:hover, .profile-following-link:hover {
        border-bottom: 2px #FF2F46 solid;
    }

    .my-profile-button-container {
        width: 600px;
        margin: 10px auto;;
        text-align: center;
        margin-top: 25px;
    }

    .my-profile-button {
        width: 250px;
        height: 30px;
        margin: 5px;
        border: none;
        color: white;
        background-color: #FF2F46;
        border-radius: 5px;
        display: inline-block;
        vertical-align: top;
    }

    .my-profile-button:hover {
        cursor: pointer;
    }

    .profile-tab-container {
        margin: 25px auto;
        width: 100%;   
        text-align: center;     
    }

    .profile-attending-tab, .profile-hosting-tab, .profile-posts-tab {
        display: inline-block;
        margin: 10px 25px;
    }

    .profile-attending-tab, .profile-hosting-tab, .profile-posts-tab:hover {
        cursor: pointer;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .profile-pic-info-items {
        width: 1000px;
        margin: 0 auto;
        margin-top: 75px;
        display: block;
    }

    .profile-active-tab {
        width: 1000px;
        margin: auto;
    }
}