.container {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.welcome-footer-follow-soon-icon {
    opacity: .7;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {

    .mobile-hide {
        display: none;
    }

    .welcome-footer-become-member-container {
        display: none;
    }

    .header {
        width: 100%;
        padding: 10px 0;
        display: flex;
        flex-direction: row;
    }

    .header-title {
        margin: auto 10px;
        width: 50%;
        font-size: 13px;
    }

    .header-logo {
        width: 120px;
        margin-top: 5px;
    }
    
    .home-header-title:hover {cursor: default;}

    .welcome-header-items {
        display: flex;
        padding: 0px;
        margin: 5px 15px;
        list-style-type: none;
        width: 50%;
        justify-content: flex-end;
    }

    .welcome-header-item {
        margin-right: 10px;
        font-size: 12px;
        color: black;
    }

    .welcome-header-item:hover {
        border-bottom: solid #FF2F46 2px;
    }

    .main-image {
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../images/images/motorcyk-motorcycle-ducati-profile-main.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 320px;
        padding: 20px;
        margin: 0px;
        display: block;
    }

    .image-quote {
        color: white;
        font-family: "Permanent Marker", "cursive";
        margin-top: 290px;
        margin-left: 5px;
        width: 100px;
        font-size: 20px;
    }

    .upcoming-event-section {
        width: 100%;
        background-color: #232323;
        text-align: center;
        display: block;
        padding: 20px 0;
    }

    .upcoming-event-section-title {
        color: #FF2F46;
        font-family: "Permanent Marker", "cursive";
        font-size: 35px;
        width: 100%;
        text-align: center;
        background-color: #232323;
        margin: 0px;
        padding-top: 45px;
    }

    .event-box {
        display: inline-block;
        background-color: #191919;
        width: 35%;
        padding: 10px;
        border-radius: 10px;
        margin: 20px 10px;
    }

    .event-box p {
        margin: 10px auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .event-date {
        opacity: .70;
        color: white;
        font-size: 10px;
    }

    .event-title {
        font-size: 15px;
        color: white;
    }

    .event-city {
        font-family: "Permanent Marker", "cursive";
        color:#FF2F46;
        font-size: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .event-host {
        font-size: 8px;
        color: white;
    }

    .upcoming-event-section-button {
        width: 90%;
        height: 50px;
        border-radius: 10px;
        margin: 20px auto;
        border: none;
        background-color: #FF2F46;
        color: white;
        font-size: 15px;
    }

    .upcoming-event-section-button:hover {
        cursor: pointer;
    }

    .how-it-works-section {
        padding: 20px;
        text-align: center;
    }

    .how-it-works-section-title {
        color: #191919;
        font-size: 35px;
        font-weight: bolder;
        margin: 50px auto;
        text-align: center;
    }

    .discover, .create {
        display: block;
        margin-bottom: 100px;
        width: 100%;
    }

    .discover-icon, .create-icon {
        height: 20px;
    }

    .how-it-works-section .discover .discover-title, 
    .how-it-works-section .create .create-title {
        font-family: "Permanent Marker", "cursive";
        font-size: 25px;
    }

    .discover-content, .create-content {
        width: 95%;
        margin: auto;
        font-size: 15px;
    }

    .discover-link, .create-link {
        color: #FF2F46;
        font-size: 15px;
    }

    .marker, .header-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
    }

    .arrow-icon {
        margin-bottom: -2px;
        width: 12px;
    }

    .welcome-footer-container {
        background-color: #232323;
        color: white;
        width: 100%;
        padding: 20px 0px;
    }

    .welcome-footer-list-container {
        width: 100%;
        margin-top: 50px;
        text-align: center;
    }

    .welcome-footer-list-one,
    .welcome-footer-list-two,
    .welcome-footer-list-three {
        display: block;
        width: 100%;
        padding: 0px;
        margin: 50px 0px;
    }

    .welcome-footer-list-title:hover {
        cursor: default;
    }

    .footer-break {
        display: none;
    }

    .welcome-footer-list-item {
        list-style-type: none;
        padding: 0px;
        margin-top: 10px;
        font-weight: lighter;
        font-size: 15px;
    }

    .welcome-footer-list-item:hover {
        color: #FF2F46;
    }

    .welcome-footer-list-item-question:hover {
        cursor: default;
        color: white;
    }

    .welcome-footer-follow-soon-container {
        text-align: center;
    }

    .welcome-footer-follow-soon-icons-container {
        display: block;
    }

    .welcome-footer-follow-soon-icons-title {
        margin: 0px auto;
    }

    .welcome-footer-follow-soon-icons-title:hover {
        cursor: default;
    }

    .welcome-footer-follow-soon-icons-title-comming {
        margin-top: 45px;
    }

    .welcome-footer-follow-soon-icons {
        width: 100%;
        margin-top: 35px;
    }

    .welcome-footer-follow-soon-icon {
        margin: 0px 10px;
    }

    .welcome-footer-follow-social-icon {
        height: 35px;
    }

    .welcome-footer-follow-soon-store-container {
        display: inline-block;
        vertical-align: top;
        margin: auto 50px;
    }

    .welcome-footer-follow-soon-icons-google {
        width: 135px;
    }

    .welcome-footer-follow-soon-icons-apple {
        width: 135px;
    }

    .welcome-footer-bottom-title {
        font-size: 10px;
        margin-top: 50px;
        width: 100%;
        text-align: center;
    }

    .welcome-footer-bottom-title-title {
        margin: 0px 10px;
    }

    .welcome-footer-bottom-title-title:hover {
        cursor: default;
    }

    .welcome-footer-bottom-title-link {
        color: white;
        margin: 0px 10px;
    }

    .welcome-footer-bottom-title-link:hover {
        color:#FF2F46;
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .hide-tablet {
        display: none;
    }

    .header {
        width: 100%;
        color: white;
        /* background-color: #232323; */
        padding: 10px 0;
        display: flex;
        flex-direction: row;
        height: 50px;
    }

    .header-title {
        margin: auto 10px;
        width: 50%;
        font-size: 18px;
    }

    .header-logo {
        width: 130px;
        margin-top: 5px;
    }

    .welcome-header-items{
        display: flex;
        padding: 0;
        margin: 5px 10px;
        list-style-type: none;
        width: 50%;
        justify-content: flex-end;
    }

    .welcome-header-item {
        margin: auto 15px;
        font-size: 15px;
        color: black;
    }

    .welcome-header-item:hover {
        border-bottom: solid #FF2F46 2px;
    }

    ul li {
        margin: auto 5px;
    }

    .main-image {
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../images/images/tabletImage3.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: 600px;
        padding: 20px;
        margin: 0px;
        display: block;
        text-align: center;
    }

    .image-quote {
        color: white;
        /* font-family: "Indie Flower", "cursive"; */
        font-family: "Permanent Marker", "cursive";
        width: 100%;
        margin-top: 300px;
        font-size: 30px;
        font-weight: bold;
    }

    .image-button {
        width: 30%;
        height: 50px;
        border-radius: 10px;
        margin: 0 auto;
        border: none;
        background-color: #FF2F46;
        color: white;
        font-size: 15px;
    }

    .image-button:hover {
        cursor: pointer;
    }

    .upcoming-event-section {
        width: 100%;
        background-color: #232323;
        text-align: center;
        display: block;
        padding: 20px 0;
    }

    .upcoming-event-section-title {
        background-color: #232323;
        color: #FF2F46;
        font-family: "Permanent Marker", "cursive";
        font-size: 35px;
        margin: 0px;
        padding: 20px 0px;
        width: 100%;
        text-align: center;
    }

    .event-box {
        display: inline-block;
        background-color: #191919;
        width: 40%;
        padding: 10px;
        border-radius: 10px;
        margin: 20px 20px;
        margin-bottom: 40px;
    }

    .event-box p {
        margin: 10px auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .event-date {
        opacity: .70;
        color: white;
    }

    .event-title {
        font-size: 20px;
        color: white;
    }

    .event-city {
        /* font-family: "Indie Flower", "cursive"; */
        font-family: "Permanent Marker", "cursive";
        color:#FF2F46;
        font-size: 25px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .event-host {
        font-size: 10px;
        color: white;
    }


    .how-it-works-section {
        padding: 20px;
        text-align: center;

    }

    .how-it-works-section-title {
        color: #191919;
        /* font-family: "Permanent Marker", "cursive"; */
        font-weight: bolder;
        font-size: 45px;
        margin: 50px;
        text-align: center;
    }

    .discover, .create {
        display: block;
        margin-bottom: 100px;
        width: 100%;
    }

    .discover-icon, .create-icon {
        width: 25px;
    }

    .discover-title, .create .create-title {
        font-family: "Permanent Marker", "cursive";
        font-size: 30px;
    }

    .discover-content, .create-content {
        width: 90%;
        margin: auto;
        font-size: 20px;
    }

    .discover-link, .create-link {
        color: #FF2F46;
        font-size: 25px;
    }

    .marker, .header-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
    }

    .arrow-icon {
        margin-bottom: -4px;
        width: 20px;
    }

    .welcome-footer-container {
        background-color: #232323;
        color: white;
        width: 100%;
        padding: 80px 0px;
    }

    .welcome-footer-become-member-container {
        width: 100%;
        text-align: center;
    }

    .welcome-footer-statement:hover {
        cursor: default;
    }

    .welcome-footer-button {
        width: 120px;
        height: 40px;
        border-radius: 10px;
        margin: 0px auto;
        margin-left: 5px;
        border: #FF2F46 1px solid;
        background-color: transparent;
        color: #FF2F46;
    }

    .welcome-footer-button:hover {
        cursor: pointer;
        color: white;
        background-color: #FF2F46;
    }

    .welcome-footer-list-container {
        width: 100%;
        margin-top: 50px;
        text-align: center;
    }

    .welcome-footer-list-one,
    .welcome-footer-list-two {
        display: inline-block;
        vertical-align: top;
        width: 40%;
        padding: 0px;
        margin: 50px 0px;
    }

    .welcome-footer-list-three {
        display: block;
        width: 400px;
        text-align: "center";
        margin: 0px auto;
        padding: 0px;
    }

    .welcome-footer-list-title {
        margin: 0px;
    }

    .welcome-footer-list-title:hover {
        cursor: default;
    }

    .footer-break {
        display: none;
    }

    .welcome-footer-list-item {
        list-style-type: none;
        padding: 0px;
        margin-top: 10px;
        font-weight: lighter;
        font-size: 15px;
    }

    .welcome-footer-list-item:hover {
        color: #FF2F46;
    }

    .welcome-footer-list-item-question {
        margin-top: 35px;
    }

    .welcome-footer-list-item-question:hover {
        cursor: default;
        color: white;
    }

    .welcome-footer-follow-soon-container {
        text-align: center;
    }

    .welcome-footer-follow-soon-icons-container {
        display: inline-block;
        vertical-align: top;
        margin: auto 100px;
    }

    .welcome-footer-follow-soon-icons-title {
        width: 400px;
        margin: 0px auto;
        margin-top: 100px;
    }

    .welcome-footer-follow-soon-icons-title:hover {
        cursor: default;
    }

    .welcome-footer-follow-soon-icons {
        margin-top: 45px;
    }

    .welcome-footer-follow-soon-icon {
        margin: 0px 10px;
    }

    .welcome-footer-follow-social-icon {
        height: 40px;
    }

    .welcome-footer-follow-soon-store-container {
        display: inline-block;
        vertical-align: top;
        margin: auto 50px;
    }

    .welcome-footer-follow-soon-icons-google {
        width: 135px;
    }

    .welcome-footer-follow-soon-icons-apple {
        width: 135px;
    }

    .welcome-footer-bottom-title {
        font-size: 10px;
        margin-top: 50px;
        width: 100%;
        text-align: center;
    }

    .welcome-footer-bottom-title-title {
        margin: 0px 20px;
    }

    .welcome-footer-bottom-title-title:hover {
        cursor: default;
    }

    .welcome-footer-bottom-title-link {
        color: white;
        margin: 0px 20px;
    }

    .welcome-footer-bottom-title-link:hover {
        color:#FF2F46;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    
    .hide-tablet {
        display: none;
    }

    .header {
        width: 100%;
        color: white;
        /* background-color: #232323; */
        padding: 10px 0;
        display: flex;
        flex-direction: row;
        height: 50px;
    }

    .header-title {
        margin: auto 10px;
        width: 50%;
        font-size: 22px;
    }

    .header-logo {
        width: 150px;
        margin-top: 5px;
    }

    .welcome-header-items{
        display: flex;
        padding: 0;
        margin: 5px 10px;
        list-style-type: none;
        width: 50%;
        justify-content: flex-end;
    }

    .welcome-header-item {
        margin: auto 15px;
        font-size: 15px;
    }

    .main-image {
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../images/images/indexImage.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 500px;
        text-align: center;
        margin: auto;
    }

    .image-quote {
        color: white;
        font-family: "Permanent Marker", "cursive";
        width: 100%;
        margin-top: 200px;
        font-size: 30px;
        font-weight: bold;
    }

    .image-button {
        width: 300px;
        height: 50px;
        border-radius: 10px;
        margin: 0 auto;
        border: none;
        background-color: #FF2F46;
        color: white;
        font-size: 20px;
    }

    .upcoming-event-section-contianer {
        width: 100%;
        background-color: #232323;
        overflow:auto;
    }

    .upcoming-event-section {
        width: 1200px;
        background-color: #232323;
        text-align: center;
        display: block;
        padding: 20px 0;
        margin: auto;
        overflow:auto;
    }

    .upcoming-event-section-title {
        color: #FF2F46;
        font-family: "Permanent Marker", "cursive";
        margin: 0px;
        padding: 20px 0px;
        width: 100%;
        text-align: center;
    }

    .event-box {
        display: inline-block;
        background-color: #191919;
        width: 300px;
        padding: 10px;
        border-radius: 10px;
        margin: 20px 20px;
        margin-bottom: 40px;
    }

    .event-box p {
        margin: 10px auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .event-date {
        opacity: .70;
        color: white;
    }

    .event-title {
        font-size: 20px;
        color: white;
    }

    .event-city {
        font-family: "Permanent Marker", "cursive";
        color:#FF2F46;
        font-size: 25px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .event-host {
        font-size: 10px;
        color: white;
    }


    .how-it-works-section {
        padding: 20px;
        text-align: center;

    }

    .how-it-works-section-title {
        color: #191919;
        /* font-family: "Permanent Marker", "cursive"; */
        font-weight: bolder;
        font-size: 45px;
        margin: 50px;
        text-align: center;
    }

    .discover, .create {
        display: inline-block;
        vertical-align: top;
        margin-bottom: 100px;
        width: 48%;
    }

    .discover-icon, .create-icon {
        width: 25px;
    }

    .discover-title, .create-title {
        font-family: "Permanent Marker", "cursive";
        font-size: 30px;
    }

    .discover-content, .create-content {
        width: 70%;
        margin: auto;
        font-size: 20px;
    }

    .discover-link, .create-link {
        color: #FF2F46;
        font-size: 25px;
    }

    .marker, .header-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
    }

    .arrow-icon {
        margin-bottom: -4px;
        width: 20px;
    }

    .welcome-footer-container {
        background-color: #232323;
        color: white;
        width: 100%;
        padding: 80px 0px;
    }

    .welcome-footer-become-member-container {
        width: 100%;
        text-align: center;
    }

    .welcome-footer-statement:hover {
        cursor: default;
    }

    .welcome-footer-button {
        width: 120px;
        height: 40px;
        border-radius: 10px;
        margin: 0px auto;
        margin-left: 5px;
        border: #FF2F46 1px solid;
        background-color: transparent;
        color: #FF2F46;
    }

    .welcome-footer-button:hover {
        cursor: pointer;
        color: white;
        background-color: #FF2F46;
    }

    .welcome-footer-list-container {
        width: 100%;
        margin-top: 50px;
        text-align: center;
    }

    .welcome-footer-list-one,
    .welcome-footer-list-two,
    .welcome-footer-list-three {
        display: inline-block;
        vertical-align: top;
        width: 150px;
        margin: 50px 30px;
    }

    .welcome-footer-list-title {
        margin: 0px;
    }

    .welcome-footer-list-title:hover {
        cursor: default;
    }

    .welcome-footer-list-item {
        list-style-type: none;
        padding: 0px;
        margin-top: 10px;
        font-weight: lighter;
        font-size: 15px;
    }

    .welcome-footer-list-item:hover {
        color: #FF2F46;
    }

    .welcome-footer-list-item-question:hover {
        cursor: default;
        color: white;
    }

    .welcome-footer-follow-soon-container {
        text-align: center;
        width: 100%;
    }

    .welcome-footer-follow-soon-icons-container {
        display: inline-block;
        vertical-align: top;
        margin: auto 100px;
    }

    .welcome-footer-follow-soon-icons-title {
        margin: 0px 50px;
        margin-top: 50px;
    }

    .welcome-footer-follow-soon-icons {
        margin-top: 45px;
    }

    .welcome-footer-follow-soon-icon {
        margin: 0px 10px;
    }

    .welcome-footer-follow-social-icon {
        height: 40px;
    }

    .welcome-footer-follow-soon-store-container {
        display: inline-block;
        vertical-align: top;
        margin: auto 50px;
    }

    .welcome-footer-follow-soon-icons-google {
        width: 135px;
    }

    .welcome-footer-follow-soon-icons-apple {
        width: 135px;
    }

    .welcome-footer-bottom-title {
        font-size: 10px;
        margin-top: 50px;
        width: 100%;
        text-align: center;
    }

    .welcome-footer-bottom-title-title {
        margin: 0px 20px;
    }

    .welcome-footer-bottom-title-title:hover {
        cursor: default;
    }

    .welcome-footer-bottom-title-link {
        color: white;
        margin: 0px 20px;
    }

    .welcome-footer-bottom-title-link:hover {
        color:#FF2F46;
    }

    

}
