@media only screen and (max-width: 600px) {
    .mobile-nav-bar {
        width: 100%;
        background-color: #232323;
        display: block;
        position: fixed;
        bottom: 0;
    }

    .mobile-nav-bar ul {
        list-style-type: none;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-around;
        color: white;
        text-align: center;
        padding: 0px;
    }

    .mobile-nav-bar ul li {
        width: 15%;
        margin: auto;
        text-align: center;
    }

    .mobile-nav-bar ul li:hover {
        cursor: pointer;
        opacity: .7;
    }

    .mobile-nav-bar ul li h6 {
        margin: 0px;
    }

    .home-home-icon,
    .home-profile-icon,
    /* .home-host-icon, */
    .home-explore-icon,
    .home-settings-icon {
        width: 25px;
        height: 20px;
        /* margin: 5px auto; */
    }

    .home-logo-icon {
        height: 25px;
        /* margin: 5px; */
    }

}
