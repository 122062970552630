/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .profile-feed-component-container {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 150px;
        margin: 0px;
        margin-top: -50px;
        text-align: center;
    }

    .profile-posts-component-message-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }

    .profile-feed-statement {
        font-family: "Poppins", "cursive";
        text-align: center;
        width: 90%;
        margin: 10px auto;
    }

    .profile-feed-component-event-box {
        display: inline-block;
        width: 180px;
        padding: 10px;
        border-radius: 10px;
        margin: 10px;
        border: solid 1px black;
        text-align: center;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    .profile-feed-component-event-box p {
        margin: 10px auto;
    }

    .profile-feed-component-date {
        font-family: "Poppins", "cursive";
        opacity: .70;
        color: black;
        font-size: 10px;
    }

    .profile-feed-component-title {
        font-family: "Poppins", "cursive";
        font-size: 15px;
        color: black;
    }

    .profile-feed-component-city {
        font-family: "Permanent Marker", "cursive";
        color:#FF2F46;
        font-size: 15px;
    }

    .profile-feed-component-host {
        font-family: "Poppins", "cursive";
        font-size: 8px;
        color: black;
    }

    .profile-feed {
        width: 90%;
        margin: 0px auto;
        margin-top: 30px;
    }

    .profile-feed-date-time {
        margin-bottom: -10px;
        /* margin-left: 20px; */
    }

    .profile-feed-stuff {
        /* margin-left: 20px; */
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .profile-feed-component-container {
        width: 90%;
        padding: 20px;
        padding-bottom: 150px;
        margin: auto;
        margin-top: -40px;
        text-align: center;
    }

    .profile-posts-component-message-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }

    .profile-feed-statement {
        font-family: "Poppins", "cursive";
        text-align: center;
        width: 90%;
        margin: 10px auto;
    }

    .profile-feed-component-event-box {
        display: inline-block;
        width: 180px;
        padding: 10px;
        border-radius: 10px;
        margin: 10px;
        border: solid 1px black;
        text-align: center;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    .profile-feed-component-event-box p {
        margin: 10px auto;
    }

    .profile-feed-component-date {
        font-family: "Poppins", "cursive";
        opacity: .70;
        color: black;
        font-size: 10px;
    }

    .profile-feed-component-title {
        font-family: "Poppins", "cursive";
        font-size: 15px;
        color: black;
    }

    .profile-feed-component-city {
        font-family: "Permanent Marker", "cursive";
        color:#FF2F46;
        font-size: 15px;
    }

    .profile-feed-component-host {
        font-family: "Poppins", "cursive";
        font-size: 8px;
        color: black;
    }

    .profile-feed {
        width: 90%;
        margin: 0px auto;
        margin-top: 30px;
    }

    .profile-feed-date-time {
        margin-bottom: -10px;
        /* margin-left: 20px; */
    }

    .profile-feed-stuff {
        /* margin-left: 20px; */
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}