.event-details-container {
    width: 100%;
    padding: 0px;
    padding-bottom: 500px;
    margin: 0px;
}

.links {
    text-decoration: none;
}

.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.b {
    color: black;
}

.no-dec {
    text-decoration: none;
}

.eventdetails-map {
    width: 400px;
    height: 200px;
}

.map {
    width: 100px;
    text-align: center;
    display: block;
}

.event-details-user-going {
    color: black;
    margin: 10px;
}

.event-details-user-going:hover {
    color: #FF2F46
}

.eventdetails-coming-soon-title {
    color: #FF2F46;
}

.eventdetails-coming-soon-title:hover {
    cursor: default;
}

.eventdetails-riders-going-title {
    margin-bottom: -5px;
}

.eventdetails-riders-going-title:hover {
    cursor: default;
}

.event-details-external-link:hover {
    cursor: pointer;
    border-bottom: solid #FF2F46 2px;
}


/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .map-container {
        width: 99%; /* 99 due to border*/
        height: 300px;
        margin: 0px auto;
        border: 1px #FF2F46 solid;
    } 
    
    .event-details-title-container {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        margin-top: 75px;
    }

    .event-details-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .eventdetails-owner-buttons {
        text-align: center;
    }

    .eventdetails-form-button {
        background-color: #FF2F46;
        color: white;
        width: 95%;
        height: 35px;
        margin: 5px auto;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
        cursor: pointer;
    }

    .eventdetails-details-container {
        text-align: center;
        margin: 50px 0px;
        width: 100%;
    }

    /* .eventdetails-map {
        width: 200px;
        height: 100px;
        border: solid #FF2F46 1px;
        border-radius: 5px;
        margin: 10px auto;
    } */

    .event-details-users-going-container {
        width: 100%;
        padding: 0px;
    }

    .chat-container {
        width: 90%;
        margin: 0px auto;
        margin-top: 50px;
        text-align:  center;
    }

    .messages-box {
        width: 100%;
        height: 300px;
        background-color: white;
        margin: 0px auto;
        border-radius: 5px;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        /* overflow-y: auto; */
    }

    .typing-container {
        width: 100%;
    }

    .message-form-input {
        width: 80%;
        height: 30px;
        margin: 30px auto;
        margin-bottom: 5px;
        text-align: center;
        display: inline-block;
        background-color: white;
        color: black;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        font-size: 15px;
    }

    .eventdetails-post-button {
        background-color: #FF2F46;
        color: white;
        width: 80%;
        height: 35px;
        margin: 5px auto;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
        cursor: pointer;
    }

    .message-send {
        height: 13px;
        margin-left: 10px;
    }

    .description-container {
        white-space: pre-line;
    }

    .event-details-gif-container {
        width: 100%;
    }

    .event-details-gif {
        width: 100%;
        margin: 0px;
        padding: 0px;
        border: none;
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .map-container {
        width: 80%;
        height: 300px;
        margin: 0px auto;
        border: 1px #FF2F46 solid;
        border-radius: 10px;
    } 

    .event-details-title-container {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        margin-top: 75px;
    }

    .event-details-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .eventdetails-owner-buttons {
        text-align: center;
    }

    .eventdetails-form-button {
        background-color: #FF2F46;
        color: white;
        width: 400px;
        height: 35px;
        margin: 10px;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
        cursor: pointer;
    }

    .eventdetails-details-container {
        text-align: center;
        margin: 50px auto;
        width: 95%;
    }

    .eventdetails-details-title {
        font-size: 30px;
    }

    .description-container {
        width: 40%;
        font-family: "Poppins", "cursive";
        padding-top: 50px;
        white-space: pre-line;
    }

    .chat-container {
        font-family: "Poppins", "cursive";
        padding-top: 50px;
        width: 40%;
        margin: 0px auto;
        text-align:  center;
    }

    .description-container, .chat-container {
        display: inline-block;
        vertical-align: top;
        margin: 0px 25px;
    }

    .messages-box {
        width: 100%;
        height: 300px;
        background-color: white;
        border-radius: 5px;
        font-family: "Poppins", "cursive";
        margin-bottom: -20px;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        overflow-y: auto;
    }

    .typing-container {
        width: 100%;
        margin-top: 20px;
    }

    .message-form-input {
        width: 80%;
        height: 30px;
        margin: 30px auto;
        margin-bottom: 5px;
        text-align: center;
        display: inline-block;
        background-color: white;
        color: black;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
    }

    .eventdetails-post-button {
        background-color: #FF2F46;
        color: white;
        width: 80%;
        height: 35px;
        margin: 5px auto;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
        cursor: pointer;
    }

    .message-send {
        height: 13px;
        margin-left: 10px;
    }
    
    .event-details-gif-container {
        width: 100%;
    }

    .event-details-gif {
        width: 100%;
        margin: 0px;
        padding: 0px;
        border: none;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {

    .eventdetails-details-container {
        width: 1000px;
    }
}

