div.fileContainer div.uploadPictureContainer {
    width: 500px;
}

div.fileContainer button.chooseFileButton {
    /* background-color: #FF2F46; */
    background-color: #232323;
}

div.fileContainer div.deleteImage {
    background: none;
    background-color: #FF2F46;
}

div.fileContainer {
    box-shadow: none;
}

/* .deleteImage {
    display: none;
} */

.upload-post-error-message {
    color: #FF2F46;
    font-family: "Poppins", "cursive";
    margin: 0px auto;
    margin-top: 20px;
    box-shadow: none;

}


/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */
@media only screen and (max-width: 600px) {
    .upload-post-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }
    
    .upload-post-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .upload-post-form-input {
        width: 85%;
        height: 30px;
        margin: 5px auto;
        text-align: center;
        display: block;
        background-color: white;
        color: black;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
    }

    .upload-post-section {
        width: 90%;
        padding: 10px;
        text-align: center;
        margin: auto;
        margin-bottom: 200px;
    }
    
    .upload-post-form-button {
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 85%;
        font-family: "Poppins", "cursive";
        display: block;
        margin: 0px auto;
        margin-top:  20px;
    }

    .link {
        text-align:justify;
    }
    
    .upload-post-form-button {cursor: pointer}
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .upload-post-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }
    
    .upload-post-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .upload-post-form-input {
        width: 400px;
        height: 30px;
        margin: 5px auto;
        text-align: center;
        display: block;
        background-color: white;
        color: black;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
    }

    .upload-post-form-button {
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 400px;
        font-family: "Poppins", "cursive";
        display: block;
        margin: 10px auto;
        margin-top:  20px;

    }
    
    .upload-post-form-button {cursor: pointer}
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}
