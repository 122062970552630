.edit-event-container {
    width: 100%;
    padding: 0px;
    /* padding-top: 25px; */
    padding-bottom: 200px;
    margin: 0px;
    /* background-color: #232323; */
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.b {
    color: black;
}

.no-dec {
    text-decoration: none;
}

.edit-event-privacy-select {
    width: 90px;
    padding: 5px;
    border-radius: 5px;
    font-size: 15px;
    margin: 15px auto;
}

.edit-event-privacy-select:hover {
    cursor: pointer;
}

.edit-event-select {
    appearance: none;
    width: 100px;
    padding: 5px;
    border-radius: 5px;
    font-size: 15px;
    margin: 15px auto;
    text-align: center;
}

.edit-event-datepicker-container {
    width: 400px;
    margin: auto;
}

.edit-event-form-error-message {
    color: #FF2F46;
    font-family: "Poppins", "cursive";
    margin: 0px auto;
}

.back {
    margin-top: 25px;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {

    .edit-event-title-container {
        width: 100%;
        text-align: center;
        margin-bottom: 25px;
        margin-top: 75px;
    }

    .edit-event-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .edit-event-form {
        width: 100%;
        text-align: center;
    }

    .edit-event-form-input {
        width: 400px;
        height: 30px;
        margin: 5px auto;
        text-align: center;
        display: block;
        background-color: white;
        color: black;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
    }

    .edit-event-form-input::-webkit-input-placeholder { /* WebKit browsers */
        color:    black;
         opacity: 1 !important;
    }

    .text-box {
        heightk: 100px;
        resize: none;
    }

    .edit-event-form-input-loc-container {
        overflow:hidden;
        margin: auto;
    }

    .edit-event-form-button {
        background-color: #FF2F46;
        color: white;
        width: 400px;
        height: 35px;
        margin: 5px auto;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
        cursor: pointer;
    }

    .edit-event-options-switch-title {
        width: 100%;
        margin: 0px auto;
        margin-top: 50px;
        text-align: center;
    }

    .edit-event-switches {
        display: flex;
        justify-content: space-evenly;
        width: 400px;
        margin: 0px auto;

    }

    .edit-event-switch {
        width: 150px;
    }

    .edit-event-user-invitees {
        margin: 0px auto;
    }

    .edit-event-user-invitees p {
        display: inline-block;
        width: 30%;
        margin: 5px;
        margin-top: 20px;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .edit-event-title-container {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        margin-top: 75px;
    }

    .edit-event-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .edit-event-form {
        width: 100%;
        text-align: center;
    }

    .edit-event-form-input {
        width: 400px;
        height: 30px;
        margin: 5px auto;
        text-align: center;
        display: block;
        background-color: white;
        color: black;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
    }

    .edit-event-form-input::-webkit-input-placeholder { /* WebKit browsers */
        color:    black;
         opacity: 1 !important;
    }

    .text-box {
        height: 100px;
        resize: none;
    }

    .edit-event-form-button {
        background-color: #FF2F46;
        color: white;
        width: 400px;
        height: 35px;
        margin: 5px auto;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
        cursor: pointer;
    }

    .edit-event-options-switch-title {
        width: 100%;
        margin: 0px auto;
        margin-top: 50px;
        text-align: center;
    }

    .edit-event-switches {
        display: flex;
        justify-content: space-evenly;
        width: 400px;
        margin: 0px auto;

    }

    .edit-event-switch {
        width: 150px;
    }

    .edit-event-user-invitees-container {
        width: 650px;
        margin: auto;
    }

    .edit-event-user-invitees {
        margin: 0px auto;
    }

    .edit-event-user-invitees p {
        display: inline-block;
        width: 30%;
        margin: 10px auto;
        margin-top: 20px;
    }
    
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}

