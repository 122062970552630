.explore-container {
    width: 100%;
    padding: 0px;
    /* padding-top: 25px; */
    padding-bottom: 500px;
    margin: 0px;
    /* background-color: #232323; */
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.b {
    color: black;
}

.no-dec {
    text-decoration: none;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {

    .explore-title-container {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        margin-top: 75px;
    }

    .explore-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .explore-form {
        width: 100%;
        text-align: center;
    }

    .explore-form-input {
        display: block;
        margin: 20px auto;
        width: 85%;
        height: 30px;
        border: solid 2px #FF2F46;
        border-radius: 5px;
        background-color: white;
        color: black;
        text-align: center;
        font-size: 15px;
        font-family: "Poppins", "cursive";
    }

    .explore-form-button {
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 85%;
        font-family: "Poppins", "cursive";
    }

    .search-event-zero-found {
        width: 100%;
        text-align: center;
        margin-top: 100px;
    }

    .explore-event-view {
        width: 90%;
        margin: 10px 0px;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 601px) {

    .explore-title-container {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        margin-top: 75px;
    }

    .explore-title {
        font-family: "Permanent Marker", "cursive";
        color: red;
        margin-bottom: 0px;
    }

    .explore-search-items {
        margin: 50px auto;
    }

    .explore-form {
        width: 100%;
        text-align: center;
    }

    .explore-form-input {
        display: block;
        margin: 20px auto;
        width: 400px;
        height: 30px;
        border: solid 2px #FF2F46;
        border-radius: 5px;
        background-color: white;
        color: black;
        text-align: center;
        font-size: 15px;
        font-family: "Poppins", "cursive";
    }

    .explore-form-button {
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 400px;
        font-family: "Poppins", "cursive";
    }

    .search-event-zero-found {
        width: 100%;
        text-align: center;
        margin-top: 100px;
    }

    .explore-event-view {
        width: 280px;
        margin: 8px;
    }

}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}
