html {
    font-family: "Poppins", "cursive";
    padding: 0px;
}

body {
    margin: 0px;
}

input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}