.rider-attending-signin-button {
    width: 260px;
    height: 30px;
    border: none;
    color: white;
    background-color: #FF2F46;
    border-radius: 5px;
    margin: 20px;
}

.rider-hosting-signin-button:hover {
    cursor: pointer;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .rider-attending-component-container {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 150px;
        margin: 0px;
        margin-top: -40px;
        text-align: center;
    }

    .rider-attending-component-message-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }

    .rider-attending-statement {
        font-family: "Poppins", "cursive";
        text-align: center;
        width: 90%;
        margin: 10px auto;
    }

    .rider-attending-component-event-box {
        display: inline-block;
        width: 180px;
        padding: 10px;
        border-radius: 10px;
        margin: 10px;
        border: solid 1px black;
        text-align: center;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    .rider-attending-component-event-box p {
        margin: 10px auto;
    }

    .rider-attending-component-date {
        font-family: "Poppins", "cursive";
        opacity: .70;
        color: black;
        font-size: 10px;
    }

    .rider-attending-component-title {
        font-family: "Poppins", "cursive";
        font-size: 15px;
        color: black;
    }

    .rider-attending-component-city {
        font-family: "Permanent Marker", "cursive";
        color:#FF2F46;
        font-size: 15px;
    }

    .rider-attending-component-host {
        font-family: "Poppins", "cursive";
        font-size: 8px;
        color: black;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .rider-attending-component-container {
        width: 90%;
        padding: 20px;
        padding-bottom: 150px;
        margin: auto;
        margin-top: -40px;
        text-align: center;
    }

    .rider-posts-component-message-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }

    .rider-attending-statement {
        font-family: "Poppins", "cursive";
        text-align: center;
        width: 90%;
        margin: 10px auto;
    }

    .rider-attending-component-event-box {
        display: inline-block;
        width: 180px;
        padding: 10px;
        border-radius: 10px;
        margin: 10px;
        border: solid 1px black;
        text-align: center;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    .rider-attending-component-event-box p {
        margin: 10px auto;
    }

    .rider-attending-component-date {
        font-family: "Poppins", "cursive";
        opacity: .70;
        color: black;
        font-size: 10px;
    }

    .rider-attending-component-title {
        font-family: "Poppins", "cursive";
        font-size: 15px;
        color: black;
    }

    .rider-attending-component-city {
        font-family: "Permanent Marker", "cursive";
        color:#FF2F46;
        font-size: 15px;
    }

    .rider-attending-component-host {
        font-family: "Poppins", "cursive";
        font-size: 8px;
        color: black;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}