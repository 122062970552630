.attended-events-history-section {
    width: 90%;
    padding: 10px;
    text-align: center;
    margin: auto;
}

.attended-events-history-button {
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
    margin-top: 30px;
}

.attended-events-history-button {cursor: pointer}

.attended-events-history-event {
    font-family: "Poppins", "cursive";
    margin: 25px auto;
    width: auto;
}

.attended-events-history-event:hover {
    color: #FF2F46;
    cursor: pointer;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .attended-events-history-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .attended-events-history-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .attended-events-history-section {
        text-align: center;
        margin-bottom: 150px;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .attended-events-history-title-container {
        width: 100%;
        text-align: center;
        margin-bottom: -10px;
        margin-top: 75px;
    }

    .attended-events-history-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
    }

    .attended-events-history-button {
        width: 400px;
    }

    .attended-events-history-section {
        text-align: center;
        margin-bottom: 150px;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}