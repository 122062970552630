.motorcyk-platform-rules-container {
    width: 90%;
    margin: 50px auto;
}

.motorcyk-platform-rules-content-item {
    margin: 10px;
    margin-left: 10px;
    font-size: 12px;
}

.motorcyk-platform-rules-container-final {
    margin-bottom: 150px;
}
/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) { 
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}