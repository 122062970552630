.signup-container {
    width: 100%;
    padding: 30px 0px;
    margin: 0px;
    text-align: center;
    background-color: #232323;
}

.confirmation {
    height: 100vh;
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.no-dec {
    text-decoration: none;
}

.signup-title {
    font-family: "Permanent Marker", "cursive";
}

.signup-message {
    font-family: "Poppins", "cursive";
    width: 85%;
    margin: 50px auto;
    margin-top: 40px;
    font-size: 15px;
}

.signup-continue {
    margin: 10px auto;
    display: block;
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 300px;
}

.signup-continue:hover {
    cursor: pointer;
}

.signup-create-event {
    width: 55px;
    height: 17px;
    color: white;
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    margin: 0px 2px;
    padding: auto;
}

.signup-create-event:hover {
    cursor: pointer;
}

/* .signup-footer {
    font-family: "Poppins", "cursive";
    font-size: 10px;
    margin: 300px auto;
    padding-bottom: 50px;
    background-color: #232323;
} */
