.settings-container {
    width: 100%;
    padding: 0px;
    /* padding-top: 25px; */
    padding-bottom: 300px;
    margin: 0px;
    /* background-color: #232323; */
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.b {
    color: black;
}

.no-dec {
    text-decoration: none;
}

@media only screen and (max-width: 600px) {

    .settings-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .settings-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .settings-items-container {
        height: 100%;
        padding: 0px 20px;
        margin: 0px;
        font-family: "Poppins", "cursive";
    }

    .settings-items-container h4 {
        margin-bottom: 0px;
    }

    .settings-items-container ul {
        list-style-type: none;
        margin-top: 0px;
        padding-left: 10px;
    }

    .settings-items-container li {
        margin-bottom: 10px;
    }
}

@media only screen and (min-width: 600px) {
    .settings-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .settings-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .settings-items-container {
        height: 100%;
        padding: 0px 20px;
        margin: 0px;
        font-family: "Poppins", "cursive";
        text-align: center;
    }

    .settings-items-container h4 {
        margin-bottom: 10px;
        font-weight: bolder;
        font-size: larger;
    }

    .settings-items-container ul {
        list-style-type: none;
        margin-top: 0px;
        padding-left: 10px;
    }

    .settings-items-container li {
        margin-bottom: 10px;
    }
}

/* @media only screen and (min-width: 992px) {} */
