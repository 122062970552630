.following-container {
    padding-bottom: 100px;
}


/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .following-title {
        font-family: "Permanent Marker", "cursive";
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .following-user-icons-container {
        width: 100%;
        padding: 0px;
        text-align: center;
    }

    .following-profile-container-add,
    .following-profile-container-user {
        width: 100px;
        padding: 10px;
        margin: 10px;
        display: inline-block;
        vertical-align: top;
    }

    .following-add-icon-link {
        text-decoration: none;
        padding: 0px;
    }

    .following-add-icon {
        padding: 0px;
        margin: 0px;
        margin-top: 10px;
        width: 70px;
    }

    .following-text-add-name {
        font-family: "Permanent Marker", "cursive";
        margin-top: 6px;
        margin-right: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .following-profile-container-user {
        text-align: center;
    }

    .following-user-icon-link {
        text-decoration: none;
        padding: 0px;
    }

    .following-user-icon {
        padding: 0px;
        margin: 0px;
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }

    .following-text-name-user {
        width: 100%;
        margin: 0px;
        font-family: "Permanent Marker", "cursive";
        font-size: 15px;
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .following-title {
        font-family: "Permanent Marker", "cursive";
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .following-user-icons-container {
        width: 100%;
        padding: 0px;
        text-align: center;
    }

    .following-profile-container-add,
    .following-profile-container-user {
        width: 100px;
        padding: 10px;
        margin: 10px;
        display: inline-block;
        vertical-align: top;
    }

    .following-add-icon-link {
        text-decoration: none;
        padding: 0px;
    }

    .following-add-icon {
        padding: 0px;
        margin: 0px;
        margin-top: 10px;
        width: 70px;
    }

    .following-text-add-name {
        font-family: "Permanent Marker", "cursive";
        margin-top: 6px;
        margin-right: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .following-profile-container-user {
        text-align: center;
    }

    .following-user-icon-link {
        text-decoration: none;
        padding: 0px;
    }

    .following-user-icon {
        padding: 0px;
        margin: 0px;
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }

    .following-text-name-user {
        width: 100%;
        margin: 0px;
        font-family: "Permanent Marker", "cursive";
        font-size: 15px;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .following-user-icons-container {
        width: 1000px;
        padding: 0px;
        text-align: center;
        margin: 0px auto;
    }
}

