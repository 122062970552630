.tip-jar-rules-guide-guide-title-container {
    width: 100%;
    text-align: center;
}
 
.tip-jar-rules-guide-guide-steps {
    width: 95%;
    margin: 10px auto;
}

.guide-content {
    margin: 25px auto;
}

.tip-jar-rules-guide-rules-title-container {
    width: 100%;
    text-align: center;
}

.tip-jar-rules-guide-rules-steps {
    width: 95%;
    margin: 10px auto;
    margin-bottom: 200px;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) { 
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}