.profile-posts-container {
    width: 90%;
    padding: 0px;
    margin: 10px auto;
}

  .profile-posts-post:hover {
    cursor: pointer;
  }
  
.profile-posts-confirm {
    background-color: white;
    border: solid 3px #FF2F46;
    border-radius: 10px;
    width: 300px;
    text-align: center;
    position:absolute;
    top: 40%;
    left: 0;
    right: 0;
    margin: auto;
    padding: 20px 10px;
}
 .profile-posts-delete-button {
    height: 25px;
    background-color: #FF2F46;
    color: white;
    border: none;
    border-radius: 5px;
 }

 .profile-posts-delete-button:hover {
    cursor: pointer;
 }

.profile-post-delete-confirm-button {
    height: 25px;
    background-color: #FF2F46;
    color: white;
    border: none;
    border-radius: 5px;
}

.profile-post-delete-confirm-button:hover {
    cursor: pointer;  
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .profile-posts-statement-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }
    
    .profile-posts-statement {
        font-family: "Poppins", "cursive";
        text-align: center;
        margin-bottom: 40px;
    }

    .profile-posts-upload {
        display: block;
        margin: 0px auto;
        margin-top:  20px;
        /* margin-bottom: 40px; */
        padding-top: 5px;
        text-align: center;
        background-color: #FF2F46;
        border-radius: 5px;
        color: white;
        height: 25px;
        width: 200px;
        font-size: 12px;
    }

    .profile-posts-post-container {
        column-count: 2;
        column-gap: 15px;
        padding: 10px;
        margin-top: 40px;
    }

    .profile-posts-post {
        margin: 0;
        display: grid;
        grid-template-rows: 1fr auto;
        break-inside: avoid;
        color: black;
    }

    .profile-posts-post > .profile-posts-image {
        grid-row: 1 / -1;
        grid-column: 1;
    }

    .profile-posts-image {
        max-width: 100%;
        display: block;
        border-radius: 10px;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        margin: 15px auto;
    }

    .profile-posts-info {
        color: black;
        margin: 0px auto;
        margin-bottom: 10px;
        width: 90%;
      }

    /* **************MODAL STUFF BELOW************** */
    
    .popup-content {
        margin: auto;
        background: rgb(255, 255, 255);
        width: 85%;
        padding: 5px;
        text-align: center;
    }

    .profile-posts-post-pop-up {
        /* width: 720px; */
        height: 700px;
        background-color: black;
        padding: 0px;
        overflow:scroll;
        text-align: center;
        align-content: center;
        border-radius: 10px;
        background-color: transparent;
        margin: auto;
    
      }
    
      .profile-posts-image-test {
        width: 85%;
        max-height: 100%;
        border-radius: 10px;
        display: inline-block;
        vertical-align: middle;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
      }
    
    .popup-content {
        padding: 0px;
        border-radius: 10px;
        background-color: transparent;
    }
    
    .modal {
        font-size: 12px;
    }
    
    .modal > .close {
        cursor: pointer;
        position: absolute;
        display: block;
        padding: 2px 5px;
        line-height: 20px;
        right: 15px;
        top: -15px;
        font-size: 24px;
        background: #FF2F46;
        border-radius: 18px;
        border: 2px solid #FF2F46;
        color: white
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .profile-posts-statement-container {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }
    
    .profile-posts-statement {
        font-family: "Poppins", "cursive";
        text-align: center;
        margin-bottom: 40px;
    }

    .profile-posts-upload {
        display: block;
        margin: 0px auto;
        margin-top:  20px;
        /* margin-bottom: 40px; */
        padding-top: 5px;
        text-align: center;
        background-color: #FF2F46;
        border-radius: 5px;
        color: white;
        height: 25px;
        width: 200px;
        font-size: 12px;
    }

    .profile-posts-post-container {
        column-count: 3;
        column-gap: 15px;
        padding: 10px;
        margin-top: 40px;
        width: 100%;
    }

    .profile-posts-post {
        margin: 0;
        display: grid;
        grid-template-rows: 1fr auto;
        /* margin-bottom: 20px; */
        break-inside: avoid;
        color: black;
    }

    .profile-posts-post > .profile-posts-image {
        grid-row: 1 / -1;
        grid-column: 1;
    }

    .profile-posts-image {
        max-width: 100%;
        display: block;
        border-radius: 10px;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        margin: 20px auto;
    }

    div.fileContainer div.errorsContainer {
        overflow-wrap: break-word;
        max-width: 100%;
    }

    /* **************MODAL STUFF BELOW************** */

    .popup-content {
        margin: auto;
        background: rgb(255, 255, 255);
        max-width: 40%;
    }

    .profile-posts-post-pop-up {
        /* width: 720px; */
        height: 700px;
        background-color: black;
        padding: 0px;
        overflow:scroll;
        text-align: center;
        align-content: center;
        border-radius: 10px;
        background-color: transparent;
        margin: auto;
    
      }
    
      .profile-posts-image-test {
        max-width: 100%;
        max-height: 100%;
        border-radius: 10px;
        display: inline-block;
        vertical-align: middle;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
      }
    
    .popup-content {
        padding: 0px;
        border-radius: 10px;
        background-color: transparent;
    }
    
    .modal {
        font-size: 12px;
    }
    
    .modal > .close {
        cursor: pointer;
        position: absolute;
        display: block;
        padding: 2px 5px;
        line-height: 20px;
        right: -10px;
        top: -10px;
        font-size: 24px;
        background: #FF2F46;
        border-radius: 18px;
        border: 2px solid #FF2F46;
        color: white
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}