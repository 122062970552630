.change-payment-section {
    width: 90%;
    padding: 10px;
    text-align: center;
    margin-top: 50px;
}


.change-payment-button {
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
}

.change-payment-button {cursor: pointer}

.change-payment-event {
    font-family: "Poppins", "cursive";
}
/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .change-payment-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .change-payment-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .change-payment-section {
        text-align: center;
        width: 80%;
        margin: auto;
        margin-top: 50px;
    }

    .change-payment-input {
        width: 85%;
        height: 30px;
        margin: 5px auto;
        text-align: center;
        display: block;
        background-color: white;
        color: black;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .change-payment-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .change-payment-title {
        font-family: "Permanent Marker", "cursive";
        color: red;
        margin-bottom: 0px;
    }

    .change-payment-section {
        text-align: center;
        width: 70%;
        margin: auto;
        margin-top: 50px;
    }

    .change-payment-input {
        width: 400px;
        height: 30px;
        margin: 5px auto;
        text-align: center;
        display: block;
        background-color: white;
        color: black;
        border: solid 1px #FF2F46;
        border-radius: 5px;
        font-size: 15px;
        font-family: "Poppins", "cursive";
    }

    .change-payment-button {
        width: 400px;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .change-payment-title-container {
        width: 100%;
        text-align: center;
        margin-bottom: 0px;
        margin-top: 75px;
    }

    .change-payment-section {
        text-align: center;
        width: 800px;
        margin: auto;
        margin-top: 50px;
    }
}