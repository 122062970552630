.tip-jar-cashout-total {
    width: 100%;
    margin-top: 30px;
}

.tip-jar-cashout-total p, .tip-jar-cashout-total button {
    font-size: 14px;
    margin-left: 20px;
}

.tip-jar-cashout-paypal-logo {
    width: 65px;
    margin-left: 10px;
    margin-bottom: -4px;
}

.tip-jar-cashout-paypal-account {
    background-color: white;
    color: #FF2F46;
    border: #FF2F46 solid 1px;
    border-radius: 5px;
    height: 25px;
}

.tip-jar-cashout-paypal-account:hover {
    cursor: pointer;
    background-color: #FF2F46;
    color: white;
}

.tip-jar-cashout-continue-container {
    width: 100%;
    text-align: center;
    margin-top: 75px;
}

.tip-jar-cashout-continue {
    width: 250px;
    margin: 10px auto;
    text-align: center;
    background-color: #FF2F46;
    color: white;
    border: none;
    border-radius: 5px;
    height: 30px;
    display: block;
}

.tip-jar-cashout-account-method-form-container {
    width: 90%;
    margin: 20px auto;
    margin-bottom: 150px;
    margin-top: 50px;
}

.tip-jar-cashout-account-method-form {
    text-align: center;
}

.tip-jar-cashout-account-method-form label, 
.tip-jar-cashout-account-method-form input,
.tip-jar-cashout-account-method-form button {
    display: block;
}

.tip-jar-cashout-account-method-form label {
    font-size: 12px;
}

.tip-jar-cashout-account-method-form input {
    margin: 10px auto;
    margin-bottom: 20px;
    width: 250px;
    padding: 10px 5px;
    border: #FF2F46 solid 1px;
    border-radius: 5px;
}

.tip-jar-cashout-account-method-form button {
    margin: 10px auto;
    color: white;
    background-color: #FF2F46;
    border: none;
    height: 30px;
    width: 252px;
    border-radius: 5px;

}

.tip-jar-cashout-account-method-form button:hover {
    cursor: pointer;
}

.tip-jar-cashout-continue:hover {
    cursor: pointer;
}

.tip-jar-confirm-cashout-confirmation {
    width: 90%;
    text-align: center;
    margin: 10px auto;
    margin-top: -45px;
    font-size: 15px;
}

.tip-jar-confirm-cashout-container {
    width: 100%;
    text-align: center;
    margin-bottom: 150px;
}

.tip-jar-confirm-cashout-continue {
    width: 250px;
    margin-top: 20px;
    text-align: center;
    background-color: #00aa00;
    color: white;
    border: none;
    border-radius: 5px;
    height: 30px;
}

.tip-jar-confirm-cashout-continue:hover {
    cursor: pointer;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) { 
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}