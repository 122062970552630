.edit-profile-edit-section {
    width: 90%;
    padding: 10px;
    text-align: center;
    margin: auto;
    margin-bottom: 200px;
}

.edit-profile-image {
    width: 100px;
    margin: auto;
    margin-top: 50px;
    display: block;
}

.edit-profile-form-input {
    display: block;
    margin: 5px auto;
    width: 85%;
    height: 30px;
    border: solid 2px #FF2F46;
    border-radius: 5px;
    background-color: white;
    color: black;
    text-align: center;
    font-size: 15px;
}

.edit-profile-form-button {
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
    margin-top:  20px;
}

.edit-profile-form-button {cursor: pointer}

.edit-profile-error-message {
    color: #FF2F46;
    font-family: "Poppins", "cursive";
    margin: 0px auto;
    margin-top: 20px;
}

.edit-profile-form-input-socials-container {
    margin-bottom: 50px;
}

.edit-profile-form-input-social-container {
    display: block;
    margin: 15px auto;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */
@media only screen and (max-width: 600px) {
    .edit-profile-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }
    
    .edit-profile-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .edit-profile-form-input-social-label {
        font-size: 12px;
    }

    .edit-profile-form-input-socials {
        margin-left: 3px;
        border: 1px solid #FF2F46;
        border-radius: 4px;
        width: 120px;
        height: 18px;
        text-align: center;
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .edit-profile-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }
    
    .edit-profile-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .edit-profile-form-input {
        width: 400px;
    }

    .edit-profile-form-button {
        width: 400px;
        margin: 10px auto;
        display: block;
    }

    .edit-profile-form-input-social-label {
        font-size: 15px;
    }

    .edit-profile-form-input-socials {
        margin-left: 5px;
        border: 1px solid #FF2F46;
        border-radius: 4px;
        width: 150px;
        height: 18px;
        text-align: center;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}
