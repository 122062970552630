.messages-container {
    width: 100%;
    padding: 0px;
    padding-bottom: 500px;
    margin: 0px;
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.b {
    color: black;
}

.no-dec {
    text-decoration: none;
}


/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {

    .messages-container {
        width: 100%;
        margin: 0px;
        padding-bottom: 200px;
    }

    .messages-title-container {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        margin-top: 75px;
    }

    .messages-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .messages-create-new-message-statement-container {
        width: 100%;
        text-align: center;
        margin: 30px auto;

    }

    .messages-create-new-message-statement {
        color: black;
    }

    .no-messages {
        width: 100%;
        margin-top: 100px;
        text-align: center;
        opacity: .5;
    }

    .messages-create-new-message-statement:hover {
        cursor: pointer;
        border-bottom: 2px solid #FF2F46;
    }

    .messages-new-message-icon {
        height: 15px;
    }

    .messages-messages-container {
        width: 100%;
        margin: auto;
    }

    .messages-message-container {
        display: block;
        width: 100%;
        margin: 30px auto;
        padding: 0px;
    }

    .messages-message-container:hover {
        opacity: .5;
        cursor: pointer;
    }

    .messages-message-user-icon, .messages-message-user-name-preview, .messages-message-read-status {
        display: inline-block;
        vertical-align: middle;
    }

    .messages-message-user-icon {
        justify-content: center;
        text-align: center;
        margin-left: 20px;
        margin-right: -20px;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        overflow:hidden;
        background-size: cover;
        background-position: center center;
    }

    .messages-message-user-name-preview {
        font-size: 13px;
        margin-left: 50px;
        color: black;
        width: 40%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .messages-message-read-status {
        position: relative;
        float: right;
        margin-right: 30px;

    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {

    .messages-container {
        width: 100%;
        margin: 0px;
        padding-bottom: 200px;
    }

    .messages-title-container {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        margin-top: 75px;
    }

    .messages-title {
        font-family: "Permanent Marker", "cursive";
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .messages-create-new-message-statement-container {
        width: 100%;
        text-align: center;
        margin: 30px auto;

    }

    .messages-create-new-message-statement {
        color: black;
    }

    .no-messages {
        width: 100%;
        margin-top: 100px;
        text-align: center;
        opacity: .5;
    }

    .messages-create-new-message-statement:hover {
        cursor: pointer;
        border-bottom: 2px solid #FF2F46;
    }

    .messages-new-message-icon {
        height: 12px;
    }

    .messages-messages-container {
        width: 630px;
        margin: auto;
    }

    .messages-message-container {
        display: block;
        width: 100%;
        margin: 30px auto;
        padding: 0px;
    }

    .messages-message-container:hover {
        opacity: .5;
        cursor: pointer;
    }

    .messages-message-user-icon, .messages-message-user-name-preview, .messages-message-read-status {
        display: inline-block;
        vertical-align: middle;
    }

    .messages-message-user-icon {
        justify-content: center;
        text-align: center;
        margin-left: 20px;
        margin-right: -20px;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        overflow:hidden;
        background-size: cover;
        background-position: center center;
    }

    .messages-message-user-name-preview {
        margin-left: 70px;
        color: black;
        width: 50%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .messages-message-read-status {
        position: relative;
        float: right;
        margin-right: 30px;

    }
    
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {}
