.home-container {
    width: 100%;
    padding: 0px;
    padding-bottom: 500px;
    margin: 0px;
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.b {
    color: black;
}

.no-dec {
    text-decoration: none;
}

.font-1 {
    font-family: "Permanent Marker", "cursive";
}

.font-2 {
    font-family: "Poppins", "cursive";
}

.rider-info-button-container form {
    display: inline-block;
    margin-top: 10px;
}

.rider-info-button {
    width: 75px;
    margin-right: 10px;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    padding: 3px;
    display: inline-block;
}

.rider-info-button:hover {
    cursor: pointer;
    background-color: #FF2F46;
    color: white;
    border: 1px solid #FF2F46;
}

.rider-info-button-unfollow {
    width: 75px;
    margin-right: 10px;
    background-color: #FF2F46;
    border: 1px solid #FF2F46;
    color: white;
    border-radius: 5px;
    padding: 3px;
    display: inline-block;
}

.rider-info-button-unfollow:hover {
    cursor: pointer;
}

.rider-message-status {
    width: 80%;
    margin: 10px auto;
    text-align: center;
}

.home-message-link-guest {
    font-size: 13px;
}

.home-message-link-guest:hover {
    cursor: pointer;
    border-bottom: #FF2F46 solid 2px;
}

.rider-image-contianer-s3 {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow:hidden;
    background-size: cover;
    background-position: center center;
    margin: auto;
}

.link:hover {
    border-bottom: #FF2F46 solid 2px;
}

.rider-tip-button-container {
    width: 100%;
    text-align: center;
}

.rider-tip-button {
    background-color: #FF2F46;
    border: #FF2F46 1px solid;
    border-radius: 20px;
    padding: 8px;
    margin: 5px;
    text-align: center;
    margin-bottom: -20px;
    color: white;
    width: 50px;
}

.rider-tip-button-custom {
    background-color: #FF2F46;
    border: #FF2F46 1px solid;
    border-radius: 20px;
    padding: 8px;
    margin: 5px;
    text-align: center;
    margin-bottom: -20px;
    color: white;
}

.rider-tip-button-name {
    font-family: "Permanent Marker", "cursive";
}

.rider-tip-button:hover {
    cursor: pointer;
    background-color: white;
    color: #FF2F46;
    border: #FF2F46 1px solid;
}

.rider-tip-button-custom:hover {
    cursor: pointer;
    background-color: white;
    color: #FF2F46;
    border: #FF2F46 1px solid;
}
/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .extra-margin {
        margin-bottom: 10px;
    }

    .rider-pic-info-items {
        width: 100%;
        margin: 0px;
        display: block;
        margin-top: 75px;
        text-align: center;
    }

    .rider-pic-item {
        display: block;
        width: 150px;
        margin: 0px auto;
    }

    .rider-info-items {
        display: inline-block;
        vertical-align: middle;
        width: 270px;
        margin: 20px;
        margin-bottom: 10px;
        margin-right: 30px;
        font-family: "Poppins", "cursive";
    }

    .rider-name-item {
        margin: 0px;
    }
    

    .rider-detail-item {
        margin: 0px;
    }

    .rider-message-status {
        width: 80%;
        margin: 10px auto;
    }

    .rider-follow-info {
        width: 100%;
        text-align: center;
        margin-top: 25px;
    }

    .rider-followers-link, .rider-following-link {
        margin: 20px;
        text-decoration: none;
        font-family: "Poppins", "cursive";
        font-size: 15px;
    }

    .rider-followers-link:hover, .rider-following-link:hover {
        border-bottom: 2px red solid;
    }

    .rider-edit-button-container {
        width: 100%;
        text-align: center;
        margin-top: 25px;
    }

    .rider-edit-button {
        width: 50%;
        height: 30px;
        border: none;
        color: white;
        background-color: #FF2F46;
        border-radius: 5px;
    }

    .rider-edit-button:hover {
        cursor: pointer;
    }

    .rider-tab-container {
        margin: 25px auto;
        width: 100%;   
        text-align: center;     
    }

    .rider-attending-tab, .rider-hosting-tab, .rider-posts-tab {
        display: inline-block;
        margin: 10px 20px;
    }

    .rider-attending-tab, .rider-hosting-tab, .rider-posts-tab:hover {
        cursor: pointer;
    }

    /* .rider-attending-tab {}

    .rider-hosting-tab {}

    .rider-posts-tab {} */
        
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .rider-pic-info-items {
        width: 100%;
        margin: 0px;
        margin-top: 10px;
        vertical-align:middle;
        margin-top: 75px;
    }

    .rider-pic-item {
        display: inline-block;
        vertical-align:middle;
        width: 110px;
        margin: 20px 0px 0px 20px;
    }

    .rider-info-items {
        display: inline-block;
        vertical-align:middle;
        width: 270px;
        margin: 20px;
        font-family: "Poppins", "cursive";

    }

    .rider-name-item {
        margin: 0px;
    }
    
    .rider-detail-item {
        margin: 0px;
    }

    .rider-message-status {
        width: 80%;
        margin: 10px auto;
    }

    .rider-follow-info {
        width: 100%;
        text-align: center;
        margin-top: 25px;
    }

    .rider-followers-link, .rider-following-link {
        margin: 20px;
        text-decoration: none;
        font-family: "Poppins", "cursive";
        font-size: 18px;
    }

    .rider-followers-link:hover, .rider-following-link:hover {
        border-bottom: 2px red solid;
    }

    .rider-edit-button-container {
        width: 100%;
        text-align: center;
        margin-top: 25px;
    }

    .rider-edit-button {
        width: 50%;
        height: 30px;
        border: none;
        color: white;
        background-color: #FF2F46;
        border-radius: 5px;
    }

    .rider-edit-button:hover {
        cursor: pointer;
    }

    .rider-tab-container {
        margin: 25px auto;
        width: 100%;   
        text-align: center;     
    }

    .rider-attending-tab, .rider-hosting-tab, .rider-posts-tab {
        display: inline-block;
        margin: 10px 25px;
    }

    .rider-attending-tab, .rider-hosting-tab, .rider-posts-tab:hover {
        cursor: pointer;
    }

}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .rider-pic-info-items {
        width: 930px;
        margin: 0px auto;
        margin-top: 10px;
        vertical-align: middle;
        margin-top: 75px;
    }
}